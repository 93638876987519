import { call, put, takeEvery, all } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_LICENSEE_INIT_ACTION,
  FETCH_LICENSEE_SUCCESS_ACTION,
  FETCH_LICENSEE_FAIL_ACTION,
  FETCH_COUNTRY_DROPDOWN_IN_LICENSE_INIT_ACTION,
  FETCH_COUNTRY_DROPDOWN_IN_LICENSE_SUCCESS_ACTION,
  FETCH_COUNTRY_DROPDOWN_IN_LICENSE__FAIL_ACTION,
  FETCH_STATE_DROPDOWN_IN_LICENSE_INIT_ACTION,
  FETCH_STATE_DROPDOWN_IN_LICENSE_SUCCESS_ACTION,
  FETCH_STATE_DROPDOWN_IN_LICENSE_FAIL_ACTION,
  FETCH_DISTRICT_DROPDOWN_IN_LICENSE_INIT_ACTION,
  FETCH_DISTRICT_DROPDOWN_IN_LICENSE_SUCCESS_ACTION,
  FETCH_DISTRICT_DROPDOWN_IN_LICENSE_FAIL_ACTION,
  ADD_LICENSE_INIT_ACTION,
  ADD_LICENSE_SUCCESS_ACTION,
  ADD_LICENSE_FAIL_ACTION,
  EDIT_LICENSE_INIT_ACTION,
  EDIT_LICENSE_SUCCESS_ACTION,
  EDIT_LICENSE_FAIL_ACTION,
  FETCH_LICENSESTATUS_INIT_ACTION,
  FETCH_LICENSESTATUS_SUCCESS_ACTION,
  FETCH_LICENSESTATUS_FAIL_ACTION,
  FETCH_BANKS_INIT_ACTION,
  FETCH_BANKS_SUCCESS_ACTION,
  FETCH_BANKS_FAIL_ACTION,
  FETCH_LICENSEDETAIL_INIT_ACTION,
  FETCH_LICENSEDETAIL_SUCCESS_ACTION,
  FETCH_LICENSEDETAIL_FAIL_ACTION,
  APPROVE_OPEN_LICENSEE_INIT_ACTION,
  APPROVE_OPEN_LICENSEE_SUCCESS_ACTION,
  APPROVE_OPEN_LICENSEE_FAIL_ACTION,
  REJECT_OPEN_LICENSEE_INIT_ACTION,
  REJECT_OPEN_LICENSEE_SUCCESS_ACTION,
  REJECT_OPEN_LICENSEE_FAIL_ACTION,
  FETCH_CHANNEL_PARTNER_IN_LICENSEE_INIT_ACTION,
  FETCH_CHANNEL_PARTNER_IN_LICENSEE_SUCCESS_ACTION,
  FETCH_CHANNEL_PARTNER_IN_LICENSEE_FAIL_ACTION,
} from "./action";
import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var btoa = require("Base64").btoa;
var history = createBrowserHistory();

function* fetchLicensee(value) {
  const token = localStorage.getItem("saleStraitToken");
  const role = localStorage.getItem("UserRole");
  const userid = localStorage.getItem("saleStraitUserId");
  let statusFilter = value.value ? value.value.statusFilter : "";
  let channelPartnerFilter = value.value
    ? value.value.channelPartnerFilter
    : "";
  let pageno = value.value.page === undefined ? 1 : value.value.page;

  let search =
    !value || !value.value || !value.value.searchQuery
      ? ""
      : value.value.searchQuery;

  let getDataUrl = [
    // {
    //   role: "admin",
    //   url: "/users?role=licensee&page=" + pageno + "&limit=10&isActive=true",
    // },
    {
      role: "admin",
      url: "/licenseeProf?page=" + pageno + "&limit=10&isActive=true",
    },
    {
      role: "channelPartner",
      url:
        "/licenseeProf?page=" +
        pageno +
        "&limit=10&isActive=true&channelPartner=" +
        userid,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);

  let _url = `${appConfig.ip}${apiUrl.url}`;

  if (search) {
    _url = `${_url}&search=${search}`;
  }

  if (channelPartnerFilter) {
    _url = `${_url}&channelPartner=${channelPartnerFilter}`;
  }
  if (statusFilter) {
    _url = `${_url}&status=${statusFilter}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch { }
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_LICENSEE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_LICENSEE_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchLicenseeDistrict(value) {
  let stateId = (value.value && value.value.stateId) || null;

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(
      `${appConfig.ip}/districts?isActive=true&stateId=${stateId}`,

      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_DISTRICT_DROPDOWN_IN_LICENSE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_DISTRICT_DROPDOWN_IN_LICENSE_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* fetchLicenseeCountry(value) {
  try {
    const token = localStorage.getItem("saleStraitToken");

    const res = yield fetch(`${appConfig.ip}/country?isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_COUNTRY_DROPDOWN_IN_LICENSE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_COUNTRY_DROPDOWN_IN_LICENSE__FAIL_ACTION,
        error: err,
      });
    }
  }
}
function* fetchLicenseeStateDropdown(value) {
  let countryId = (value.value && value.value.countryId) || null;
  try {
    const token = localStorage.getItem("saleStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/state?isActive=true&countryId=${countryId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_STATE_DROPDOWN_IN_LICENSE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_STATE_DROPDOWN_IN_LICENSE_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* addLicenseProfile(value) {


  const role = localStorage.getItem("UserRole");

  let userID = localStorage.getItem("saleStraitUserId");
  let data = {
    password: value.value.password,
    mobileNo: value.value.mobileNo,
    name: value.value.name,
    lName: value.value.lName,
    orgName: value.value.orgName,
    email: value.value.email,
    country: value.value.country,
    state: value.value.state,
    district: value.value.district,
    commsnPrcntg: value.value.commsnPrcntg || 0,
    taxPrcntg: value.value.taxPrcntg,
    crNumber: value.value.crNumber,
    gstin: value.value.gstin,
    taxEnble: value.value.isEnabled,
    spocName: value.value.spocName,
    spocDsgntn: value.value.spocDsgntn,
    spocEmail: value.value.spocEmail,
    spocPhn: value.value.spocPhn,
    hqAddr: value.value.hqAddr,
    hqFax: value.value.hqFax,
    hqEmail: value.value.hqEmail,
    hqPhn: value.value.hqPhn,
    addr1: value.value.addr1,
    addr2: value.value.addr2,
    bankName: value.value.bankName,
    accountType: value.value.accountType,
    accountName: value.value.accountName,
    accountNo: value.value.accountNo,
    branchAddress: value.value.branchAddress,
    branchName: value.value.branchName,
    ifscCode: value.value.ifscCode,
    multiOrder: value.value.multiOrder,
    zip: value.value.zip,
    contactNo: value.value.contactNo,
    contactEmail: value.value.contactEmail,
    channelPartner: role == "channelPartner" ? userID : null,
    networkHierarchy: (value.value && value.value.networkHierarchy) || false,

    type: (value.value && value.value.type) || "",
    role:
      value.value && value.value.type === "licensee"
        ? "licensee"
        : value.value && value.value.type === "distributor"
        ? "distributor"
        : "unipos",
  };
  console.log("value", data);

  try {
    const token = localStorage.getItem("saleStraitToken");

    const res = yield fetch(`${appConfig.ip}/users/createLicensee`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
        if (errJSON.message === "User already registered") {
          yield toast.error("Licensee already exist", {
            autoClose: 3000,
          });
        }
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield call(addDefaultUser, { value: resJSON, password: data.password });

      let userId = resJSON.id;

      const formData = new FormData();

      formData.append(userId, value.value.picture);

      const resFile = yield fetch(`${appConfig.ip}/users/image`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!resFile.ok) {
        throw resFile;
      } else {
        const resJSON1 = yield resFile.json();
        // console.log("resJSON1", resJSON1, resJSON);

        let invoiceConfigData = {
          remark: null,
          bankName: resJSON.licProf.bankName || null,
          accountName: resJSON.licProf.accountName || null,
          accountNo: resJSON.licProf.accountNo || null,
          branchName: resJSON.licProf.branchName || null,
          ifscCode: resJSON.licProf.ifscCode || null,
          invoiceStartNo:value.value.individual ?value.value.individual: resJSON.orgName.substring(0, 3) || null,
          user: userID,
          licenseeId: resJSON1.licenseeId || "",
          imgUrl: resJSON1.picture || "",
          entrprzInvStartNo: value.value.enterprise? value.value.enterprise: resJSON.orgName.substring(0, 3) || null,
        };

        const resInvoice = yield fetch(`${appConfig.ip}/invoiceConfig`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(invoiceConfigData),
        });

        let creditNoteConfigData = {
          remark: null,

          startNo: resJSON.orgName.substring(0, 3) || null,
          user: userID,
          licenseeId: resJSON1.licenseeId || "",
          imgUrl: resJSON1.picture || "",
        };

        const rescreditNote = yield fetch(`${appConfig.ip}/creditNoteConfig`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(creditNoteConfigData),
        });

        let quotationConfigData = {
          remark: null,

          quoteStartNo: resJSON.orgName.substring(0, 3) || null,
          user: userID,
          licenseeId: resJSON1.licenseeId || "",
          imgUrl: resJSON1.picture || "",
        };

        const resQuotation = yield fetch(`${appConfig.ip}/quoteConfig`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(quotationConfigData),
        });
      }

      yield toast.success("Licensee added successfully", {});
      yield put({ type: FETCH_LICENSEE_INIT_ACTION, value: { page: 1 } });

      yield put({
        type: ADD_LICENSE_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield put({
        type: "SUCCESS_ACTION_MODAL",
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_LICENSE_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* editLicense(value) {
  let id = value.value.id;

  try {
    const token = localStorage.getItem("saleStraitToken");
    let imageFlag = value.value.isNewImage || false;

    if (imageFlag) {
      const formData = new FormData();
      // const userId = localStorage.getItem("saleStraitUserId");

      formData.append(id, value.value.picture);

      const resFile = yield fetch(`${appConfig.ip}/users/image`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!resFile.ok) {
        throw resFile;
      } else {
        const resJSON = yield resFile.json();

        const franchiseData = {
          // picture:resJSON.files[0],
          mobileNo: value.value.mobileNo,

          name: value.value.name,
          lName: value.value.lName,

          orgName: value.value.orgName,
          email: value.value.email,

          country: value.value.country,
          state: value.value.state,
          district: value.value.district,
          commsnPrcntg: value.value.commsnPrcntg || 0,
          taxPrcntg: value.value.taxPrcntg,
          crNumber: value.value.crNumber,
          gstin: value.value.gstin,
          taxEnble: value.value.isEnabled,
          spocName: value.value.spocName,
          spocDsgntn: value.value.spocDsgntn,
          spocEmail: value.value.spocEmail,
          spocPhn: value.value.spocPhn,
          hqAddr: value.value.hqAddr,
          hqFax: value.value.hqFax,
          hqEmail: value.value.hqEmail,
          hqPhn: value.value.hqPhn,
          addr1: value.value.addr1,
          addr2: value.value.addr2,
          bankName: value.value.bankName,
          accountName: value.value.accountName,
          accountNo: value.value.accountNo,
          accountType: value.value.accountType,
          branchAddress: value.value.branchAddress,
          branchName: value.value.branchName,
          ifscCode: value.value.ifscCode,
          multiOrder: value.value.multiOrder,
          zip: value.value.zip,
          city: value.value.city,
          contactNo: value.value.contactNo,
          contactEmail: value.value.contactEmail,
          networkHierarchy:
            (value.value && value.value.networkHierarchy) || false,

          type: (value.value && value.value.type) || "",
          // role:
          //   value.value && value.value.type === "licensee"
          //     ? "licensee"
          //     : "distributor",
          role:
            value.value && value.value.type === "licensee"
              ? "licensee"
              : value.value && value.value.type === "distributor"
                ? "distributor"
                : "unipos",
        };

        const res = yield fetch(`${appConfig.ip}/licenseeProf/update/` + id, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(franchiseData),
        });

        if (!res.ok) {
          let errJSON = {};
          try {
            errJSON = yield res.json();
          } catch {
            throw Object.assign(res, errJSON);
          }
        } else {
          const resJSON = yield res.json();
          const licProfById = yield call(fetchLicenseDetailList, {
            value: resJSON,
          });
          yield toast.success("Licensee updated successfully", {
            autoClose: 3000,
          });

          yield put({
            type: EDIT_LICENSE_SUCCESS_ACTION,
            payload: licProfById,
          });
        }
      }
    } else {
      const data = {
        picture: value.value.picture,
        mobileNo: value.value.mobileNo,

        name: value.value.name,
        lName: value.value.lName,

        orgName: value.value.orgName,
        email: value.value.email,

        country: value.value.country,
        state: value.value.state,
        district: value.value.district,
        commsnPrcntg: value.value.commsnPrcntg || 0,
        taxPrcntg: value.value.taxPrcntg,
        crNumber: value.value.crNumber,
        gstin: value.value.gstin,
        taxEnble: value.value.isEnabled,
        spocName: value.value.spocName,
        spocDsgntn: value.value.spocDsgntn,
        spocEmail: value.value.spocEmail,
        spocPhn: value.value.spocPhn,
        hqAddr: value.value.hqAddr,
        hqFax: value.value.hqFax,
        hqEmail: value.value.hqEmail,
        hqPhn: value.value.hqPhn,
        addr1: value.value.addr1,
        addr2: value.value.addr2,
        bankName: value.value.bankName,
        accountName: value.value.accountName,
        accountNo: value.value.accountNo,
        accountType: value.value.accountType,
        branchAddress: value.value.branchAddress,
        branchName: value.value.branchName,
        ifscCode: value.value.ifscCode,
        multiOrder: value.value.multiOrder,
        zip: value.value.zip,
        city: value.value.city,
        contactNo: value.value.contactNo,
        contactEmail: value.value.contactEmail,
        networkHierarchy:
          (value.value && value.value.networkHierarchy) || false,

        type: (value.value && value.value.type) || "",
        // role:
        //   value.value && value.value.type === "licensee"
        //     ? "licensee"
        //     : "distributor",
        role:
          value.value && value.value.type === "licensee"
            ? "licensee"
            : value.value && value.value.type === "distributor"
              ? "distributor"
              : "unipos",
      };
      const res = yield fetch(`${appConfig.ip}/licenseeProf/update/` + id, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!res.ok) {
      } else {
        const resJSON = yield res.json();

        const licProfById = yield call(fetchLicenseDetailList, {
          value: resJSON,
        });
        yield put({
          type: EDIT_LICENSE_SUCCESS_ACTION,
          payload: licProfById,
        });
        yield toast.success("Licensee updated successfully", {
          autoClose: 3000,
        });
        yield put({
          type: "SUCCESS_ACTION_MODAL",
        });
      }
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: EDIT_LICENSE_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* fetchLicenseStatus(value) {
  let data =
    value.value.userId.status === "active"
      ? { status: "suspended" }
      : { status: "active" };
  let id = value.value.userId.id;

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(
      `${appConfig.ip}/users/licenseeChangeStatus/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }
    );
    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_LICENSESTATUS_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Status changed successfully", {
        autoClose: 3000,
      });
      yield put({
        type: FETCH_LICENSEE_INIT_ACTION,
        value: { page: 1 },
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_LICENSESTATUS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchBanks() {
  try {
    const token = localStorage.getItem("saleStraitToken");

    const res = yield fetch(`${appConfig.ip}/bankName/all`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
    } else {
      const resJSON = yield res.json();
      yield put({
        type: FETCH_BANKS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_BANKS_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* addDefaultUser(value) {
  try {
    const token = localStorage.getItem("saleStraitToken");

    const data = {
      licenseeId: value.value.licenseeId,
      author: value.value.id,
      orgName: value.value.orgName,
      orgId: value.value.orgId,
      password: value.password,
      email: value.value.email,
      mobileNo: value.value.mobileNo,
    };

    const res = yield fetch(`${appConfig.ip}/users/defaultCreate`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();
    }
  } catch (err) {
    if (err.ok === false) {
    }
  }
}

function* fetchLicenseDetailList(value) {
  let id = value.value.userId.id;

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/licenseeProf/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch { }
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_LICENSEDETAIL_SUCCESS_ACTION,
        payload: resJSON,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_LICENSEDETAIL_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* approveOpenLicensee(params) {
  let data =
    params.params.userId.status === "active"
      ? { status: "pending" }
      : { status: "active" };
  let id = params.params.userId.id;
  try {
    const token = localStorage.getItem("saleStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/users/licenseeChangeStatus/${id}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();
      yield toast.success("Licensee approved  successfully", {
        autoClose: 3000,
      });
      yield put({
        type: APPROVE_OPEN_LICENSEE_SUCCESS_ACTION,
      });
      yield put({
        type: FETCH_LICENSEE_INIT_ACTION,
        value: { page: 1 },
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: APPROVE_OPEN_LICENSEE_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* rejectOpenLicensee(params) {
  let data =
    params.params.userId.status === "rejected"
      ? { status: "pending" }
      : { status: "rejected" };
  let id = params.params.userId.id;
  try {
    const token = localStorage.getItem("saleStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/users/licenseeChangeStatus/${id}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();
      yield toast.success("Licensee rejected  successfully", {
        autoClose: 3000,
      });
      yield put({
        type: REJECT_OPEN_LICENSEE_SUCCESS_ACTION,
      });
      yield put({
        type: FETCH_LICENSEE_INIT_ACTION,
        value: { page: 1 },
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: REJECT_OPEN_LICENSEE_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* fetchChannelParnterInLicensee() {
  try {
    const token = localStorage.getItem("saleStraitToken");

    const res = yield fetch(`${appConfig.ip}/users?role=channelPartner`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
    } else {
      const resJSON = yield res.json();
      yield put({
        type: FETCH_CHANNEL_PARTNER_IN_LICENSEE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_CHANNEL_PARTNER_IN_LICENSEE_FAIL_ACTION,
        error: err,
      });
    }
  }
}

export function* LicenseeActionWatcher() {
  yield takeEvery(FETCH_LICENSEE_INIT_ACTION, fetchLicensee);
  yield takeEvery(
    FETCH_COUNTRY_DROPDOWN_IN_LICENSE_INIT_ACTION,
    fetchLicenseeCountry
  );
  yield takeEvery(
    FETCH_STATE_DROPDOWN_IN_LICENSE_INIT_ACTION,
    fetchLicenseeStateDropdown
  );
  yield takeEvery(
    FETCH_DISTRICT_DROPDOWN_IN_LICENSE_INIT_ACTION,
    fetchLicenseeDistrict
  );
  yield takeEvery(ADD_LICENSE_INIT_ACTION, addLicenseProfile);
  yield takeEvery(EDIT_LICENSE_INIT_ACTION, editLicense);
  yield takeEvery(FETCH_BANKS_INIT_ACTION, fetchBanks);
  yield takeEvery(FETCH_LICENSESTATUS_INIT_ACTION, fetchLicenseStatus);
  yield takeEvery(FETCH_LICENSEDETAIL_INIT_ACTION, fetchLicenseDetailList);
  yield takeEvery(APPROVE_OPEN_LICENSEE_INIT_ACTION, approveOpenLicensee);
  yield takeEvery(REJECT_OPEN_LICENSEE_INIT_ACTION, rejectOpenLicensee);
  yield takeEvery(
    FETCH_CHANNEL_PARTNER_IN_LICENSEE_INIT_ACTION,
    fetchChannelParnterInLicensee
  );
}
