export const FETCH_DASHBOARD_INIT_ACTION = "FETCH_DASHBOARD_INIT_ACTION"
export const FETCH_DASHBOARD_SUCCESS_ACTION = FETCH_DASHBOARD_INIT_ACTION + '_SUCCESS'
export const FETCH_DASHBOARD_FAIL_ACTION = FETCH_DASHBOARD_INIT_ACTION + '_ERROR'


export function dashboardList() {
  
    return {
        type: FETCH_DASHBOARD_INIT_ACTION
    }
}
