import { put, takeEvery } from "redux-saga/effects";
//import { createBrowserHistory } from "history";
import {
  ADD_STATE_INIT_ACTION,
  ADD_STATE_SUCCESS_ACTION,
  ADD_STATE_FAIL_ACTION,
  FETCH_STATELIST_INIT_ACTION,
  FETCH_STATELIST_SUCCESS_ACTION,
  FETCH_STATELIST_FAIL_ACTION,
  DELETE_STATE_INIT_ACTION,
  DELETE_STATE_SUCCESS_ACTION,
  DELETE_STATE_FAIL_ACTION,
  UPDATE_STATE_INIT_ACTION,
  UPDATE_STATE_SUCCESS_ACTION,
  UPDATE_STATE_FAIL_ACTION,
  FETCH_COUNTRY_DROPDOWN_IN_STATE_INIT_ACTION,
  FETCH_COUNTRY_DROPDOWN_IN_STATE_SUCCESS_ACTION,
  FFETCH_COUNTRY_DROPDOWN_IN_STATE_FAIL_ACTION,
} from "./action";
//import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastrMsg from "../../screens/Common/ToastrMsg";

// var btoa = require("Base64").btoa;
// var history = createBrowserHistory();
function* fetchCntryByState(value) {
  try {
    const token = localStorage.getItem("saleStraitToken");

    const res = yield fetch(`${appConfig.ip}/country?isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_COUNTRY_DROPDOWN_IN_STATE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FFETCH_COUNTRY_DROPDOWN_IN_STATE_FAIL_ACTION,
        error: err,
      });
    }
  }
}
function* addState(value) {
  try {
    let latitude = value.value.latitude;
    let longitude = value.value.longitude;
    const StateValue = {
      name: value.value.name,
      code: value.value.code,
      type: value.value.type,
      // capital: value.value.capital,
      countryId: value.value.countryId,
      // districts: [],
      // coordinates: [latitude, longitude],
    };

    const token = localStorage.getItem("saleStraitToken");
    const res = yield fetch(`${appConfig.ip}/state`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(StateValue),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_STATELIST_INIT_ACTION,
        value: { page: 1 },
      });

      yield toast.success(ToastrMsg.addMsg, {
        autoClose: 3000,
      });

      yield put({
        type: ADD_STATE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_STATE_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* fetchStateList(value) {
  let pageno = (value.value && value.value.page) || 1;
  const token = localStorage.getItem("saleStraitToken");
  let searchVal =
    !value || !value.value || !value.value.searchQuery
      ? ""
      : value.value.searchQuery;
  let filter =
    !value || !value.value || !value.value.filter ? null : value.value.filter;
  let _url = `${appConfig.ip}/state?limit=10&search=${searchVal}&page=${pageno}&isActive=true`;

  if (filter) {
    _url = `${_url}&countryId=${filter}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_STATELIST_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_STATELIST_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* deleteState(items) {
  let id = items.items.id;

  try {
    const token = localStorage.getItem("saleStraitToken");
    const res = yield fetch(`${appConfig.ip}/state/` + items.items.id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
        if (errJSON) {
          yield toast.error("Delete not allowed", {
            autoClose: 3000,
          });
        }
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      // yield put({
      //   type: FETCH_STATELIST_INIT_ACTION,
      // });
      //const resJSON = yield res.json();
      yield toast.success("Deleted successfully", {
        autoClose: 3000,
      });
      yield put({
        type: DELETE_STATE_SUCCESS_ACTION,
        payload: id,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: DELETE_STATE_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* editState(value) {
  // const coordinates = [];
  // coordinates.push(value.value.latitude, value.value.longitude);

  let id = value.value.id;

  const token = localStorage.getItem("saleStraitToken");

  const StateValue = {
    name: value.value.name,
    countryId: value.value.countryId,
    code: value.value.code,
    // coordinates: coordinates,
    // capital: value.value.capital,
    type: value.value.type,
    // districts: [],
  };

  try {
    const res = yield fetch(`${appConfig.ip}/state/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(StateValue),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      // yield put({
      //   type: FETCH_STATELIST_INIT_ACTION,
      // });
      yield toast.success("updated successfully", {
        autoClose: 3000,
      });
      yield put({
        type: UPDATE_STATE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: UPDATE_STATE_FAIL_ACTION, error: err });
    } else {
    }
  }
}

export function* StateActionWatcher() {
  yield takeEvery(
    FETCH_COUNTRY_DROPDOWN_IN_STATE_INIT_ACTION,
    fetchCntryByState
  );
  yield takeEvery(ADD_STATE_INIT_ACTION, addState);
  yield takeEvery(FETCH_STATELIST_INIT_ACTION, fetchStateList);
  yield takeEvery(DELETE_STATE_INIT_ACTION, deleteState);
  yield takeEvery(UPDATE_STATE_INIT_ACTION, editState);
}
