import {
  FETCH_PRODUCTTYPE_SUCCESS_ACTION,

  EDIT_PRODUCTTYPE_SUCCESS_ACTION,
  DELETE_PRODUCTTYPE_SUCCESS_ACTION
} from "./action";

const initialState = {
  processing: false,
  error: false,
};

export default function (state: any = initialState, action: Function) {
  switch (action.type) {
    case FETCH_PRODUCTTYPE_SUCCESS_ACTION:
      return {
        ...state,
        ProductTypeList: action.payload.rows,
        ProductTypeCount: action.payload.count,
      };

 
    case EDIT_PRODUCTTYPE_SUCCESS_ACTION:
      return {
        ...state,
         ProductTypeList: state.ProductTypeList.map((ProductType) =>
          ProductType.id === action.payload.id ? action.payload : ProductType
        ),
      };
    case DELETE_PRODUCTTYPE_SUCCESS_ACTION:

      return {
        ...state,
        ProductTypeList: state.ProductTypeList.filter(
          (item) => item.id !== action.payload
        ),
        ProductTypeCount: state.ProductTypeCount - 1
      };

    default:
      return {
        ...state,
      };
  }
}
