export const FETCH_PAYMENTS_INIT_ACTION = "FETCH_PAYMENTS_INIT_ACTION";
export const FETCH_PAYMENTS_SUCCESS_ACTION =
FETCH_PAYMENTS_INIT_ACTION + "_SUCCESS";
export const FETCH_PAYMENTS_FAIL_ACTION = FETCH_PAYMENTS_INIT_ACTION + "_ERROR";

export const FETCH_PAYMENTSBYID_INIT_ACTION = "FETCH_PAYMENTSBYID_INIT_ACTION";
export const FETCH_PAYMENTSBYID_SUCCESS_ACTION =
FETCH_PAYMENTSBYID_INIT_ACTION + "_SUCCESS";
export const FETCH_PAYMENTSBYID_FAIL_ACTION = FETCH_PAYMENTSBYID_INIT_ACTION + "_ERROR";






export function fetchPayments(value) {
    return {
      type: FETCH_PAYMENTS_INIT_ACTION,
      value:value,

    };
  }
  

  export function getPaymentsById(value) {
    return {
      type: FETCH_PAYMENTSBYID_INIT_ACTION,
      value:value
    };
  }