import { put, takeEvery } from "redux-saga/effects";
//import { createBrowserHistory } from "history";
import {
  FETCH_TERMSANDCONDITION_INIT_ACTION,
  FETCH_TERMSANDCONDITION_SUCCESS_ACTION,
  FETCH_TERMSANDCONDITION_FAIL_ACTION,
  ADD_TERMSANDCONDITION_INIT_ACTION,
  ADD_TERMSANDCONDITION_SUCCESS_ACTION,
  ADD_TERMSANDCONDITION_FAIL_ACTION,
  EDIT_TERMSANDCONDITION_INIT_ACTION,
  EDIT_TERMSANDCONDITION_SUCCESS_ACTION,
  EDIT_TERMSANDCONDITION_FAIL_ACTION
} from "./action";
//import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// var btoa = require("Base64").btoa;
// var history = createBrowserHistory();

function* fetchTermsandCondition() {
  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/tandCS`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_TERMSANDCONDITION_SUCCESS_ACTION,
        payload: resJSON
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_TERMSANDCONDITION_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* AddTrmsadCndtn(value) {
  try {
    const token = localStorage.getItem("saleStraitToken");

    const data = {
      termsUrl: value.value.termsUrl,
      termsV: value.value.termsV,
      privacyUrl: value.value.privacyUrl,
      privacyV: value.value.privacyV
    };

    const res = yield fetch(`${appConfig.ip}/tandCS`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(data)
    });

    if (!res.ok) {
    } else {
      const resJSON = yield res.json();

      yield put({ type: FETCH_TERMSANDCONDITION_INIT_ACTION });
      yield put({
        type: ADD_TERMSANDCONDITION_SUCCESS_ACTION,
        payload: resJSON
      });
      yield toast.success("Terms & Conditions added successfully", {
        autoClose: 3000
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_TERMSANDCONDITION_FAIL_ACTION,
        error: err
      });
    }
  }
}

function* EditTrmsadCndtn(value) {
  try {
    const token = localStorage.getItem("saleStraitToken");

    const data = {
      termsUrl: value.value.termsUrl,
      termsV: value.value.termsV,
      privacyUrl: value.value.privacyUrl,
      privacyV: value.value.privacyV,
      isActive: value.value.isActive
    };

    const res = yield fetch(`${appConfig.ip}/tandCS/` + value.value.id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(data)
    });

    if (!res.ok) {
    } else {
      const resJSON = yield res.json();
      // yield put({ type: FETCH_TERMSANDCONDITION_INIT_ACTION });
      yield put({
        type: EDIT_TERMSANDCONDITION_SUCCESS_ACTION,
        payload: resJSON
      });
      yield toast.success("Terms & Conditions updated successfully", {
        autoClose: 3000
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: EDIT_TERMSANDCONDITION_FAIL_ACTION,
        error: err
      });
    }
  }
}

export function* termsandconditionActionWatcher() {
  yield takeEvery(FETCH_TERMSANDCONDITION_INIT_ACTION, fetchTermsandCondition);
  yield takeEvery(ADD_TERMSANDCONDITION_INIT_ACTION, AddTrmsadCndtn);
  yield takeEvery(EDIT_TERMSANDCONDITION_INIT_ACTION, EditTrmsadCndtn);
}
