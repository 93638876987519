export const FETCH_ORDERS_INIT_ACTION = "FETCH_ORDERS_INIT_ACTION";
export const FETCH_ORDERS_SUCCESS_ACTION =
FETCH_ORDERS_INIT_ACTION + "_SUCCESS";
export const FETCH_ORDERS_FAIL_ACTION = FETCH_ORDERS_INIT_ACTION + "_ERROR";

export const FETCH_ORDERBYID_INIT_ACTION = "FETCH_ORDERBYID_INIT_ACTION";
export const FETCH_ORDERBYID_SUCCESS_ACTION =
FETCH_ORDERBYID_INIT_ACTION + "_SUCCESS";
export const FETCH_ORDERBYID_FAIL_ACTION = FETCH_ORDERBYID_INIT_ACTION + "_ERROR";

export const FETCH_REGIONDROPDOWN_INIT_ACTION = "FETCH_REGIONDROPDOWN_INIT_ACTION";
export const FETCH_REGIONDROPDOWN_SUCCESS_ACTION = FETCH_REGIONDROPDOWN_INIT_ACTION + "_SUCCESS";
export const FETCH_REGIONDROPDOWN_FAIL_ACTION = FETCH_REGIONDROPDOWN_INIT_ACTION + "_ERROR";

export const FETCH_AREADROPDOWN_INIT_ACTION = "FETCH_AREADROPDOWN_INIT_ACTION";
export const FETCH_AREADROPDOWN_SUCCESS_ACTION =FETCH_AREADROPDOWN_INIT_ACTION + "_SUCCESS";
export const FETCH_AREADROPDOWN_FAIL_ACTION = FETCH_AREADROPDOWN_INIT_ACTION + "_ERROR";

export const FETCH_ZONEDROPDOWN_INIT_ACTION = "FETCH_ZONEDROPDOWN_INIT_ACTION";
export const FETCH_ZONEDROPDOWN_SUCCESS_ACTION =FETCH_ZONEDROPDOWN_INIT_ACTION + "_SUCCESS";
export const FETCH_ZONEDROPDOWN_FAIL_ACTION = FETCH_ZONEDROPDOWN_INIT_ACTION + "_ERROR";



export function fetchOrders(value) {
 
  return {
    type: FETCH_ORDERS_INIT_ACTION,
    value:value

  };
}

export function getOrderById(value) {
  return {
    type: FETCH_ORDERBYID_INIT_ACTION,
    value:value
  };
}

export function fetchRegionDropdown() {
  return {
    type: FETCH_REGIONDROPDOWN_INIT_ACTION,
  };
}

export function fetchAreaDropdown(value) {
  return {
    type: FETCH_AREADROPDOWN_INIT_ACTION,
    value: value
  };
}

export function fetchZoneDropdown(value) {
  return {
    type: FETCH_ZONEDROPDOWN_INIT_ACTION,
    value: value

  };
}
