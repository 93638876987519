export const FETCH_PINCODE_INIT_ACTION = "FETCH_PINCODE_INIT_ACTION";
export const FETCH_PINCODE_SUCCESS_ACTION =
  FETCH_PINCODE_INIT_ACTION + "_SUCCESS";
export const FETCH_PINCODE_FAIL_ACTION = FETCH_PINCODE_INIT_ACTION + "_ERROR";

export const ADD_PINCODE_INIT_ACTION = "ADD_PINCODE_INIT_ACTION";
export const ADD_PINCODE_SUCCESS_ACTION =
  ADD_PINCODE_INIT_ACTION + "_SUCCESS";
export const ADD_PINCODE_FAIL_ACTION = ADD_PINCODE_INIT_ACTION + "_ERROR";

export const EDIT_PINCODE_INIT_ACTION = "EDIT_PINCODE_INIT_ACTION";
export const EDIT_PINCODE_SUCCESS_ACTION =
  EDIT_PINCODE_INIT_ACTION + "_SUCCESS";
export const EDIT_PINCODE_FAIL_ACTION = EDIT_PINCODE_INIT_ACTION + "_ERROR";

export const DELETE_PINCODE_INIT_ACTION = "DELETE_PINCODE_INIT_ACTION";
export const DELETE_PINCODE_SUCCESS_ACTION =
  DELETE_PINCODE_INIT_ACTION + "_SUCCESS";
export const DELETE_PINCODE_FAIL_ACTION =
  DELETE_PINCODE_INIT_ACTION + "_ERROR";



export const FETCH_PINCODE_DISTRICT_INIT_ACTION = "FETCH_PINCODE_DISTRICT_INIT_ACTION";
export const FETCH_PINCODE_DISTRICT_SUCCESS_ACTION =
  FETCH_PINCODE_DISTRICT_INIT_ACTION + "_SUCCESS";
export const FETCH_PINCODE_DISTRICT_FAIL_ACTION = FETCH_PINCODE_DISTRICT_INIT_ACTION + "_ERROR";


export function fetchPincode(value) {
  return {
    type: FETCH_PINCODE_INIT_ACTION,
    value: value,

  };
}


export function fetchPincodeDistrict(value) {
  return {
    type: FETCH_PINCODE_DISTRICT_INIT_ACTION,
    value: value,
  };
}

export function addPincode(value) {
  return {
    type: ADD_PINCODE_INIT_ACTION,
    value: value,
  };
}

export function editPincode(value) {
  return {
    type: EDIT_PINCODE_INIT_ACTION,
    value: value,
  };
}

export function deletePincode(value) {
  return {
    type: DELETE_PINCODE_INIT_ACTION,
    value: value,
  };
}




