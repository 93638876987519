import {
  FETCH_STATELIST_SUCCESS_ACTION,
  FETCH_STATELIST_INIT_ACTION,
  FETCH_STATELIST_FAIL_ACTION,
  FETCH_COUNTRY_DROPDOWN_IN_STATE_SUCCESS_ACTION,
  DELETE_STATE_SUCCESS_ACTION,
  UPDATE_STATE_SUCCESS_ACTION
} from "./action";

const initialState = {
  processing: true,
  error: false,
};

export default function StateReducer(
  state: any = initialState,
  action: Function
) {
  switch (action.type) {
    case FETCH_STATELIST_INIT_ACTION:
      return {
        ...state,
        processing: true,
        error: false,
      };
    case FETCH_COUNTRY_DROPDOWN_IN_STATE_SUCCESS_ACTION:
      return {
        ...state,
        countryList: action.payload.rows,
      };
    case FETCH_STATELIST_SUCCESS_ACTION:
      return {
        ...state,
        stateList: action.payload.rows,
        stateDtlsCount: action.payload.count,
        processing: false,
        error: false,
      };
    case FETCH_STATELIST_FAIL_ACTION:
      return {
        ...state,
        processing: false,
        error: action.error,
      };
      case UPDATE_STATE_SUCCESS_ACTION:
        return {
          ...state,
          stateList: state.stateList.map((state) =>
          state.id === action.payload.id ? action.payload : state
          ),
        };
      case DELETE_STATE_SUCCESS_ACTION:
  
        return {
          ...state,
          stateList: state.stateList.filter(
            (item) => item.id !== action.payload
          ),
          stateDtlsCount: state.stateDtlsCount - 1
        };
  

    default:
      return {
        ...state,
      };
  }
}
