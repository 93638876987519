import { put, takeEvery, select, call, all } from "redux-saga/effects";
import { createBrowserHistory } from "history";

import {
  FETCH_EARNINGS_INIT_ACTION,
  FETCH_EARNINGS_SUCCESS_ACTION,
  FETCH_EARNINGS_FAIL_ACTION,
  FETCH_EARNINGSBYID_INIT_ACTION,
  FETCH_EARNINGSBYID_SUCCESS_ACTION,
  FETCH_EARNINGSBYID_FAIL_ACTION,
  EXPORT_EARNINGS_INIT_ACTION,
  EXPORT_EARNINGS_SUCCESS_ACTION,
  EXPORT_EARNINGS_FAIL_ACTION,
} from "./action";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { shallow } from "enzyme";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

var dateFormat = require("dateformat");

function* fetchEarnings(value) {
  const token = localStorage.getItem("saleStraitToken");
  const userId = localStorage.getItem("saleStraitUserId");
  let searchVal =
    !value || !value.value || !value.value.searchQuery
      ? ""
      : value.value.searchQuery;
  let pageno = (value.value && value.value.page) || 1;
  let filter =
    !value || !value.value || !value.value.filter ? null : value.value.filter;

  let _url = `${appConfig.ip}/earnings?receiver=${userId}&limit=10&search=${searchVal}&page=${pageno}`;

  if (filter) {
    _url = `${_url}&licenseeId=${filter}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_EARNINGS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_EARNINGS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* getEarningsById(value) {
  const token = localStorage.getItem("saleStraitToken");

  try {
    const res = yield fetch(`${appConfig.ip}/earnings/` + value.value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_EARNINGSBYID_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_EARNINGSBYID_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* exportEarnings(value) {
  let fileName = "Platform earnigs Report";
  const token = localStorage.getItem("saleStraitToken");
  const userId = localStorage.getItem("saleStraitUserId");
  let searchVal =
    !value || !value.value || !value.value.searchQuery
      ? ""
      : value.value.searchQuery;
  let pageno = (value.value && value.value.page) || 1;
  let filter =
    !value || !value.value || !value.value.filter ? null : value.value.filter;

  let _url = `${appConfig.ip}/earnings?receiver=${userId}&limit=100&search=${searchVal}&page=${pageno}`;

  if (filter) {
    _url = `${_url}&licenseeId=${filter}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
      let csvData = [];
      resJSON.rows.map((item) => {
        csvData.push({
          EARNINGS_ID: item.refId,
          AMOUNT: item && item.amount ? item.amount : "NIL",
          ORGANIZATION_NAME:
            item.licenseeId != null ? item.licenseeId.orgName : "NIL",
          ORDER_ID: item.order != null ? item.order.refId : "NIL",

          STATUS: item && item.status ? item.status : "NIL",

          CREATED_DATE: item && dateFormat(item.createdAt, "mediumDate"),
        });
      });
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(csvData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      yield put({
        type: EXPORT_EARNINGS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EXPORT_EARNINGS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

export function* EarningsActionWatcher() {
  yield takeEvery(FETCH_EARNINGS_INIT_ACTION, fetchEarnings);
  yield takeEvery(FETCH_EARNINGSBYID_INIT_ACTION, getEarningsById);
  yield takeEvery(EXPORT_EARNINGS_INIT_ACTION, exportEarnings);
}
