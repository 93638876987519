import {
  FETCH_STATE_BY_DISTRICT_SUCCESS_ACTION,
  FETCH_DISTRICT_SUCCESS_ACTION,
  DELETE_DISTRICT_SUCCESS_ACTION,
  EDIT_DISTRICT_SUCCESS_ACTION
} from "./action";

const initialState = {
  processing: false,
  error: false,
};

export default function DistrictReducer(
  state: any = initialState,
  action: Function
) {
  switch (action.type) {
    case FETCH_STATE_BY_DISTRICT_SUCCESS_ACTION:
      return {
        ...state,
        stateList: action.payload.rows,
      };

    case FETCH_DISTRICT_SUCCESS_ACTION:
      return {
        ...state,
        DistrictList: action.payload.rows,
        districtDtlsCount: action.payload.count,
      };
      case EDIT_DISTRICT_SUCCESS_ACTION:
        return {
          ...state,
          DistrictList: state.DistrictList.map((district) =>
          district.id === action.payload.id ? action.payload : district
          ),
        };
      case DELETE_DISTRICT_SUCCESS_ACTION:
        return {
          ...state,
          DistrictList: state.DistrictList.filter(
            (item) => item.id !== action.payload
          ),
          districtDtlsCount: state.districtDtlsCount - 1
        };

    default:
      return {
        ...state,
      };
  }
}
