export const FETCH_COUNTRIES_INIT_ACTION = "FETCH_COUNTRIES_INIT_ACTION"
export const FETCH_COUNTRIES_SUCCESS_ACTION = FETCH_COUNTRIES_INIT_ACTION + '_SUCCESS'
export const FETCH_COUNTRIES_FAIL_ACTION = FETCH_COUNTRIES_INIT_ACTION + '_ERROR'

export const ADD_COUNTRIES_INIT_ACTION = "ADD_COUNTRIES_INIT_ACTION"
export const ADD_COUNTRIES_SUCCESS_ACTION = ADD_COUNTRIES_INIT_ACTION + '_SUCCESS'
export const ADD_COUNTRIES_FAIL_ACTION = ADD_COUNTRIES_INIT_ACTION + '_ERROR'

export const DELETE_COUNTRIES_INIT_ACTION = "DELETE_COUNTRIES_INIT_ACTION"
export const DELETE_COUNTRIES_SUCCESS_ACTION = DELETE_COUNTRIES_INIT_ACTION + '_SUCCESS'
export const DELETE_COUNTRIES_FAIL_ACTION = DELETE_COUNTRIES_INIT_ACTION + '_ERROR'

export const EDIT_COUNTRIES_INIT_ACTION = "EDIT_COUNTRIES_INIT_ACTION"
export const EDIT_COUNTRIES_SUCCESS_ACTION = EDIT_COUNTRIES_INIT_ACTION + '_SUCCESS'
export const EDIT_COUNTRIES_FAIL_ACTION = EDIT_COUNTRIES_INIT_ACTION + '_ERROR'

export function fetchCountries() {
   
    return {
        type: FETCH_COUNTRIES_INIT_ACTION
    }
}

export function AddCountry(value) {
   
    return {
        type: ADD_COUNTRIES_INIT_ACTION,
        value:value
    }
}
export function DeleteValue(value) {
    return {
        type: DELETE_COUNTRIES_INIT_ACTION,
        value: value,
       
    }
}
export function EditCountry(value) {
   
    return {
        type: EDIT_COUNTRIES_INIT_ACTION,
        value:value
    }
}

export function countrySelectedPage(value) {
 
    return {
        type: FETCH_COUNTRIES_INIT_ACTION,
        value:value
    }
}