import { put, takeEvery } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_COUNTRIES_INIT_ACTION,
  FETCH_COUNTRIES_SUCCESS_ACTION,
  FETCH_COUNTRIES_FAIL_ACTION,
  ADD_COUNTRIES_INIT_ACTION,
  ADD_COUNTRIES_SUCCESS_ACTION,
  ADD_COUNTRIES_FAIL_ACTION,
  DELETE_COUNTRIES_INIT_ACTION,
  DELETE_COUNTRIES_SUCCESS_ACTION,
  DELETE_COUNTRIES_FAIL_ACTION,
  EDIT_COUNTRIES_INIT_ACTION,
  EDIT_COUNTRIES_SUCCESS_ACTION,
  EDIT_COUNTRIES_FAIL_ACTION,

} from "./action";
import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastrMsg from '../../screens/Common/ToastrMsg'

var btoa = require("Base64").btoa;
var history = createBrowserHistory();

function* fetchcountries(value) {
  let pageno = value.value === undefined ? 1 : value.value;

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/country?limit=10&page=` + pageno+'&isActive=true', {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
    
      yield put({
        type: FETCH_COUNTRIES_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_COUNTRIES_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* addcountries(value) {
  
  try {
    const token = localStorage.getItem("saleStraitToken");

    const data = {
     
      name: value.value.name,
      isoName: value.value.isoName,
      iso3Name: value.value.iso3Name,
      niceName: value.value.niceName,
      callingCode: value.value.callingCode,
      isActive: value.value.isActive,
    };

    const res = yield fetch(`${appConfig.ip}/country`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: ADD_COUNTRIES_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success(ToastrMsg.addMsg, {
        autoClose: 3000,
      });
      yield put({
        type: FETCH_COUNTRIES_INIT_ACTION,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_COUNTRIES_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* editcountries(value) {
  let id = value.value.id;
  let data = {
  
    name: value.value.name,
    isoName: value.value.isoName,
    iso3Name: value.value.iso3Name,
    niceName: value.value.niceName,
    callingCode: value.value.callingCode,
    isActive: value.value.isActive,
  };

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/country/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield toast.success(ToastrMsg.updateMsg, {
        autoClose: 3000,
      });
      const resJSON = yield res.json();
      yield put({
        type: EDIT_COUNTRIES_SUCCESS_ACTION,
        payload:resJSON
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EDIT_COUNTRIES_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* DeleteCountry(value) {

  let id = value.value.id;
  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/country/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
    
      yield put({
        type: DELETE_COUNTRIES_SUCCESS_ACTION,
        payload:id
      });
      yield toast.success(ToastrMsg.delMsg, {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: DELETE_COUNTRIES_FAIL_ACTION, error: err });
    } else {
    }
  }
}



export function* countryActionWatcher() {
  yield takeEvery(FETCH_COUNTRIES_INIT_ACTION, fetchcountries);
  yield takeEvery(ADD_COUNTRIES_INIT_ACTION, addcountries);
  yield takeEvery(DELETE_COUNTRIES_INIT_ACTION, DeleteCountry);
  yield takeEvery(EDIT_COUNTRIES_INIT_ACTION, editcountries);
}
