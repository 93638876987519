export const FETCH_GST_INIT_ACTION = "FETCH_GST_INIT_ACTION";
export const FETCH_GST_SUCCESS_ACTION =
  FETCH_GST_INIT_ACTION + "_SUCCESS";
export const FETCH_GST_FAIL_ACTION = FETCH_GST_INIT_ACTION + "_ERROR";

export const ADD_GST_INIT_ACTION = "ADD_GST_INIT_ACTION";
export const ADD_GST_SUCCESS_ACTION =
  ADD_GST_INIT_ACTION + "_SUCCESS";
export const ADD_GST_FAIL_ACTION = ADD_GST_INIT_ACTION + "_ERROR";

export const EDIT_GST_INIT_ACTION = "EDIT_GST_INIT_ACTION";
export const EDIT_GST_SUCCESS_ACTION =
  EDIT_GST_INIT_ACTION + "_SUCCESS";
export const EDIT_GST_FAIL_ACTION = EDIT_GST_INIT_ACTION + "_ERROR";

export const DELETE_GST_INIT_ACTION = "DELETE_GST_INIT_ACTION";
export const DELETE_GST_SUCCESS_ACTION =
  DELETE_GST_INIT_ACTION + "_SUCCESS";
export const DELETE_GST_FAIL_ACTION =
  DELETE_GST_INIT_ACTION + "_ERROR";


export function fetchGst(value) {
  return {
    type: FETCH_GST_INIT_ACTION,
    value: value,

  };
}

export function AddGst(value) {
  return {
    type: ADD_GST_INIT_ACTION,
    value: value,
  };
}

export function EditGst(value) {
  return {
    type: EDIT_GST_INIT_ACTION,
    value: value,
  };
}

export function DeleteValue(value) {
  return {
    type: DELETE_GST_INIT_ACTION,
    value: value,
  };
}

// export function gstSlectedPage(value) {
//   return {
//     type: FETCH_GST_INIT_ACTION,
//     value: value,
//   };
// }



