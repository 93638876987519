import React, { useState, useEffect } from "react"
import "./login.css"
import { Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css'
import { Container, Row, Col, Card } from "react-bootstrap";
import { TextField } from "../Common/TextField"
import * as Yup from 'yup';
import { Formik, Form } from 'formik';

function Login(props) {

  const validate = Yup.object({
    username: Yup.string()
      .required('Username is required'),
    password: Yup.string()
      .required('Password is required')
      .min(6, 'Password must be atleast 6 characters long'),

  });

  return (

    <Formik
      initialValues={{
        username: "",
        password: "",
      }}
      validationSchema={validate}
      onSubmit={values => {
      
        props.logins(values)

      }}
    >
      {formik => (

<div>

<div className="bgcolorLogin container-fluid">

  <Container>
    <div className="cardMainlogin">


      <Card>
        <Card.Header className="cardHeading">Salestrait</Card.Header>

        <Card.Body>
          <Row className="loginform">


            <Col xs={12} md={12} sm={12} lg={12}>


                        <div className="formDiv">
                          <Form>

                            <div>
                              <TextField label="Username" name="username" type="text" />
                            </div>

                            <div>
                              <TextField label="Password" name="password" type="password" />
                            </div>

                            <button className="contactpagebtn" type="submit">Submit</button>

                           

                          </Form>

                        </div>

                      </Col>

                    </Row>
                  </Card.Body>
                </Card>
              </div>
            </Container>

          </div>
        </div>
      )}
    </Formik>

  );

}

export default Login;
