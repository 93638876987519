export const FETCH_LEADS_INIT_ACTION = "FETCH_LEADS_INIT_ACTION";
export const FETCH_LEADS_SUCCESS_ACTION = FETCH_LEADS_INIT_ACTION + "_SUCCESS";
export const FETCH_LEADS_FAIL_ACTION = FETCH_LEADS_INIT_ACTION + "_ERROR";

export const FETCH_CUSTOMERS_BY_SALESEXECUTIVE_INIT_ACTION =
  "FETCH_CUSTOMERS_BY_SALESEXECUTIVE_INIT_ACTION";
export const FETCH_CUSTOMERS_BY_SALESEXECUTIVE_SUCCESS_ACTION =
  FETCH_CUSTOMERS_BY_SALESEXECUTIVE_INIT_ACTION + "_SUCCESS";
export const FETCH_CUSTOMERS_BY_SALESEXECUTIVE_FAIL_ACTION =
  FETCH_CUSTOMERS_BY_SALESEXECUTIVE_INIT_ACTION + "_ERROR";

export const FETCH_PRODUCTS_BY_SALESEXECUTIVE_INIT_ACTION =
  "FETCH_PRODUCTS_BY_SALESEXECUTIVE_INIT_ACTION";
export const FETCH_PRODUCTS_BY_SALESEXECUTIVE_SUCCESS_ACTION =
  FETCH_PRODUCTS_BY_SALESEXECUTIVE_INIT_ACTION + "_SUCCESS";
export const FETCH_PRODUCTS_BY_SALESEXECUTIVE_FAIL_ACTION =
  FETCH_PRODUCTS_BY_SALESEXECUTIVE_INIT_ACTION + "_ERROR";

export const FETCH_LEADS_DETAIL_INIT_ACTION = "FETCH_LEADS_DETAIL_INIT_ACTION";
export const FETCH_LEADS_DETAIL_SUCCESS_ACTION =
  FETCH_LEADS_DETAIL_INIT_ACTION + "_SUCCESS";
export const FETCH_LEADS_DETAIL_FAIL_ACTION =
  FETCH_LEADS_DETAIL_INIT_ACTION + "_ERROR";

export const FETCH_USER_DETAIL_INIT_ACTION = "FETCH_USER_DETAIL_INIT_ACTION";
export const FETCH_USER_DETAIL_SUCCESS_ACTION =
  FETCH_USER_DETAIL_INIT_ACTION + "_SUCCESS";
export const FETCH_USER_DETAIL_FAIL_ACTION =
  FETCH_USER_DETAIL_INIT_ACTION + "_ERROR";

// export const FETCH_LICENSEEFILTER_INIT_ACTION = "FETCH_LICENSEEFILTER_INIT_ACTION";
// export const FETCH_LICENSEEFILTER_SUCCESS_ACTION =
//   FETCH_LICENSEEFILTER_INIT_ACTION + "_SUCCESS";
// export const FETCH_LICENSEEFILTER_FAIL_ACTION = FETCH_LICENSEEFILTER_INIT_ACTION + "_ERROR";

// export const FETCH_LEADS_CLEAR_ACTION = "FETCH_LEADS_CLEAR_ACTION";

export function fetchLeads(value) {
  return {
    type: FETCH_LEADS_INIT_ACTION,
    value: value,
  };
}

export function fetchCusBySExec(value) {
  return {
    type: FETCH_CUSTOMERS_BY_SALESEXECUTIVE_INIT_ACTION,
    value: value,
  };
}

export function fetchPrdtBySExec(value) {
  return {
    type: FETCH_PRODUCTS_BY_SALESEXECUTIVE_INIT_ACTION,
    value: value,
  };
}

// export function clearLeads() {
//   return {
//     type:FETCH_LEADS_CLEAR_ACTION ,
//   };
// }

export function fetchLeadsDetail(value) {
  return {
    type: FETCH_LEADS_DETAIL_INIT_ACTION,
    value: value,
  };
}

export function fetchUserDetail(value) {
  return {
    type: FETCH_USER_DETAIL_INIT_ACTION,
    value: value,
  };
}

// export function fetchLicenseeFilter(value) {
//   return {
//     type: FETCH_LICENSEEFILTER_INIT_ACTION,
//     value: value,

//   };
// }
