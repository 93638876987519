import {
  FETCH_PAYMENTMODE_SUCCESS_ACTION,

  EDIT_PAYMENTMODE_SUCCESS_ACTION,
  DELETE_PAYMENTMODE_SUCCESS_ACTION
} from "./action";

const initialState = {
  processing: false,
  error: false,
};

export default function (state: any = initialState, action: Function) {
  switch (action.type) {
    case FETCH_PAYMENTMODE_SUCCESS_ACTION:
      return {
        ...state,
        PaymentModeList: action.payload.rows,
        paymentModeDtlsCount: action.payload.count,
      };

 
    case EDIT_PAYMENTMODE_SUCCESS_ACTION:
      return {
        ...state,
        PaymentModeList: state.PaymentModeList.map((paymentMode) =>
        paymentMode.id === action.payload.id ? action.payload : paymentMode
        ),
      };
    case DELETE_PAYMENTMODE_SUCCESS_ACTION:

      return {
        ...state,
        PaymentModeList: state.PaymentModeList.filter(
          (item) => item.id !== action.payload
        ),
        paymentModeDtlsCount: state.paymentModeDtlsCount - 1
      };

    default:
      return {
        ...state,
      };
  }
}
