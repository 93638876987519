import * as actionTypes from "./constants";

const initialState = {
  processing: false,
  error: false,
  isModel: false,
};

export default function testReportUrlReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_APP_CONFIG_IN_TEST_REPORT_SUCCESS_ACTION:
      return {
        ...state,
        testReportUrlList: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
}
