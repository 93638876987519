import { put, takeEvery } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_PRODUCTTYPE_INIT_ACTION,
  FETCH_PRODUCTTYPE_SUCCESS_ACTION,
  FETCH_PRODUCTTYPE_FAIL_ACTION,
  ADD_PRODUCTTYPE_INIT_ACTION,
  ADD_PRODUCTTYPE_SUCCESS_ACTION,
  ADD_PRODUCTTYPE_FAIL_ACTION,
  EDIT_PRODUCTTYPE_INIT_ACTION,
  EDIT_PRODUCTTYPE_SUCCESS_ACTION,
  EDIT_PRODUCTTYPE_FAIL_ACTION,
  DELETE_PRODUCTTYPE_INIT_ACTION,
  DELETE_PRODUCTTYPE_SUCCESS_ACTION,
  DELETE_PRODUCTTYPE_FAIL_ACTION,
} from "./action";
import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var btoa = require("Base64").btoa;
var history = createBrowserHistory();

function* fetchProductType(value) {
  let pageno = (value.value && value.value.page) || 1;
  let searchVal =
    !value || !value.value || !value.value.searchQuery
      ? ""
      : value.value.searchQuery;

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(
      `${appConfig.ip}/prdtType?limit=10&search=${searchVal}&page=${pageno}&isActive=true`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_PRODUCTTYPE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_PRODUCTTYPE_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* AddProductType(value) {
  try {
    const token = localStorage.getItem("saleStraitToken");

    const data = {
      name: value.value.name,
      symbol: value.value.symbol,
      desc: value.value.desc,
    };

    const res = yield fetch(`${appConfig.ip}/prdtType`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: ADD_PRODUCTTYPE_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Product Type added successfully", {
        autoClose: 3000,
      });

      yield put({ type: FETCH_PRODUCTTYPE_INIT_ACTION, value: { page: 1 } });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_PRODUCTTYPE_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* EditProductType(value) {
  let id = value.value.id;
  let data = {
    name: value.value.name,
    symbol: value.value.symbol,
    desc: value.value.desc,
  };

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/prdtType/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield toast.success("Product Type updated successfully", {
        autoClose: 3000,
      });
      const resJSON = yield res.json();
      yield put({
        type: EDIT_PRODUCTTYPE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EDIT_PRODUCTTYPE_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* DeleteProductType(value) {
  let id = value.value.id;

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/prdtType/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield put({
        type: DELETE_PRODUCTTYPE_SUCCESS_ACTION,
        payload: id,
      });
      yield toast.success("Deleted successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: DELETE_PRODUCTTYPE_FAIL_ACTION, error: err });
    } else {
    }
  }
}

export function* ProductTypeActionWatcher() {
  yield takeEvery(FETCH_PRODUCTTYPE_INIT_ACTION, fetchProductType);
  yield takeEvery(ADD_PRODUCTTYPE_INIT_ACTION, AddProductType);
  yield takeEvery(EDIT_PRODUCTTYPE_INIT_ACTION, EditProductType);
  yield takeEvery(DELETE_PRODUCTTYPE_INIT_ACTION, DeleteProductType);
}
