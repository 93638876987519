import { put, takeEvery } from "redux-saga/effects";
import { createBrowserHistory } from "history";

import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  FETCH_APPCONFIG_INIT_ACTION,
  FETCH_APPCONFIG_SUCCESS_ACTION,
  FETCH_APPCONFIG_FAIL_ACTION,
  EDIT_APPCONFIG_INIT_ACTION,
  EDIT_APPCONFIG_SUCCESS_ACTION,
  EDIT_APPCONFIG_FAIL_ACTION,
} from "./action";

var btoa = require("Base64").btoa;
var history = createBrowserHistory();

function* fetchappVersionList() {
  const token = localStorage.getItem("saleStraitToken");

  try {
    const res = yield fetch(`${appConfig.ip}/appConfig`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_APPCONFIG_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_APPCONFIG_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* editAppConfig(value) {
  const data = {
    partnerApiUrl: value.value.partnerApiUrl,
    helpDocUrl: value.value.helpDocUrl,
    externalApiUrl: value.value.externalApiUrl,
    testReportUrl: value.value.testReportUrl,
    apiDocUrl: value.value.apiDocUrl,
  };

  try {
    const token = localStorage.getItem("saleStraitToken");

    const res = yield fetch(`${appConfig.ip}/appConfig`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: EDIT_APPCONFIG_SUCCESS_ACTION,
        payload: resJSON,
      });

      yield put({
        type: FETCH_APPCONFIG_INIT_ACTION,
      });
      yield toast.success("App Config updated successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EDIT_APPCONFIG_FAIL_ACTION, error: err });
    } else {
    }
  }
}

export function* AppConfigActionWatcher() {
  yield takeEvery(FETCH_APPCONFIG_INIT_ACTION, fetchappVersionList);
  yield takeEvery(EDIT_APPCONFIG_INIT_ACTION, editAppConfig);
}
