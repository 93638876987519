import { put, takeEvery } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_CUSTOMER_INIT_ACTION,
  FETCH_CUSTOMER_SUCCESS_ACTION,
  FETCH_CUSTOMER_FAIL_ACTION,
  FETCH_CUSTOMERBYID_INIT_ACTION,
  FETCH_CUSTOMERBYID_SUCCESS_ACTION,
  FETCH_CUSTOMERBYID_FAIL_ACTION,
  FETCH_REGIONDROPDOWN_INIT_ACTION,
  FETCH_REGIONDROPDOWN_SUCCESS_ACTION,
  FETCH_REGIONDROPDOWN_FAIL_ACTION,
  FETCH_AREADROPDOWN_INIT_ACTION,
  FETCH_AREADROPDOWN_SUCCESS_ACTION,
  FETCH_AREADROPDOWN_FAIL_ACTION,
  FETCH_ZONEDROPDOWN_INIT_ACTION,
  FETCH_ZONEDROPDOWN_SUCCESS_ACTION,
  FETCH_ZONEDROPDOWN_FAIL_ACTION,
} from "./action";
import { push } from "connected-react-router";
import { appConfig } from "../../config";

var btoa = require("Base64").btoa;
var history = createBrowserHistory();

function* fetchCustomer(value) {
  let pageno = (value.value && value.value.page) || 1;
  const role = localStorage.getItem("UserRole");
  const userid = localStorage.getItem("saleStraitUserId");

  const token = localStorage.getItem("saleStraitToken");
  let filterVal =
    !value || !value.value || !value.value.filterVal
      ? ""
      : value.value.filterVal;
  let filterValRegion =
    !value || !value.value || !value.value.filterValRegion
      ? null
      : value.value.filterValRegion;
  let filterValZone =
    !value || !value.value || !value.value.filterValZone
      ? null
      : value.value.filterValZone;
  let filterValArea =
    !value || !value.value || !value.value.filterValArea
      ? null
      : value.value.filterValArea;
  let searchVal =
    !value || !value.value || !value.value.searchQuery
      ? ""
      : value.value.searchQuery;
  let filter =
    !value || !value.value || !value.value.filter ? null : value.value.filter;

  let getDataUrl = [
    {
      role: "admin",
      url: `/customer?limit=10&page=${pageno}`,
    },
    {
      role: "channelPartner",
      url: `/customer?limit=10&page=${pageno}&channelPartner=${userid}`,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);

  let _url = `${appConfig.ip}${apiUrl.url}`;

  // let _url = `${appConfig.ip}/customer?limit=10&search=${searchVal}${filterVal}&page=${pageno}`;

  if (filterValRegion && !filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}`;
  } else if (filterValRegion && filterValZone && !filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}`;
  } else if (filterValRegion && filterValZone && filterValArea) {
    _url = `${_url}&region=${filterValRegion}&zone=${filterValZone}&area=${filterValArea}`;
  }
  if (filter) {
    _url = `${_url}&licenseeId=${filter}`;
  }
  if (searchVal) {
    _url = `${_url}&search=${searchVal}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_CUSTOMER_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_CUSTOMER_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* getCustomerById(value) {
  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/customer/` + value.value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_CUSTOMERBYID_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_CUSTOMERBYID_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchRegionDropdown() {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/region?&isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_REGIONDROPDOWN_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_REGIONDROPDOWN_FAIL_ACTION, error: err });
    } else {
    }
  }
}

// Get area list

function* fetchAreaDropdown(value) {
  let zone = !value || !value.value.id ? "" : value.value.id;
  const token = localStorage.getItem("saleStraitToken");

  try {
    const res = yield fetch(`${appConfig.ip}/area?zone=${zone}&isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_AREADROPDOWN_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_AREADROPDOWN_FAIL_ACTION, error: err });
    } else {
    }
  }
}

// Get zone list

function* fetchZoneDropdown(value) {
  let region = !value || !value.value.id ? "" : value.value.id;
  const token = localStorage.getItem("saleStraitToken");

  try {
    const res = yield fetch(
      `${appConfig.ip}/zone?&region=${region}&isActive=true`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_ZONEDROPDOWN_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_ZONEDROPDOWN_FAIL_ACTION, error: err });
    } else {
    }
  }
}

export function* CustomerActionWatcher() {
  yield takeEvery(FETCH_CUSTOMER_INIT_ACTION, fetchCustomer);
  yield takeEvery(FETCH_CUSTOMERBYID_INIT_ACTION, getCustomerById);
  yield takeEvery(FETCH_REGIONDROPDOWN_INIT_ACTION, fetchRegionDropdown);
  yield takeEvery(FETCH_AREADROPDOWN_INIT_ACTION, fetchAreaDropdown);
  yield takeEvery(FETCH_ZONEDROPDOWN_INIT_ACTION, fetchZoneDropdown);
}
