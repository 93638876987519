export const FETCH_CUSTOMER_INIT_ACTION = "FETCH_CUSTOMER_INIT_ACTION";
export const FETCH_CUSTOMER_SUCCESS_ACTION =
  FETCH_CUSTOMER_INIT_ACTION + "_SUCCESS";
export const FETCH_CUSTOMER_FAIL_ACTION = FETCH_CUSTOMER_INIT_ACTION + "_ERROR";


export const FETCH_LEADS_INIT_ACTION = "FETCH_LEADS_INIT_ACTION";
export const FETCH_LEADS_SUCCESS_ACTION =
  FETCH_LEADS_INIT_ACTION + "_SUCCESS";
export const FETCH_LEADS_FAIL_ACTION = FETCH_LEADS_INIT_ACTION + "_ERROR";

export const FETCH_CUSTOMERBYID_INIT_ACTION = "FETCH_CUSTOMERBYID_INIT_ACTION";
export const FETCH_CUSTOMERBYID_SUCCESS_ACTION = FETCH_CUSTOMERBYID_INIT_ACTION + "_SUCCESS";
export const FETCH_CUSTOMERBYID_FAIL_ACTION = FETCH_CUSTOMERBYID_INIT_ACTION + "_ERROR";

export const FETCH_REGIONDROPDOWN_INIT_ACTION = "FETCH_REGIONDROPDOWN_INIT_ACTION";
export const FETCH_REGIONDROPDOWN_SUCCESS_ACTION = FETCH_REGIONDROPDOWN_INIT_ACTION + "_SUCCESS";
export const FETCH_REGIONDROPDOWN_FAIL_ACTION = FETCH_REGIONDROPDOWN_INIT_ACTION + "_ERROR";


export const FETCH_AREADROPDOWN_INIT_ACTION = "FETCH_AREADROPDOWN_INIT_ACTION";
export const FETCH_AREADROPDOWN_SUCCESS_ACTION =FETCH_AREADROPDOWN_INIT_ACTION + "_SUCCESS";
export const FETCH_AREADROPDOWN_FAIL_ACTION = FETCH_AREADROPDOWN_INIT_ACTION + "_ERROR";

export const FETCH_ZONEDROPDOWN_INIT_ACTION = "FETCH_ZONEDROPDOWN_INIT_ACTION";
export const FETCH_ZONEDROPDOWN_SUCCESS_ACTION =FETCH_ZONEDROPDOWN_INIT_ACTION + "_SUCCESS";
export const FETCH_ZONEDROPDOWN_FAIL_ACTION = FETCH_ZONEDROPDOWN_INIT_ACTION + "_ERROR";


export function fetchCustomer(value) {
 

  return {
    type: FETCH_CUSTOMER_INIT_ACTION,
    value: value,

  };
}



export function fetchLeads(value) {
  return {
    type: FETCH_LEADS_INIT_ACTION,
    value: value
  };
}

export function getCustomerById(value) {
  return {
    type: FETCH_CUSTOMERBYID_INIT_ACTION,
    value:value
  };
}

export function fetchRegionDropdown() {
  return {
    type: FETCH_REGIONDROPDOWN_INIT_ACTION,
  };
}

export function fetchAreaDropdown(value) {
  return {
    type: FETCH_AREADROPDOWN_INIT_ACTION,
    value: value
  };
}

export function fetchZoneDropdown(value) {
  return {
    type: FETCH_ZONEDROPDOWN_INIT_ACTION,
    value: value

  };
}
