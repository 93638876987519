import { put, takeEvery } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_UNIT_INIT_ACTION,
  FETCH_UNIT_SUCCESS_ACTION,
  FETCH_UNIT_FAIL_ACTION,
  ADD_UNIT_INIT_ACTION,
  ADD_UNIT_SUCCESS_ACTION,
  ADD_UNIT_FAIL_ACTION,
  EDIT_UNIT_INIT_ACTION,
  EDIT_UNIT_SUCCESS_ACTION,
  EDIT_UNIT_FAIL_ACTION,
  DELETE_UNIT_INIT_ACTION,
  DELETE_UNIT_SUCCESS_ACTION,
  DELETE_UNIT_FAIL_ACTION,

} from "./action";
import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var btoa = require("Base64").btoa;
var history = createBrowserHistory();

function* fetchUnit(value) {
  let pageno = value.value === undefined ? 1 : value.value;

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/Unit?limit=10&page=` + pageno+'&isActive=true', {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
     
      yield put({
        type: FETCH_UNIT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_UNIT_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* AddUnit(value) {
  try {
    const token = localStorage.getItem("saleStraitToken");

    const data = {
     
      name: value.value.name,
      symbol: value.value.symbol,
      desc: value.value.desc,



      
    };

    const res = yield fetch(`${appConfig.ip}/Unit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: ADD_UNIT_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Unit added successfully", {
        autoClose: 3000,
      });
      yield put({
        type: FETCH_UNIT_INIT_ACTION,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_UNIT_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* EditUnit(value) {
  let id = value.value.id;
  let data = {
  
    name: value.value.name,
      symbol: value.value.symbol,
      desc: value.value.desc,
  };

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/Unit/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield toast.success("Unit updated successfully", {
        autoClose: 3000,
      });
      const resJSON = yield res.json();
      yield put({
        type: EDIT_UNIT_SUCCESS_ACTION,
        payload:resJSON
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EDIT_UNIT_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* DeleteUnit(value) {
  let id = value.value.id;
  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/Unit/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      // yield put({ type: FETCH_UNIT_INIT_ACTION });
      // const resJSON = yield res.json();
      yield put({
        type: DELETE_UNIT_SUCCESS_ACTION,
        payload:id
      });
      yield toast.success("Deleted successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: DELETE_UNIT_FAIL_ACTION, error: err });
    } else {
    }
  }
}



export function* UnitActionWatcher() {
  yield takeEvery(FETCH_UNIT_INIT_ACTION, fetchUnit);
  yield takeEvery(ADD_UNIT_INIT_ACTION, AddUnit);
  yield takeEvery(EDIT_UNIT_INIT_ACTION, EditUnit);
  yield takeEvery(DELETE_UNIT_INIT_ACTION, DeleteUnit);

}
