import { put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "./constants";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* fetchExpenseType(params) {
  let page = (params.params && params.params.page) || 1;
  let q = (params.params && params.params.search) || "";
  try {
    const token = localStorage.getItem("saleStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/expenseType?isActive=true&limit=10&page=${page}&search=${q}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.FETCH_EXPENSE_TYPE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_EXPENSE_TYPE_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* addExpenseType(value) {
  let data = value.value;
  try {
    const token = localStorage.getItem("saleStraitToken");
    const res = yield fetch(`${appConfig.ip}/expenseType`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();
      yield put({
        type: actionTypes.ADD_EXPENSE_TYPE_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Expense type added successfully", {
        autoClose: 3000,
      });
      yield put({
        type: actionTypes.FETCH_EXPENSE_TYPE_INIT_ACTION,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.ADD_EXPENSE_TYPE_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* editExpenseType(value) {
  let id = value.id;
  let data = value.params;
  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/expenseType/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield toast.success("Expense type edited successfully", {
        autoClose: 3000,
      });
      const resJSON = yield res.json();
      yield put({ type: actionTypes.FETCH_EXPENSE_TYPE_INIT_ACTION });
      yield put({
        type: actionTypes.EDIT_EXPENSE_TYPE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.EDIT_EXPENSE_TYPE_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* deleteExpenseType(params) {
  let id = params.data.id;

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/expenseType/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield put({ type: actionTypes.FETCH_EXPENSE_TYPE_INIT_ACTION });

      yield put({
        type: actionTypes.DELETE_EXPENSE_TYPE_SUCCESS_ACTION,
        payload: id,
      });
      yield toast.success("Deleted successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.DELETE_EXPENSE_TYPE_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

export function* expenseTypeActionWatcher() {
  yield takeEvery(actionTypes.FETCH_EXPENSE_TYPE_INIT_ACTION, fetchExpenseType);
  yield takeEvery(actionTypes.ADD_EXPENSE_TYPE_INIT_ACTION, addExpenseType);
  yield takeEvery(
    actionTypes.DELETE_EXPENSE_TYPE_INIT_ACTION,
    deleteExpenseType
  );
  yield takeEvery(actionTypes.EDIT_EXPENSE_TYPE_INIT_ACTION, editExpenseType);
}
