import {
  FETCH_LOCALITYY_SUCCESS_ACTION,
  EDIT_LOCALITYY_SUCCESS_ACTION,
  DELETE_LOCALITYY_SUCCESS_ACTION,
  FETCH_LOCALITYY_DISTRICT_SUCCESS_ACTION,
  FETCH_STATES_SUCCESS_ACTION,
  FETCH_COUNTRY_DRODOWN_SUCCESS_ACTION
} from "./action";

const initialState = {
  processing: false,
  error: false,
};

export default function (state: any = initialState, action: Function) {
  switch (action.type) {
    case FETCH_LOCALITYY_SUCCESS_ACTION:
      return {
        ...state,
        LocalityyList: action.payload.rows,
        LocalityyDtlsCount: action.payload.count,
      };

    case FETCH_LOCALITYY_DISTRICT_SUCCESS_ACTION:
      return {
        ...state,
        LocalityyDistrictList: action.payload.rows,
        LocalityyDistrictDtlsCount: action.payload.count,
      };
    case EDIT_LOCALITYY_SUCCESS_ACTION:
      return {
        ...state,
        LocalityyList: state.LocalityyList.map((Localityy) =>
        Localityy.id === action.payload.id ? action.payload : Localityy
        ),
      };
    case DELETE_LOCALITYY_SUCCESS_ACTION:

      return {
        ...state,
        LocalityyList: state.LocalityyList.filter(
          (item) => item.id !== action.payload
        ),
        LocalityyDtlsCount: state.LocalityyDtlsCount - 1
      };
      case FETCH_STATES_SUCCESS_ACTION:
        return {
          ...state,
          stateList: action.payload.rows,
        };

        case FETCH_COUNTRY_DRODOWN_SUCCESS_ACTION:
          return {
            ...state,
            countryList: action.payload.rows,
          };

    default:
      return {
        ...state,
      };
  }
}
