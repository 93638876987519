import {
  FETCH_GST_SUCCESS_ACTION,

  EDIT_GST_SUCCESS_ACTION,
  DELETE_GST_SUCCESS_ACTION
} from "./action";

const initialState = {
  processing: false,
  error: false,
};

export default function (state: any = initialState, action: Function) {
  switch (action.type) {
    case FETCH_GST_SUCCESS_ACTION:
      return {
        ...state,
        GstList: action.payload.rows,
        gstDtlsCount: action.payload.count,
      };

 
    case EDIT_GST_SUCCESS_ACTION:
      return {
        ...state,
        GstList: state.GstList.map((gst) =>
        gst.id === action.payload.id ? action.payload : gst
        ),
      };
    case DELETE_GST_SUCCESS_ACTION:

      return {
        ...state,
        GstList: state.GstList.filter(
          (item) => item.id !== action.payload
        ),
        gstDtlsCount: state.gstDtlsCount - 1
      };

    default:
      return {
        ...state,
      };
  }
}
