import {
    FETCH_PRODUCT_SUCCESS_ACTION,
    FETCH_PRODUCTBYID_INIT_ACTION,
    FETCH_PRODUCTBYID_SUCCESS_ACTION,
    FETCH_PRODUCTBYID_FAIL_ACTION,
    FETCH_LICENSEEFILTER_SUCCESS_ACTION
    
  } from "./action";
  
  const initialState = {
    processing: false,
    error: false,
  };
  
  export default function (state: any = initialState, action: Function) {
    switch (action.type) {
      case FETCH_PRODUCT_SUCCESS_ACTION:
        return {
          ...state,
          ProductList: action.payload.rows,
          productDtlsCount: action.payload.count,
        };
        case FETCH_PRODUCTBYID_SUCCESS_ACTION:
          return {
              ...state,
              productDtls: action.payload,
              processing: false,
              error: false,
              loading: false
          };
   
          case FETCH_LICENSEEFILTER_SUCCESS_ACTION:
            return {
              ...state,
              LicenseeFilterList: action.payload.rows,
              // LicenseeFilterList: sortData(action.payload.rows),

            };
  
      default:
        return {
          ...state,
        };
    }
  }
  