import { put, takeEvery } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_PAYMENTMODE_INIT_ACTION,
  FETCH_PAYMENTMODE_SUCCESS_ACTION,
  FETCH_PAYMENTMODE_FAIL_ACTION,
  ADD_PAYMENTMODE_INIT_ACTION,
  ADD_PAYMENTMODE_SUCCESS_ACTION,
  ADD_PAYMENTMODE_FAIL_ACTION,
  EDIT_PAYMENTMODE_INIT_ACTION,
  EDIT_PAYMENTMODE_SUCCESS_ACTION,
  EDIT_PAYMENTMODE_FAIL_ACTION,
  DELETE_PAYMENTMODE_INIT_ACTION,
  DELETE_PAYMENTMODE_SUCCESS_ACTION,
  DELETE_PAYMENTMODE_FAIL_ACTION,

} from "./action";
import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var btoa = require("Base64").btoa;
var history = createBrowserHistory();

function* fetchPaymentMode(value) {
  let pageno = value.value === undefined ? 1 : value.value;

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/paymentMode?limit=10&page=` + pageno+'&isActive=true', {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
   
      yield put({
        type: FETCH_PAYMENTMODE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_PAYMENTMODE_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* addPaymentMode(value) {
 
  try {
    const token = localStorage.getItem("saleStraitToken");

    const data = {
     
      name: value.value.name,
      disc: value.value.disc,
     isActive: value.value.isActive,
    };

    const res = yield fetch(`${appConfig.ip}/paymentMode`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: ADD_PAYMENTMODE_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("PaymentMode added successfully", {
        autoClose: 3000,
      });
      yield put({
        type: FETCH_PAYMENTMODE_INIT_ACTION,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_PAYMENTMODE_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* editPaymentMode(value) {
  let id = value.value.id;
  let data = {
  
   
    name: value.value.name,
    disc: value.value.disc,
   isActive: value.value.isActive,
  };

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/paymentMode/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      // yield put({ type: FETCH_PaymentMode_INIT_ACTION });
      yield toast.success("PaymentMode updated successfully", {
        autoClose: 3000,
      });
      const resJSON = yield res.json();
      yield put({
        type: EDIT_PAYMENTMODE_SUCCESS_ACTION,
        payload:resJSON
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EDIT_PAYMENTMODE_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* deletePaymentMode(value) {

  let id = value.value.id;

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/paymentMode/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      
      yield put({
        type: DELETE_PAYMENTMODE_SUCCESS_ACTION,
        payload:id
      });
      yield toast.success("Deleted successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: DELETE_PAYMENTMODE_FAIL_ACTION, error: err });
    } else {
    }
  }
}



export function* PaymentModeActionWatcher() {
  yield takeEvery(FETCH_PAYMENTMODE_INIT_ACTION, fetchPaymentMode);
  yield takeEvery(ADD_PAYMENTMODE_INIT_ACTION, addPaymentMode);
  yield takeEvery(EDIT_PAYMENTMODE_INIT_ACTION, editPaymentMode);
  yield takeEvery(DELETE_PAYMENTMODE_INIT_ACTION, deletePaymentMode);

}
