import * as actionTypes from "./constants";

const initialState = {
  processing: false,
  error: false,
  expenseTypeList: [],
  expenseTypeCount: 0,
};

export default function expenseTypeReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_EXPENSE_TYPE_SUCCESS_ACTION:
      return {
        ...state,
        expenseTypeList: action.payload.rows,
        expenseTypeCount: action.payload.count,
        loading: false,
      };
    default:
      return {
        ...state,
      };
  }
}
