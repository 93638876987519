import { put, takeEvery } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_SUB_CATEGRY_INIT_ACTION,
  FETCH_SUB_CATEGRY_SUCCESS_ACTION,
  FETCH_SUB_CATEGRY_FAIL_ACTION,
  FETCH_CATEGORYS_INIT_ACTION,
  FETCH_CATEGORYS_SUCCESS_ACTION,
  FETCH_CATEGORYS_FAIL_ACTION,
  ADD_SUBCATGRY_INIT_ACTION,
  ADD_SUBCATGRY_SUCCESS_ACTION,
  ADD_SUBCATGRY_FAIL_ACTION,
  DELETE_SUB_CATGRY_INIT_ACTION,
  DELETE_SUB_CATGRY_FAIL_ACTION,
  DELETE_SUB_CATGRY_SUCCESS_ACTION,
  EDIT_SUBCATEGORY_INIT_ACTION,
  EDIT_SUBCATEGORY_SUCCESS_ACTION,
  SUBCATGRY_SEARCH_INIT_ACTION,
  SUBCATGRY_SEARCH_SUCCESS_ACTION,
  SUBCATGRY_SEARCH_FAIL_ACTION,
  SUBCATGRY_FILTER_INIT_ACTION,
  SUBCATGRY_FILTER_SUCCESS_ACTION,
  SUBCATGRY_FILTER_FAIL_ACTION,
} from "./action";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* fetchSubCatgry(value) {
  let pageno = (value.value && value.value.page) || 1;
  let searchVal =
    !value || !value.value || !value.value.searchQuery
      ? ""
      : value.value.searchQuery;
  let filter =
    !value || !value.value || !value.value.filter ? null : value.value.filter;
  let _url = `${appConfig.ip}/subCtgry?limit=10&search=${searchVal}&page=${pageno}&isActive=true`;

  if (filter) {
    _url = `${_url}&ctgryId=${filter}`;
  }

  try {
    const token = localStorage.getItem("saleStraitToken");
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_SUB_CATEGRY_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_SUB_CATEGRY_FAIL_ACTION,
        error: err,
      });
    }
  }
}
function* fetchCategorys() {
  try {
    const token = localStorage.getItem("saleStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/category?isActive=true
        `,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_CATEGORYS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_CATEGORYS_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* AddSubcategory(value) {
  try {
    const token = localStorage.getItem("saleStraitToken");
    const data = {
      name: value.value.name,
      desc: value.value.desc,
      code: value.value.code,
      ctgryId: value.value.ctgryId,
    };
    const Categry = yield fetch(`${appConfig.ip}/subCtgry`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!Categry.ok) {
    } else {
      const categryJSON = yield Categry.json();

      yield put({ type: FETCH_SUB_CATEGRY_INIT_ACTION, value: { page: 1 } });
      yield toast.success("Subcategory added successfully", {
        autoClose: 3000,
      });
      yield put({
        type: ADD_SUBCATGRY_SUCCESS_ACTION,
        payload: categryJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_SUBCATGRY_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* deleteSubCatgry(items) {
  let id = items.value.id;

  try {
    const token = localStorage.getItem("saleStraitToken");
    const res = yield fetch(`${appConfig.ip}/subCtgry/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      // yield put({
      //     type: FETCH_SUB_CATEGRY_INIT_ACTION
      // })
      yield toast.success("Deleted successfully", {
        autoClose: 3000,
      });
      //  const resJSON = yield res.json()

      yield put({
        type: DELETE_SUB_CATGRY_SUCCESS_ACTION,
        payload: id,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: DELETE_SUB_CATGRY_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* editSubCatgry(data) {
  try {
    const token = localStorage.getItem("saleStraitToken");
    const editValue = {
      name: data.data.name,
      code: data.data.code,
      desc: data.data.desc,
      ctgryId: data.data.ctgryId,
      // isActive: data.data.isActive,
    };

    const res = yield fetch(appConfig.ip + "/subCtgry/" + data.data.id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(editValue),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      // yield put({
      //     type: FETCH_SUB_CATEGRY_INIT_ACTION
      // })
      yield toast.success("Subcategory updated successfully", {
        autoClose: 3000,
      });
      const resJSON = yield res.json();

      yield put({
        type: EDIT_SUBCATEGORY_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {}
}

export function* SubCatgryActionWatcher() {
  yield takeEvery(FETCH_SUB_CATEGRY_INIT_ACTION, fetchSubCatgry);
  yield takeEvery(FETCH_CATEGORYS_INIT_ACTION, fetchCategorys);
  yield takeEvery(ADD_SUBCATGRY_INIT_ACTION, AddSubcategory);
  yield takeEvery(DELETE_SUB_CATGRY_INIT_ACTION, deleteSubCatgry);
  yield takeEvery(EDIT_SUBCATEGORY_INIT_ACTION, editSubCatgry);
}
