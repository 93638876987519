import {
 
  FETCH_SUPPORTTYPE_SUCCESS_ACTION,
  DELETE_SUPPORTTYPE_SUCCESS_ACTION,
  EDIT_SUPPORTTYPE_SUCCESS_ACTION
  // FETCH_SUPPORTTYPE_FAIL_ACTION
} from "./action";

const initialState = {
  processing: false,
  error: false
};

export default function SupportTypeReducer(state: any = initialState, action: Function)
 {
  switch (action.type) {
    case FETCH_SUPPORTTYPE_SUCCESS_ACTION:
      return {
        ...state,
        supportTypeList: action.payload.rows,
        supportTypeCount: action.payload.count
      };
      case EDIT_SUPPORTTYPE_SUCCESS_ACTION:
        return {
          ...state,
          supportTypeList: state.supportTypeList.map((support) =>
          support.id === action.payload.id ? action.payload : support
          ),
        };
      case DELETE_SUPPORTTYPE_SUCCESS_ACTION:
  
        return {
          ...state,
          supportTypeList: state.supportTypeList.filter(
            (item) => item.id !== action.payload
          ),
          supportTypeCount: state.supportTypeCount - 1
        };

    default:
      return {
        ...state
      };
  }
}


