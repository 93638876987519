import {
  FETCH_SUPPORTDETAILS_SUCCESS_ACTION,
  
  CHANGESTATUS_SUPPORT_SUCCESS_ACTION} 
  from "./action";

const initialState = {
  processing: false,
  error: false
};


export default function SupportReducer(state = initialState, action) {
  switch (action.type) {

   

    case FETCH_SUPPORTDETAILS_SUCCESS_ACTION:
      return {
        ...state,
        supportDtlsList: action.payload.rows,
        supportDtlsCount: action.payload.count
      };

      case CHANGESTATUS_SUPPORT_SUCCESS_ACTION:
        
        return {
          ...state,
          supportDtlsList: state.supportDtlsList.map((support) =>
          support.id === action.payload.id ? action.payload : support
          ),
        };
  
    default:
      return {
        ...state
      };
  }
}
