export const FETCH_EARNINGS_INIT_ACTION = "FETCH_EARNINGS_INIT_ACTION";
export const FETCH_EARNINGS_SUCCESS_ACTION =
  FETCH_EARNINGS_INIT_ACTION + "_SUCCESS";
export const FETCH_EARNINGS_FAIL_ACTION = FETCH_EARNINGS_INIT_ACTION + "_ERROR";

export const FETCH_EARNINGSBYID_INIT_ACTION = "FETCH_EARNINGSBYID_INIT_ACTION";
export const FETCH_EARNINGSBYID_SUCCESS_ACTION =
  FETCH_EARNINGSBYID_INIT_ACTION + "_SUCCESS";
export const FETCH_EARNINGSBYID_FAIL_ACTION =
  FETCH_EARNINGSBYID_INIT_ACTION + "_ERROR";

export const EXPORT_EARNINGS_INIT_ACTION = "EXPORT_EARNINGS_INIT_ACTION";
export const EXPORT_EARNINGS_SUCCESS_ACTION =
  EXPORT_EARNINGS_INIT_ACTION + "_SUCCESS";
export const EXPORT_EARNINGS_FAIL_ACTION =
  EXPORT_EARNINGS_INIT_ACTION + "_ERROR";

export function fetchEarnings(value) {
  return {
    type: FETCH_EARNINGS_INIT_ACTION,
    value: value,
  };
}

export function getEarningsById(value) {
  return {
    type: FETCH_EARNINGSBYID_INIT_ACTION,
    value: value,
  };
}

export function exportEarnings(value) {
  return {
    type: EXPORT_EARNINGS_INIT_ACTION,
    value: value,
  };
}
