import { put, takeEvery, call } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_USER_INIT_ACTION,
  FETCH_USER_SUCCESS_ACTION,
  FETCH_USER_FAIL_ACTION,
  FETCH_USERBYID_INIT_ACTION,
  FETCH_USERBYID_SUCCESS_ACTION,
  FETCH_USERBYID_FAIL_ACTION,
  FETCH_REGIONDROPDOWN_INIT_ACTION,
  FETCH_REGIONDROPDOWN_SUCCESS_ACTION,
  FETCH_REGIONDROPDOWN_FAIL_ACTION,
  FETCH_AREADROPDOWN_INIT_ACTION,
  FETCH_AREADROPDOWN_SUCCESS_ACTION,
  FETCH_AREADROPDOWN_FAIL_ACTION,
  FETCH_ZONEDROPDOWN_INIT_ACTION,
  FETCH_ZONEDROPDOWN_SUCCESS_ACTION,
  FETCH_ZONEDROPDOWN_FAIL_ACTION,
  FETCH_CHANNEL_PARTNER_IN_USER_INIT_ACTION,
  FETCH_CHANNEL_PARTNER_IN_USER_SUCCESS_ACTION,
  FETCH_CHANNEL_PARTNER_IN_USER_FAIL_ACTION,
} from "./action";
import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var btoa = require("Base64").btoa;
var history = createBrowserHistory();

function* fetchUser(value) {
  const role = localStorage.getItem("UserRole");
  const userid = localStorage.getItem("saleStraitUserId");
  const token = localStorage.getItem("saleStraitToken");

  let filter =
    !value || !value.value || !value.value.filter ? null : value.value.filter;

  // let licDataList, licIds;
  // if (role === "channelPartner") {
  //   if (filter) {
  //     licIds = filter;
  //   } else {
  //     licDataList = yield call(fetchlicenseeList);
  //     licIds = licDataList.map((x) => x.id);
  //   }
  // }

  let pageno = value.value.page === undefined ? 1 : value.value.page;

  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? ""
      : value.value.searchVal;

  let channelPartnerFilter =
    (value.value && value.value.channelPartnerFilter) || "";

  let getDataUrl = [
    {
      role: "admin",
      // url: "/licenseeProf?page=" + pageno + "&limit=10&isActive=true",
      url: `/users?limit=10&page=${pageno}&dummy=false&status=created,active,suspended&role=regionalManager,zonalManager,areaManager,salesExcecutive,licenseeAccountant,agent`,
    },
    {
      role: "channelPartner",
      url: `/users?limit=10&page=${pageno}&dummy=false&status=created,active,suspended&role=regionalManager,zonalManager,areaManager,salesExcecutive,licenseeAccountant&partner=${userid},agent`,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);

  let _url = `${appConfig.ip}${apiUrl.url}`;

  // let _url = `${appConfig.ip}/users?limit=10&search=${searchVal}${filterVal}&page=${pageno}&dummy= false&status=created,active,suspended&role=regionalManager,zonalManager,areaManager,salesExcecutive,licenseeAccountant`;

  if (searchVal) {
    _url = `${_url}&search=${searchVal}`;
  }
  if (filter) {
    _url = `${_url}&licenseeId=${filter}`;
  }
  if (channelPartnerFilter) {
    _url = `${_url}&partner=${channelPartnerFilter}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_USER_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_USER_FAIL_ACTION, error: err });
    } else {
    }
  }
}

//fetch licensee under channel partner

// function* fetchlicenseeList() {
//   const token = localStorage.getItem("saleStraitToken");
//   const userid = localStorage.getItem("saleStraitUserId");

//   try {
//     const res = yield fetch(
//       `${appConfig.ip}/licenseeProf?isActive=true&channelPartner=${userid}`,
//       {
//         method: "GET",
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     if (!res.ok) {
//       let errJSON = {};
//       try {
//         errJSON = yield res.json();
//       } catch {}
//       throw Object.assign(res, errJSON);
//     } else {
//       const resJSON = yield res.json();
//       return resJSON.rows;
//     }
//   } catch (err) {
//     if (err.ok === false) {
//     } else {
//     }
//   }
// }

function* getUserById(value) {
  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/users/` + value.value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_USERBYID_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_USERBYID_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchRegionDropdown() {
  const token = localStorage.getItem("sStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/region?&isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_REGIONDROPDOWN_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_REGIONDROPDOWN_FAIL_ACTION, error: err });
    } else {
    }
  }
}

// Get area list

function* fetchAreaDropdown(value) {
  let zone = !value || !value.value.id ? "" : value.value.id;
  const token = localStorage.getItem("saleStraitToken");

  try {
    const res = yield fetch(`${appConfig.ip}/area?zone=${zone}&isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_AREADROPDOWN_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_AREADROPDOWN_FAIL_ACTION, error: err });
    } else {
    }
  }
}

// Get zone list

function* fetchZoneDropdown(value) {
  let region = !value || !value.value.id ? "" : value.value.id;
  const token = localStorage.getItem("saleStraitToken");

  try {
    const res = yield fetch(
      `${appConfig.ip}/zone?&region=${region}&isActive=true`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_ZONEDROPDOWN_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_ZONEDROPDOWN_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchChannelParnterInUser() {
  try {
    const token = localStorage.getItem("saleStraitToken");

    const res = yield fetch(`${appConfig.ip}/users?role=channelPartner`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
    } else {
      const resJSON = yield res.json();
      yield put({
        type: FETCH_CHANNEL_PARTNER_IN_USER_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_CHANNEL_PARTNER_IN_USER_FAIL_ACTION,
        error: err,
      });
    }
  }
}

export function* UserActionWatcher() {
  yield takeEvery(FETCH_USER_INIT_ACTION, fetchUser);
  yield takeEvery(FETCH_USERBYID_INIT_ACTION, getUserById);
  yield takeEvery(FETCH_REGIONDROPDOWN_INIT_ACTION, fetchRegionDropdown);
  yield takeEvery(FETCH_AREADROPDOWN_INIT_ACTION, fetchAreaDropdown);
  yield takeEvery(FETCH_ZONEDROPDOWN_INIT_ACTION, fetchZoneDropdown);
  yield takeEvery(
    FETCH_CHANNEL_PARTNER_IN_USER_INIT_ACTION,
    fetchChannelParnterInUser
  );
}
