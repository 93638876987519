import { put, takeEvery, select, call, all } from "redux-saga/effects";
// import { createBrowserHistory } from "history";

import { appConfig } from "../../config";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import * as actionTypes from "./constants";

function* fetchAppConfigInTestReport() {
  const token = localStorage.getItem("saleStraitToken");

  try {
    const res = yield fetch(`${appConfig.ip}/appConfig`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.FETCH_APP_CONFIG_IN_TEST_REPORT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_APP_CONFIG_IN_TEST_REPORT_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

export function* testReportUrlActionWatcher() {
  yield takeEvery(
    actionTypes.FETCH_APP_CONFIG_IN_TEST_REPORT_INIT_ACTION,
    fetchAppConfigInTestReport
  );
}
