import {
  // FETCH_STATELIST_SUCCESS_ACTION,
  // FETCH_STATELIST_INIT_ACTION,
  FETCH_LEADS_SUCCESS_ACTION,
  FETCH_LEADS_INIT_ACTION,
  FETCH_LEADS_CLEAR_ACTION,
  FETCH_LEADS_DETAIL_INIT_ACTION,
  FETCH_LEADS_DETAIL_SUCCESS_ACTION,
  FETCH_CUSTOMERS_BY_SALESEXECUTIVE_SUCCESS_ACTION,
  FETCH_PRODUCTS_BY_SALESEXECUTIVE_SUCCESS_ACTION,
  FETCH_USER_DETAIL_SUCCESS_ACTION,
  // FETCH_LICENSEEFILTER_SUCCESS_ACTION,
} from "./action";

const initialState = {
  processing: true,
  error: false,
};

export default function LeadsReducer(
  state: any = initialState,
  action: Function
) {
  switch (action.type) {
    // case FETCH_STATELIST_INIT_ACTION:
    //   return {
    //     ...state,
    //     processing: true,
    //     error: false,
    //   };
    // case FETCH_LEADS_CLEAR_ACTION:
    //   return {
    //     ...state,
    //     leadsList: [],
    //     leadsDtlsCount: '',
    //   };
    case FETCH_CUSTOMERS_BY_SALESEXECUTIVE_SUCCESS_ACTION:
      return {
        ...state,
        customersList: action.payload.rows,
        customersDtlsCount: action.payload.count,
        processing: false,
        error: false,
      };
    case FETCH_PRODUCTS_BY_SALESEXECUTIVE_SUCCESS_ACTION:
      return {
        ...state,
        ProductList: action.payload.rows,
        productsDtlsCount: action.payload.count,
        processing: false,
        error: false,
      };
    case FETCH_LEADS_SUCCESS_ACTION:
      return {
        ...state,
        leadsList: action.payload.rows,
        leadsDtlsCount: action.payload.count,
        processing: false,
        error: false,
      };

    case FETCH_LEADS_DETAIL_INIT_ACTION:
      return {
        ...state,
        processing: true,
        loading: true,
        error: false,
        leads: "",
      };

    case FETCH_LEADS_DETAIL_SUCCESS_ACTION:
      return {
        ...state,
        processing: false,
        loading: false,
        error: false,
        leads: action.payload,
      };
    case FETCH_USER_DETAIL_SUCCESS_ACTION:
      return {
        ...state,
        processing: false,
        error: false,
        user: action.payload,
      };

    // case FETCH_LICENSEEFILTER_SUCCESS_ACTION:
    //   return {
    //     ...state,
    //     LicenseeFilterList: action.payload.rows,

    //   };

    default:
      return {
        ...state,
      };
  }
}
