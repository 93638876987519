import { put, takeEvery } from "redux-saga/effects";
// import { createBrowserHistory } from "history";
import {
  FETCH_SUPPORTDETAILS_INIT_ACTION,
  FETCH_SUPPORTDETAILS_SUCCESS_ACTION,
  FETCH_SUPPORTDETAILS_FAIL_ACTION,
  CHANGESTATUS_SUPPORT_INIT_ACTION,
  CHANGESTATUS_SUPPORT_SUCCESS_ACTION,
  CHANGESTATUS_SUPPORT_FAIL_ACTION,
} from "./action";

// import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// var btoa = require("Base64").btoa;
// var history = createBrowserHistory();

function* fetchSupport(value) {
  const token = localStorage.getItem("saleStraitToken");
  const role = localStorage.getItem("UserRole");
  const userid = localStorage.getItem("saleStraitUserId");

  let searchVal =
    !value || !value.value || !value.value.searchVal
      ? ""
      : value.value.searchVal;
  let pageno = value.value.page === undefined ? 1 : value.value.page;

  let filter = !value.value
    ? ""
    : value.value.filter === ""
    ? ""
    : value.value.filter;

  let getDataUrl = [
    {
      role: "admin",
      url: `/support?limit=10&page=${pageno}&isActive=true`,
    },
    {
      role: "techAdmin",
      url: `/support?limit=10&page=${pageno}&isActive=true`,
    },
    {
      role: "channelPartner",
      url: `/support?limit=10&page=${pageno}&isActive=true&channelPartner=${userid}`,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);

  let _url = `${appConfig.ip}${apiUrl.url}`;

  if (searchVal) {
    _url = `${_url}&search=${searchVal}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();
      yield put({
        type: FETCH_SUPPORTDETAILS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_SUPPORTDETAILS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* changeStatusSupport(value) {
  try {
    const token = localStorage.getItem("saleStraitToken");

    const supportValue = {
      status: value.value.status,
    };
    const res = yield fetch(`${appConfig.ip}/support/` + value.value.id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(supportValue),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: CHANGESTATUS_SUPPORT_INIT_ACTION,
      });
      yield put({
        type: CHANGESTATUS_SUPPORT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: CHANGESTATUS_SUPPORT_FAIL_ACTION, error: err });
    } else {
    }
  }
}

export function* SupportActionWatcher() {
  yield takeEvery(FETCH_SUPPORTDETAILS_INIT_ACTION, fetchSupport);
  yield takeEvery(CHANGESTATUS_SUPPORT_INIT_ACTION, changeStatusSupport);
}
