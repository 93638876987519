export const FETCH_LICENSEE_INIT_ACTION = "FETCH_LICENSEE_INIT_ACTION";
export const FETCH_LICENSEE_SUCCESS_ACTION =
  FETCH_LICENSEE_INIT_ACTION + "_SUCCESS";
export const FETCH_LICENSEE_FAIL_ACTION = FETCH_LICENSEE_INIT_ACTION + "_ERROR";

export const FETCH_COUNTRY_DROPDOWN_IN_LICENSE_INIT_ACTION =
  "FETCH_COUNTRY_DROPDOWN_IN_LICENSE_INIT_ACTION";
export const FETCH_COUNTRY_DROPDOWN_IN_LICENSE_SUCCESS_ACTION =
  FETCH_COUNTRY_DROPDOWN_IN_LICENSE_INIT_ACTION + "_SUCCESS";
export const FETCH_COUNTRY_DROPDOWN_IN_LICENSE__FAIL_ACTION =
  FETCH_COUNTRY_DROPDOWN_IN_LICENSE_INIT_ACTION + "_ERROR";

export const FETCH_STATE_DROPDOWN_IN_LICENSE_INIT_ACTION =
  "FETCH_STATE_DROPDOWN_IN_LICENSE_INIT_ACTION";
export const FETCH_STATE_DROPDOWN_IN_LICENSE_SUCCESS_ACTION =
  FETCH_STATE_DROPDOWN_IN_LICENSE_INIT_ACTION + "_SUCCESS";
export const FETCH_STATE_DROPDOWN_IN_LICENSE_FAIL_ACTION =
  FETCH_STATE_DROPDOWN_IN_LICENSE_INIT_ACTION + "_ERROR";

export const FETCH_DISTRICT_DROPDOWN_IN_LICENSE_INIT_ACTION =
  "FETCH_DISTRICT_DROPDOWN_IN_LICENSE_INIT_ACTION";
export const FETCH_DISTRICT_DROPDOWN_IN_LICENSE_SUCCESS_ACTION =
  FETCH_DISTRICT_DROPDOWN_IN_LICENSE_INIT_ACTION + "_SUCCESS";
export const FETCH_DISTRICT_DROPDOWN_IN_LICENSE_FAIL_ACTION =
  FETCH_DISTRICT_DROPDOWN_IN_LICENSE_INIT_ACTION + "_ERROR";

export const ADD_LICENSE_INIT_ACTION = "ADD_LICENSE_INIT_ACTION";
export const ADD_LICENSE_SUCCESS_ACTION = ADD_LICENSE_INIT_ACTION + "_SUCCESS";
export const ADD_LICENSE_FAIL_ACTION = ADD_LICENSE_INIT_ACTION + "_ERROR";

export const EDIT_LICENSE_INIT_ACTION = "EDIT_LICENSE_INIT_ACTION";
export const EDIT_LICENSE_SUCCESS_ACTION =
  EDIT_LICENSE_INIT_ACTION + "_SUCCESS";
export const EDIT_LICENSE_FAIL_ACTION = EDIT_LICENSE_INIT_ACTION + "_ERROR";

export const FETCH_LICENSESTATUS_INIT_ACTION =
  "FETCH_LICENSESTATUS_INIT_ACTION";
export const FETCH_LICENSESTATUS_SUCCESS_ACTION =
  FETCH_LICENSESTATUS_INIT_ACTION + "_SUCCESS";
export const FETCH_LICENSESTATUS_FAIL_ACTION =
  FETCH_LICENSESTATUS_INIT_ACTION + "_ERROR";

export const FETCH_LICENSEDETAIL_INIT_ACTION =
  "FETCH_LICENSEDETAIL_INIT_ACTION";
export const FETCH_LICENSEDETAIL_SUCCESS_ACTION =
  FETCH_LICENSEDETAIL_INIT_ACTION + "_SUCCESS";
export const FETCH_LICENSEDETAIL_FAIL_ACTION =
  FETCH_LICENSEDETAIL_INIT_ACTION + "_ERROR";

export const FETCH_BANKS_INIT_ACTION = "FETCH_BANKS_INIT_ACTION";
export const FETCH_BANKS_SUCCESS_ACTION = FETCH_BANKS_INIT_ACTION + "_SUCCESS";
export const FETCH_BANKS_FAIL_ACTION = FETCH_BANKS_INIT_ACTION + "_ERROR";

export const APPROVE_OPEN_LICENSEE_INIT_ACTION =
  "APPROVE_OPEN_LICENSEE_INIT_ACTION";
export const APPROVE_OPEN_LICENSEE_SUCCESS_ACTION =
  APPROVE_OPEN_LICENSEE_INIT_ACTION + "_SUCCESS";
export const APPROVE_OPEN_LICENSEE_FAIL_ACTION =
  APPROVE_OPEN_LICENSEE_INIT_ACTION + "_ERROR";

export const REJECT_OPEN_LICENSEE_INIT_ACTION =
  "REJECT_OPEN_LICENSEE_INIT_ACTION";
export const REJECT_OPEN_LICENSEE_SUCCESS_ACTION =
  REJECT_OPEN_LICENSEE_INIT_ACTION + "_SUCCESS";
export const REJECT_OPEN_LICENSEE_FAIL_ACTION =
  REJECT_OPEN_LICENSEE_INIT_ACTION + "_ERROR";

export const FETCH_CHANNEL_PARTNER_IN_LICENSEE_INIT_ACTION =
  "FETCH_CHANNEL_PARTNER_IN_LICENSEE_INIT_ACTION";
export const FETCH_CHANNEL_PARTNER_IN_LICENSEE_SUCCESS_ACTION =
  FETCH_CHANNEL_PARTNER_IN_LICENSEE_INIT_ACTION + "_SUCCESS";
export const FETCH_CHANNEL_PARTNER_IN_LICENSEE_FAIL_ACTION =
  FETCH_CHANNEL_PARTNER_IN_LICENSEE_INIT_ACTION + "_ERROR";

export function fetchLicensee(value) {
  return {
    type: FETCH_LICENSEE_INIT_ACTION,
    value: value,
  };
}
export function fetchLicenseDetailList(value) {
  return {
    type: FETCH_LICENSEDETAIL_INIT_ACTION,
    value: value,
  };
}

export function fetchLicenseeCountry(value) {
  return {
    type: FETCH_COUNTRY_DROPDOWN_IN_LICENSE_INIT_ACTION,
    value: value,
  };
}

export function fetchLicenseeState(value) {
  return {
    type: FETCH_STATE_DROPDOWN_IN_LICENSE_INIT_ACTION,
    value: value,
  };
}

export function fetchLicenseeDistrict(value) {
  return {
    type: FETCH_DISTRICT_DROPDOWN_IN_LICENSE_INIT_ACTION,
    value: value,
  };
}
export function addLicenseProfile(value) {
  return {
    type: ADD_LICENSE_INIT_ACTION,
    value: value,
  };
}

export function editLicense(value) {
  return {
    type: EDIT_LICENSE_INIT_ACTION,
    value: value,
  };
}

export function fetchLicenseStatus(value) {
  return {
    type: FETCH_LICENSESTATUS_INIT_ACTION,
    value: value,
  };
}

export function fetchBanks() {
  return {
    type: FETCH_BANKS_INIT_ACTION,
  };
}

export function approveOpenLicensee(params) {
  return {
    type: APPROVE_OPEN_LICENSEE_INIT_ACTION,
    params: params,
  };
}

export function rejectOpenLicensee(params) {
  return {
    type: REJECT_OPEN_LICENSEE_INIT_ACTION,
    params: params,
  };
}

export function fetchChannelParnterInLicensee() {
  return {
    type: FETCH_CHANNEL_PARTNER_IN_LICENSEE_INIT_ACTION,
  };
}

export function closeModalTriggerAction(value) {
  return {
    type: "SUCCESS_ACTION_MODAL",
    triggerValue: value,
  };
}
