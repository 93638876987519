export const FETCH_USER_INIT_ACTION = "FETCH_USER_INIT_ACTION";
export const FETCH_USER_SUCCESS_ACTION = FETCH_USER_INIT_ACTION + "_SUCCESS";
export const FETCH_USER_FAIL_ACTION = FETCH_USER_INIT_ACTION + "_ERROR";

export const FETCH_USERBYID_INIT_ACTION = "FETCH_USERBYID_INIT_ACTION";
export const FETCH_USERBYID_SUCCESS_ACTION =
  FETCH_USERBYID_INIT_ACTION + "_SUCCESS";
export const FETCH_USERBYID_FAIL_ACTION = FETCH_USERBYID_INIT_ACTION + "_ERROR";

export const FETCH_REGIONDROPDOWN_INIT_ACTION =
  "FETCH_REGIONDROPDOWN_INIT_ACTION";
export const FETCH_REGIONDROPDOWN_SUCCESS_ACTION =
  FETCH_REGIONDROPDOWN_INIT_ACTION + "_SUCCESS";
export const FETCH_REGIONDROPDOWN_FAIL_ACTION =
  FETCH_REGIONDROPDOWN_INIT_ACTION + "_ERROR";

export const FETCH_AREADROPDOWN_INIT_ACTION = "FETCH_AREADROPDOWN_INIT_ACTION";
export const FETCH_AREADROPDOWN_SUCCESS_ACTION =
  FETCH_AREADROPDOWN_INIT_ACTION + "_SUCCESS";
export const FETCH_AREADROPDOWN_FAIL_ACTION =
  FETCH_AREADROPDOWN_INIT_ACTION + "_ERROR";

export const FETCH_ZONEDROPDOWN_INIT_ACTION = "FETCH_ZONEDROPDOWN_INIT_ACTION";
export const FETCH_ZONEDROPDOWN_SUCCESS_ACTION =
  FETCH_ZONEDROPDOWN_INIT_ACTION + "_SUCCESS";
export const FETCH_ZONEDROPDOWN_FAIL_ACTION =
  FETCH_ZONEDROPDOWN_INIT_ACTION + "_ERROR";

export const FETCH_CHANNEL_PARTNER_IN_USER_INIT_ACTION =
  "FETCH_CHANNEL_PARTNER_IN_USER_INIT_ACTION";
export const FETCH_CHANNEL_PARTNER_IN_USER_SUCCESS_ACTION =
  FETCH_CHANNEL_PARTNER_IN_USER_INIT_ACTION + "_SUCCESS";
export const FETCH_CHANNEL_PARTNER_IN_USER_FAIL_ACTION =
  FETCH_CHANNEL_PARTNER_IN_USER_INIT_ACTION + "_ERROR";

export function fetchUser(value) {
  return {
    type: FETCH_USER_INIT_ACTION,
    value: value,
  };
}

export function getUserById(value) {
  return {
    type: FETCH_USERBYID_INIT_ACTION,
    value: value,
  };
}

export function fetchRegionDropdown() {
  return {
    type: FETCH_REGIONDROPDOWN_INIT_ACTION,
  };
}

export function fetchAreaDropdown(value) {
  return {
    type: FETCH_AREADROPDOWN_INIT_ACTION,
    value: value,
  };
}

export function fetchZoneDropdown(value) {
  return {
    type: FETCH_ZONEDROPDOWN_INIT_ACTION,
    value: value,
  };
}

export function fetchChannelParnterInUser() {
  return {
    type: FETCH_CHANNEL_PARTNER_IN_USER_INIT_ACTION,
  };
}
