import { put, takeEvery } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_CURRENCY_INIT_ACTION,
  FETCH_CURRENCY_SUCCESS_ACTION,
  FETCH_CURRENCY_FAIL_ACTION,
  ADD_CURRENCY_INIT_ACTION,
  ADD_CURRENCY_SUCCESS_ACTION,
  ADD_CURRENCY_FAIL_ACTION,
  EDIT_CURRENCY_INIT_ACTION,
  EDIT_CURRENCY_SUCCESS_ACTION,
  EDIT_CURRENCY_FAIL_ACTION,
  DELETE_CURRENCY_INIT_ACTION,
  DELETE_CURRENCY_SUCCESS_ACTION,
  DELETE_CURRENCY_FAIL_ACTION,

} from "./action";
import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var btoa = require("Base64").btoa;
var history = createBrowserHistory();

function* fetchCurrency(value) {

  let pageno = value.value.page === undefined ? 1 : value.value.page;
  let searchVal = !value || !value.value || !value.value.searchVal ? '' : value.value.searchVal

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/currency?limit=10&search=${searchVal}&page=${pageno}&isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
   
      yield put({
        type: FETCH_CURRENCY_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_CURRENCY_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* addcurrencies(value) {
 
  try {
    const token = localStorage.getItem("saleStraitToken");

    const data = {
     
      name: value.value.name,
      iso4217Code: value.value.iso4217Code,
      symbol: value.value.symbol,
      subunit: value.value.subunit,
      // isActive: value.value.isActive,
    };

    const res = yield fetch(`${appConfig.ip}/currency`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: ADD_CURRENCY_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Currency added successfully", {
        autoClose: 3000,
      });
      yield put({
        type: FETCH_CURRENCY_INIT_ACTION,value: { page: 1 }
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_CURRENCY_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* editcurrencies(value) {
  let id = value.value.id;
  let data = {
  
    name: value.value.name,
    iso4217Code: value.value.iso4217Code,
    symbol: value.value.symbol,
    subunit: value.value.subunit,
    // isActive: value.value.isActive
  };

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/currency/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      // yield put({ type: FETCH_CURRENCY_INIT_ACTION });
      yield toast.success("Currency updated successfully", {
        autoClose: 3000,
      });
      const resJSON = yield res.json();
      yield put({
        type: EDIT_CURRENCY_SUCCESS_ACTION,
        payload:resJSON
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EDIT_CURRENCY_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* deletecurrencies(value) {
 
  let id = value.value.id;

 

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/currency/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      // yield put({ type: FETCH_CURRENCY_INIT_ACTION });
      // const resJSON = yield res.json();
      yield put({
        type: DELETE_CURRENCY_SUCCESS_ACTION,
        payload:id
      });
      yield toast.success("Deleted successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: DELETE_CURRENCY_FAIL_ACTION, error: err });
    } else {
    }
  }
}



export function* currencyActionWatcher() {
  yield takeEvery(FETCH_CURRENCY_INIT_ACTION, fetchCurrency);
  yield takeEvery(ADD_CURRENCY_INIT_ACTION, addcurrencies);
  yield takeEvery(EDIT_CURRENCY_INIT_ACTION, editcurrencies);
  yield takeEvery(DELETE_CURRENCY_INIT_ACTION, deletecurrencies);

}
