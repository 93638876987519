import { put, takeEvery, select, call, all } from "redux-saga/effects";
// import { createBrowserHistory } from "history";

import { appConfig } from "../../config";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import * as actionTypes from "./constants";

//common function
function* commonFunction(value) {
  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${value.api}`, {
      method: `${value.method}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: value.body ? value.body : null,
    });
    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();
      yield put({ type: `${value.initAction}` });
      yield put({
        type: `${value.sucessAction}`,
        payload: resJSON,
      });
      yield toast.success(value.toastMsg, {
        autoClose: 3000,
      });
      return resJSON;
    }
  } catch (err) {
    if (err.status === 409) {
      yield toast.error("User already exist", {
        autoClose: 3000,
      });
    }

    yield put({ type: `${value.failAction}`, error: err });
  }
}

function* fetchChannelPartner(value) {
  let pageno = (value.value && value.value.page) || 1;
  let search = (value.value && value.value.search) || "";

  try {
    let params = {
      api: `${appConfig.ip}/users?role=channelPartner&limit=10&page=${pageno}&search=${search}`,
      method: "GET",
      sucessAction: actionTypes.FETCH_CHANNELPRTNR_SUCCESS_ACTION,
      failAction: actionTypes.FETCH_CHANNELPRTNR_FAIL_ACTION,
      body: null,
    };
    const res = yield call(commonFunction, params);
  } catch (e) {}
}

function* AddChannelPartnr(value) {
  const data = {
    name: value.value.name,
    lName: value.value.lName,
    email: value.value.email,
    mobileNo: value.value.mobileNo,
    password: value.value.password,
    fullName: value.value.name + " " + value.value.lName,
    role: "channelPartner",
    status: "active",
  };

  try {
    let params = {
      api: `${appConfig.ip}/users`,
      method: "POST",
      sucessAction: actionTypes.ADD_CHANNELPRTNR_SUCCESS_ACTION,
      failAction: actionTypes.ADD_CHANNELPRTNR_FAIL_ACTION,
      initAction: actionTypes.FETCH_CHANNELPRTNR_INIT_ACTION,
      toastMsg: "Successfully added",
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);
  } catch (e) {}
}

function* changeStatus(value) {
  let data;

  value.value.status === "created" || value.value.status === "suspended"
    ? (data = { status: "active" })
    : (data = { status: "suspended" });

  try {
    let params = {
      api: `${appConfig.ip}/users/${value.value.id}`,
      method: "PUT",
      sucessAction: actionTypes.CHANGESTATUS_CHNLPRTNR_SUCCESS_ACTION,
      failAction: actionTypes.CHANGESTATUS_CHNLPRTNR_FAIL_ACTION,
      initAction: null,
      toastMsg: "Status changed successfully",
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);
  } catch (e) {}
}

function* UpdateChannelPartnr(value) {
  const data = {
    name: value.value.name,
    lName: value.value.lName,
    fullName: value.value.name + " " + value.value.lName,
  };

  try {
    let params = {
      api: `${appConfig.ip}/users/${value.value.id}`,
      method: "PUT",
      sucessAction: actionTypes.UPDATE_CHNLPRTNR_SUCCESS_ACTION,
      failAction: actionTypes.UPDATE_CHNLPRTNR_FAIL_ACTION,
      initAction: null,
      toastMsg: "Updated successfully",
      body: JSON.stringify(data),
    };
    const res = yield call(commonFunction, params);
  } catch (e) {}
}

export function* ChannelPrtnrActionWatcher() {
  yield takeEvery(
    actionTypes.FETCH_CHANNELPRTNR_INIT_ACTION,
    fetchChannelPartner
  );
  yield takeEvery(actionTypes.ADD_CHANNELPRTNR_INIT_ACTION, AddChannelPartnr);
  yield takeEvery(actionTypes.CHANGESTATUS_CHNLPRTNR_INIT_ACTION, changeStatus);
  yield takeEvery(
    actionTypes.UPDATE_CHNLPRTNR_INIT_ACTION,
    UpdateChannelPartnr
  );
}
