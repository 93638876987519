import {

    FETCH_ORDERS_SUCCESS_ACTION,
    FETCH_ORDERBYID_SUCCESS_ACTION,
    FETCH_REGIONDROPDOWN_SUCCESS_ACTION,
    FETCH_AREADROPDOWN_SUCCESS_ACTION,
    FETCH_ZONEDROPDOWN_SUCCESS_ACTION

} from "./action";

const initialState = {
    processing: true,
    error: false,
};

export default function CustomersReducer(
    state: any = initialState,
    action: Function
) {
    switch (action.type) {

        case FETCH_ORDERS_SUCCESS_ACTION:
            return {
                ...state,
                orderList: action.payload.rows,
                orderDtlsCount: action.payload.count,
                processing: false,
                error: false,
            };
        case FETCH_ORDERBYID_SUCCESS_ACTION:
                return {
                    ...state,
                    orderDtls: action.payload,
                  
                };
                case FETCH_REGIONDROPDOWN_SUCCESS_ACTION:

                    return {
                        ...state,
                        regionDropdownList: action.payload.rows,
        
                    };
                case FETCH_AREADROPDOWN_SUCCESS_ACTION:
                    return {
                        ...state,
                        areaDropdownList: action.payload.rows,
                    };
                    case FETCH_ZONEDROPDOWN_SUCCESS_ACTION:
                      return {
                          ...state,
                          zoneDropdownList: action.payload.rows,
                      };

        default:
            return {
                ...state,
            };
    }
}
