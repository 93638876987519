export const FETCH_EXPENSE_TYPE_INIT_ACTION = "FETCH_EXPENSE_TYPE_INIT_ACTION";
export const FETCH_EXPENSE_TYPE_SUCCESS_ACTION =
  FETCH_EXPENSE_TYPE_INIT_ACTION + "_SUCCESS";
export const FETCH_EXPENSE_TYPE_FAIL_ACTION =
  FETCH_EXPENSE_TYPE_INIT_ACTION + "_ERROR";

export const ADD_EXPENSE_TYPE_INIT_ACTION = "ADD_EXPENSE_TYPE_INIT_ACTION";
export const ADD_EXPENSE_TYPE_SUCCESS_ACTION =
  ADD_EXPENSE_TYPE_INIT_ACTION + "_SUCCESS";
export const ADD_EXPENSE_TYPE_FAIL_ACTION =
  ADD_EXPENSE_TYPE_INIT_ACTION + "_ERROR";

export const DELETE_EXPENSE_TYPE_INIT_ACTION =
  "DELETE_EXPENSE_TYPE_INIT_ACTION";
export const DELETE_EXPENSE_TYPE_SUCCESS_ACTION =
  DELETE_EXPENSE_TYPE_INIT_ACTION + "_SUCCESS";
export const DELETE_EXPENSE_TYPE_FAIL_ACTION =
  DELETE_EXPENSE_TYPE_INIT_ACTION + "_ERROR";

export const EDIT_EXPENSE_TYPE_INIT_ACTION = "EDIT_EXPENSE_TYPE_INIT_ACTION";
export const EDIT_EXPENSE_TYPE_SUCCESS_ACTION =
  EDIT_EXPENSE_TYPE_INIT_ACTION + "_SUCCESS";
export const EDIT_EXPENSE_TYPE_FAIL_ACTION =
  EDIT_EXPENSE_TYPE_INIT_ACTION + "_ERROR";
