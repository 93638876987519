import { FETCH_APPCONFIG_SUCCESS_ACTION } from "./action";

const initialState = {
  processing: false,
  error: false,
};

export default function (state: any = initialState, action: Function) {
  switch (action.type) {
    case FETCH_APPCONFIG_SUCCESS_ACTION:
      return {
        ...state,
        appConfigDtls: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
