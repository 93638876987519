import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { connectRouter } from "connected-react-router";
import storage from "redux-persist/es/storage";

import CountryReducer from "../containers/CountryContainer/reducer";
import StateReducer from "../containers/StateContainer/reducer";
import DistrictReducer from "../containers/DistrictContainer/reducer";
import SupportTypeReducer from "../containers/SupportTypeContainer/reducer";
import TermsandConditionReducer from "../containers/TermsandConditionContainer/reducer";
import AppConfigReducer from "../containers/AppConfigContainer/reducer";
import DashboardReducer from "../containers/DefaultLayout/reducer";
import CurrencyReducer from "../containers/CurrencyContainer/reducer";
import UnitReducer from "../containers/UnitContainer/reducer";
import SubCatgryReducer from "../containers/SubcategoryContainer/reducer";
import CountryCrncyReducer from "../containers/CountryCrncyContainer/reducer";
import ProductTypeReducer from "../containers/ProductTypeContainer/reducer";
import CategoryReducer from "../containers/CategoryContainer/reducer";
import GSTReducer from "../containers/GSTContainer/reducer";
import LicenseeReducer from "../containers/LicenseeContainer/reducer";
import PincodeReducer from "../containers/PincodeContainer/reducer";
import BankReducer from "../containers/BankContainer/reducer";
import CustomerReducer from "../containers/CustomerContainer/reducer";
import UserReducer from "../containers/UserContainer/reducer";
import ProductReducer from "../containers/ProductsContainer/reducer";
import countryCurrencyReducer from "../containers/CountryCurrencyContainer/reducer";
import OrderReducer from "../containers/OrderContainer/reducer";
import LeadsReducer from "../containers/LeadsContainer/reducer";
import PaymentModeReducer from "../containers/PaymentModeContainer/reducer";
import paymentsReducer from "../containers/PaymentsContainer/reducer";
import earningsReducer from "../containers/EarningsContainer/reducer";

import WithdrawalLimitReducer from "../containers/WithdrawalLimitContainer/reducer";
import SupportReducer from "../containers/SupportContainer/reducer";
import LocalityyReducer from "../containers/LocContainer/reducer";
import channelPartnerReducer from "../containers/ChannelPartnerContainer/reducer";
import testReportUrlReducer from "../containers/TestReportUrlConatiner/reducer";
import apiDocUrlReducer from "../containers/ApiDocUrlContainer/reducer";
import expenseTypeReducer from "../containers/ExpenseTypeContainer/reducer";

import store, { history } from "../boot/configureStore";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),

    CountryReducer,
    StateReducer,
    DistrictReducer,
    CurrencyReducer,
    UnitReducer,
    SupportTypeReducer,
    SubCatgryReducer,
    ProductTypeReducer,
    CategoryReducer,
    CountryCrncyReducer,
    TermsandConditionReducer,
    GSTReducer,
    LicenseeReducer,
    PincodeReducer,
    DashboardReducer,
    BankReducer,
    CustomerReducer,
    UserReducer,
    ProductReducer,
    countryCurrencyReducer,
    OrderReducer,
    LeadsReducer,
    PaymentModeReducer,
    paymentsReducer,
    earningsReducer,
    WithdrawalLimitReducer,
    SupportReducer,
    LocalityyReducer,
    AppConfigReducer,
    channelPartnerReducer,
    testReportUrlReducer,
    apiDocUrlReducer,
    expenseTypeReducer,
  });

export default createRootReducer;
