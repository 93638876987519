// import logger from "redux-logger";



  export const CHANGESTATUS_SUPPORT_INIT_ACTION =
  "CHANGESTATUS_SUPPORT_INIT_ACTION";
export const CHANGESTATUS_SUPPORT_SUCCESS_ACTION =
  CHANGESTATUS_SUPPORT_INIT_ACTION + "_SUCCESS";
export const CHANGESTATUS_SUPPORT_FAIL_ACTION =
CHANGESTATUS_SUPPORT_INIT_ACTION + "_ERROR";



export const FETCH_SUPPORTDETAILS_INIT_ACTION =
  "FETCH_SUPPORTDETAILS_INIT_ACTION";
export const FETCH_SUPPORTDETAILS_SUCCESS_ACTION =
  FETCH_SUPPORTDETAILS_INIT_ACTION + "_SUCCESS";
export const FETCH_SUPPORTDETAILS_FAIL_ACTION =
  FETCH_SUPPORTDETAILS_INIT_ACTION + "_ERROR";


export function supportList(value) {
  return {
    type: FETCH_SUPPORTDETAILS_INIT_ACTION,
    value:value
  };
}

export function SupportSelectedPage(value) {
  return {
    type: FETCH_SUPPORTDETAILS_INIT_ACTION,
    value: value
  };
}



export function changeStatusSupport(value) {
  return {
    type: CHANGESTATUS_SUPPORT_INIT_ACTION,
    value: value
  };
}


