import { put, takeEvery } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_GST_INIT_ACTION,
  FETCH_GST_SUCCESS_ACTION,
  FETCH_GST_FAIL_ACTION,
  ADD_GST_INIT_ACTION,
  ADD_GST_SUCCESS_ACTION,
  ADD_GST_FAIL_ACTION,
  EDIT_GST_INIT_ACTION,
  EDIT_GST_SUCCESS_ACTION,
  EDIT_GST_FAIL_ACTION,
  DELETE_GST_INIT_ACTION,
  DELETE_GST_SUCCESS_ACTION,
  DELETE_GST_FAIL_ACTION,

} from "./action";
import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var btoa = require("Base64").btoa;
var history = createBrowserHistory();

function* fetchGst(value) {
  let pageno = value.value.page === undefined ? 1 : value.value.page;
  let searchVal = !value || !value.value || !value.value.searchVal ? '' : value.value.searchVal

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/gstSlab?limit=10&search=${searchVal}&page=${pageno}&isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
   
      yield put({
        type: FETCH_GST_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_GST_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* addGst(value) {
 
  try {
    const token = localStorage.getItem("saleStraitToken");

    const data = {
     
      name: value.value.name,
      prcntg: value.value.prcntg,
     isActive: value.value.isActive,
    };

    const res = yield fetch(`${appConfig.ip}/gstSlab`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: ADD_GST_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Gst added successfully", {
        autoClose: 3000,
      });
      yield put({
        type: FETCH_GST_INIT_ACTION,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_GST_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* editGst(value) {
  let id = value.value.id;
  let data = {
  
   
    name: value.value.name,
    prcntg: value.value.prcntg,
   isActive: value.value.isActive,
  };

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/gstSlab/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      // yield put({ type: FETCH_GST_INIT_ACTION });
      yield toast.success("Gst updated successfully", {
        autoClose: 3000,
      });
      const resJSON = yield res.json();
      yield put({
        type: EDIT_GST_SUCCESS_ACTION,
        payload:resJSON
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EDIT_GST_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* deleteGst(value) {

  let id = value.value.id;

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/gstSlab/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      
      yield put({
        type: DELETE_GST_SUCCESS_ACTION,
        payload:id
      });
      yield toast.success("Deleted successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: DELETE_GST_FAIL_ACTION, error: err });
    } else {
    }
  }
}



export function* GSTActionWatcher() {
  yield takeEvery(FETCH_GST_INIT_ACTION, fetchGst);
  yield takeEvery(ADD_GST_INIT_ACTION, addGst);
  yield takeEvery(EDIT_GST_INIT_ACTION, editGst);
  yield takeEvery(DELETE_GST_INIT_ACTION, deleteGst);

}
