import { put, takeEvery } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_LOCALITYY_INIT_ACTION,
  FETCH_LOCALITYY_SUCCESS_ACTION,
  FETCH_LOCALITYY_FAIL_ACTION,
  ADD_LOCALITYY_INIT_ACTION,
  ADD_LOCALITYY_SUCCESS_ACTION,
  ADD_LOCALITYY_FAIL_ACTION,
  EDIT_LOCALITYY_INIT_ACTION,
  EDIT_LOCALITYY_SUCCESS_ACTION,
  EDIT_LOCALITYY_FAIL_ACTION,
  DELETE_LOCALITYY_INIT_ACTION,
  DELETE_LOCALITYY_SUCCESS_ACTION,
  DELETE_LOCALITYY_FAIL_ACTION,
  FETCH_LOCALITYY_DISTRICT_INIT_ACTION,
  FETCH_LOCALITYY_DISTRICT_SUCCESS_ACTION,
  FETCH_LOCALITYY_DISTRICT_FAIL_ACTION,
  FETCH_STATES_INIT_ACTION,
  FETCH_STATES_SUCCESS_ACTION,
  FETCH_STATES_FAIL_ACTION,
  FETCH_COUNTRY_DRODOWN_INIT_ACTION,
  FETCH_COUNTRY_DRODOWN_SUCCESS_ACTION,
  FETCH_COUNTRY_DRODOWN_FAIL_ACTION,
} from "./action";
import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var btoa = require("Base64").btoa;
var history = createBrowserHistory();

function* fetchLocalityy(value) {
  let pageno = (value.value && value.value.page) || 1;
  let searchVal =
    !value || !value.value || !value.value.searchQuery
      ? ""
      : value.value.searchQuery;

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(
      `${appConfig.ip}/locality?limit=10&isActive=true&page=${pageno}&search=${searchVal}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_LOCALITYY_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_LOCALITYY_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* addLocalityy(value) {
  try {
    const token = localStorage.getItem("saleStraitToken");

    const data = {
      name: value.value.name,
      type: value.value.type,
      district: value.value.district,
      state: value.value.state,
      country: value.value.country,
    };

    const res = yield fetch(`${appConfig.ip}/locality`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: ADD_LOCALITYY_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Localityy added successfully", {
        autoClose: 3000,
      });
      yield put({
        type: FETCH_LOCALITYY_INIT_ACTION,
        value: { page: 1 },
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_LOCALITYY_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* editLocalityy(value) {
  let id = value.value.id;
  let data = {
    name: value.value.name,
    type: value.value.type,
    district: value.value.district,
    state: value.value.state,
    country: value.value.country,
  };

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/locality/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield put({
        type: FETCH_LOCALITYY_INIT_ACTION,
      });
      yield toast.success("Localityy updated successfully", {
        autoClose: 3000,
      });
      const resJSON = yield res.json();
      // yield put({
      //   type: EDIT_LOCALITYY_SUCCESS_ACTION,
      //   payload: resJSON
      // });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EDIT_LOCALITYY_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* deleteLocalityy(value) {
  let id = value.value.id;

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/locality/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield put({
        type: FETCH_LOCALITYY_INIT_ACTION,
      });
      // yield put({
      //   type: DELETE_LOCALITYY_SUCCESS_ACTION,
      //   payload: id
      // });
      yield toast.success("Deleted successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: DELETE_LOCALITYY_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchLocalityyDistrict(value) {
  // let id = value.value.stateId;
  let stateId = (value.value && value.value.stateId) || null;

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(
      `${appConfig.ip}/districts?isActive=true&stateId=${stateId}`,

      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_LOCALITYY_DISTRICT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_LOCALITYY_DISTRICT_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchStates(value) {
  let countryId = (value.value && value.value.countryId) || null;

  try {
    const token = localStorage.getItem("saleStraitToken");
    const res = yield fetch(
      `${appConfig.ip}/state?isActive=true&countryId=${countryId}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_STATES_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_STATES_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* fetchCountry(value) {
  try {
    const token = localStorage.getItem("saleStraitToken");
    const res = yield fetch(`${appConfig.ip}/country?isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_COUNTRY_DRODOWN_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_COUNTRY_DRODOWN_FAIL_ACTION,
        error: err,
      });
    }
  }
}

export function* LocalityyActionWatcher() {
  yield takeEvery(FETCH_LOCALITYY_INIT_ACTION, fetchLocalityy);
  yield takeEvery(ADD_LOCALITYY_INIT_ACTION, addLocalityy);
  yield takeEvery(EDIT_LOCALITYY_INIT_ACTION, editLocalityy);
  yield takeEvery(DELETE_LOCALITYY_INIT_ACTION, deleteLocalityy);
  yield takeEvery(FETCH_LOCALITYY_DISTRICT_INIT_ACTION, fetchLocalityyDistrict);
  yield takeEvery(FETCH_STATES_INIT_ACTION, fetchStates);
  yield takeEvery(FETCH_COUNTRY_DRODOWN_INIT_ACTION, fetchCountry);
}
