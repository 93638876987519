import {
    FETCH_SUB_CATEGRY_SUCCESS_ACTION,
    FETCH_CATEGORYS_SUCCESS_ACTION,
    DELETE_SUB_CATGRY_SUCCESS_ACTION,
    EDIT_SUBCATEGORY_SUCCESS_ACTION
} from './action'

const initialState = {
    processing: false,
    error: false,
}

export default function (state: any = initialState, action: Function) {

    switch (action.type) {
        case FETCH_SUB_CATEGRY_SUCCESS_ACTION:

            return {
                ...state,
                SubCatgryList: action.payload.rows,
                SubCatgryCount: action.payload.count
            }
        case FETCH_CATEGORYS_SUCCESS_ACTION:
            return {
                ...state,
                parentCategry: action.payload.rows
            }
            case EDIT_SUBCATEGORY_SUCCESS_ACTION:
                return {
                  ...state,
                  SubCatgryList: state.SubCatgryList.map((subctgry) =>
                  subctgry.id === action.payload.id ? action.payload : subctgry
                  ),
                };
        case DELETE_SUB_CATGRY_SUCCESS_ACTION:

            return {
                ...state,
                SubCatgryList: state.SubCatgryList.filter(
                    (item) => item.id !== action.payload
                ),
                SubCatgryCount: state.SubCatgryCount - 1
            };



        default:
            return {
                ...state
            }
    }

}