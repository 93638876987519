export const FETCH_PRODUCTTYPE_INIT_ACTION = "FETCH_PRODUCTTYPE_INIT_ACTION";
export const FETCH_PRODUCTTYPE_SUCCESS_ACTION =
  FETCH_PRODUCTTYPE_INIT_ACTION + "_SUCCESS";
export const FETCH_PRODUCTTYPE_FAIL_ACTION = FETCH_PRODUCTTYPE_INIT_ACTION + "_ERROR";

export const ADD_PRODUCTTYPE_INIT_ACTION = "ADD_PRODUCTTYPE_INIT_ACTION";
export const ADD_PRODUCTTYPE_SUCCESS_ACTION =
  ADD_PRODUCTTYPE_INIT_ACTION + "_SUCCESS";
export const ADD_PRODUCTTYPE_FAIL_ACTION = ADD_PRODUCTTYPE_INIT_ACTION + "_ERROR";

export const EDIT_PRODUCTTYPE_INIT_ACTION = "EDIT_PRODUCTTYPE_INIT_ACTION";
export const EDIT_PRODUCTTYPE_SUCCESS_ACTION =
  EDIT_PRODUCTTYPE_INIT_ACTION + "_SUCCESS";
export const EDIT_PRODUCTTYPE_FAIL_ACTION = EDIT_PRODUCTTYPE_INIT_ACTION + "_ERROR";

export const DELETE_PRODUCTTYPE_INIT_ACTION = "DELETE_PRODUCTTYPE_INIT_ACTION";
export const DELETE_PRODUCTTYPE_SUCCESS_ACTION =
  DELETE_PRODUCTTYPE_INIT_ACTION + "_SUCCESS";
export const DELETE_PRODUCTTYPE_FAIL_ACTION =
  DELETE_PRODUCTTYPE_INIT_ACTION + "_ERROR";


export function fetchProductType(value) {
  return {
    type: FETCH_PRODUCTTYPE_INIT_ACTION,
    value: value,

  };
}

export function AddProductType(value) {
  return {
    type: ADD_PRODUCTTYPE_INIT_ACTION,
    value: value,
  };
}

export function EditProductType(value) {
  return {
    type: EDIT_PRODUCTTYPE_INIT_ACTION,
    value: value,
  };
}

export function DeleteValue(value) {
  return {
    type: DELETE_PRODUCTTYPE_INIT_ACTION,
    value: value,
  };
}





