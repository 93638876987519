export const FETCH_COUNTRIES_DROPDOWN_INIT_ACTION =
  " FETCH_COUNTRIES_DROPDOWN_INIT_ACTION";
export const FETCH_COUNTRIES_DROPDOWN_SUCCESS_ACTION =
  FETCH_COUNTRIES_DROPDOWN_INIT_ACTION + "_SUCCESS";
export const FETCH_COUNTRIES_DROPDOWN_FAIL_ACTION =
  FETCH_COUNTRIES_DROPDOWN_INIT_ACTION + "_ERROR";

export const FETCH_CURRENCIES_DROPDOWN_INIT_ACTION =
  " FETCH_CURRENCIES_DROPDOWN_INIT_ACTION";
export const FETCH_CURRENCIES_DROPDOWN_SUCCESS_ACTION =
  FETCH_CURRENCIES_DROPDOWN_INIT_ACTION + "_SUCCESS";
export const FETCH_CURRENCIES_DROPDOWN_FAIL_ACTION =
  FETCH_CURRENCIES_DROPDOWN_INIT_ACTION + "_ERROR";

export const FETCH_COUNTRY_CURRENCY_INIT_ACTION =
  "FETCH_COUNTRY_CURRENCY_INIT_ACTION";
export const FETCH_COUNTRY_CURRENCY_SUCCESS_ACTION =
  FETCH_COUNTRY_CURRENCY_INIT_ACTION + "_SUCCESS";
export const FETCH_COUNTRY_CURRENCY_FAIL_ACTION =
  FETCH_COUNTRY_CURRENCY_INIT_ACTION + "_ERROR";

export const ADD_COUNTRY_CURRENCY_INIT_ACTION =
  "ADD_COUNTRY_CURRENCY_INIT_ACTION";
export const ADD_COUNTRY_CURRENCY_SUCCESS_ACTION =
  ADD_COUNTRY_CURRENCY_INIT_ACTION + "_SUCCESS";
export const ADD_COUNTRY_CURRENCY_FAIL_ACTION =
  ADD_COUNTRY_CURRENCY_INIT_ACTION + "_ERROR";

export const DELETE_COUNTRY_CURRENCY_INIT_ACTION =
  "DELETE_COUNTRY_CURRENCY_INIT_ACTION";
export const DELETE_COUNTRY_CURRENCY_SUCCESS_ACTION =
  DELETE_COUNTRY_CURRENCY_INIT_ACTION + "_SUCCESS";
export const DELETE_COUNTRY_CURRENCY_FAIL_ACTION =
  DELETE_COUNTRY_CURRENCY_INIT_ACTION + "_ERROR";

export const EDIT_COUNTRY_CURRENCY_INIT_ACTION =
  "EDIT_COUNTRY_CURRENCY_INIT_ACTION";
export const EDIT_COUNTRY_CURRENCY_SUCCESS_ACTION =
  EDIT_COUNTRY_CURRENCY_INIT_ACTION + "_SUCCESS";
export const EDIT_COUNTRY_CURRENCY_FAIL_ACTION =
  EDIT_COUNTRY_CURRENCY_INIT_ACTION + "_ERROR";
