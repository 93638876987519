import {
  FETCH_LICENSEE_SUCCESS_ACTION,
  FETCH_COUNTRY_DROPDOWN_IN_LICENSE_SUCCESS_ACTION,
  FETCH_DISTRICT_DROPDOWN_IN_LICENSE_SUCCESS_ACTION,
  FETCH_STATE_DROPDOWN_IN_LICENSE_SUCCESS_ACTION,
  FETCH_LICENSESTATUS_SUCCESS_ACTION,
  FETCH_LICENSEDETAIL_SUCCESS_ACTION,
  FETCH_BANKS_SUCCESS_ACTION,
  FETCH_LICENSEDETAIL_INIT_ACTION,
  EDIT_LICENSE_SUCCESS_ACTION,
  FETCH_CHANNEL_PARTNER_IN_LICENSEE_SUCCESS_ACTION,
} from "./action";

const initialState = {
  processing: true,
  error: false,
  channelPartnerList: [],
  closeModalTrigger: false,
};

export default function LicenseeReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_LICENSEE_SUCCESS_ACTION:
      return {
        ...state,
        licenseeList: action.payload.rows,
        licenseeDtlsCount: action.payload.count,
        processing: false,
        error: false,
      };
    case FETCH_COUNTRY_DROPDOWN_IN_LICENSE_SUCCESS_ACTION:
      return {
        ...state,
        cntrycrncyList: action.payload.rows,
      };

    case FETCH_STATE_DROPDOWN_IN_LICENSE_SUCCESS_ACTION:
      return {
        ...state,
        statesList: action.payload.rows,
      };

    case FETCH_DISTRICT_DROPDOWN_IN_LICENSE_SUCCESS_ACTION:
      return {
        ...state,
        districtList: action.payload.rows,
      };
    case FETCH_CHANNEL_PARTNER_IN_LICENSEE_SUCCESS_ACTION:
      return {
        ...state,
        channelPartnerList: action.payload.rows,
      };

    case FETCH_LICENSEDETAIL_INIT_ACTION:
      return {
        ...state,
        loading: true,
      };

    case FETCH_LICENSEDETAIL_SUCCESS_ACTION:
      return {
        ...state,
        detailViewList: action.payload,
      };
    case EDIT_LICENSE_SUCCESS_ACTION:
      return {
        ...state,
        licenseeList: state.licenseeList.map((licensee) =>
          licensee.id === action.payload.id ? action.payload : licensee
        ),
      };

    // case FETCH_LICENSESTATUS_SUCCESS_ACTION:

    //   return {
    //     ...state,
    //     licenseeList: state.licenseeList.map((licensee) =>
    //       licensee.id === action.payload.id
    //         ? { ...licensee, status: action.payload.status }
    //         : licensee
    //     ),
    //   };
    case FETCH_LICENSESTATUS_SUCCESS_ACTION:
      return {
        ...state,
        licenseeList: state.licenseeList.map((item) =>
          item.id === action.payload.licenseeId
            ? { ...item, userId: action.payload }
            : item
        ),
      };

    case FETCH_BANKS_SUCCESS_ACTION:
      return {
        ...state,
        banks: action.payload,
      };
    case "SUCCESS_ACTION_MODAL":
      return {
        ...state,
        closeModalTrigger: !state.closeModalTrigger,
      };

    default:
      return {
        ...state,
      };
  }
}
