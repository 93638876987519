import { put, takeEvery } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_PRODUCT_INIT_ACTION,
  FETCH_PRODUCT_SUCCESS_ACTION,
  FETCH_PRODUCT_FAIL_ACTION,
  FETCH_PRODUCTBYID_INIT_ACTION,
  FETCH_PRODUCTBYID_SUCCESS_ACTION,
  FETCH_PRODUCTBYID_FAIL_ACTION,
  FETCH_LICENSEEFILTER_INIT_ACTION,
  FETCH_LICENSEEFILTER_SUCCESS_ACTION,
  FETCH_LICENSEEFILTER_FAIL_ACTION,
} from "./action";
import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var btoa = require("Base64").btoa;
var history = createBrowserHistory();

function* fetchProduct(value) {
  let pageno = (value.value && value.value.page) || 1;
  const role = localStorage.getItem("UserRole");
  const userid = localStorage.getItem("saleStraitUserId");

  let searchVal =
    !value || !value.value || !value.value.searchQuery
      ? ""
      : value.value.searchQuery;
  let filter =
    !value || !value.value || !value.value.filter ? null : value.value.filter;

  let getDataUrl = [
    {
      role: "admin",
      url: `/product?limit=10&page=${pageno}&isActive=true&status=active`,
    },
    {
      role: "channelPartner",
      url: `/product?limit=10&page=${pageno}&isActive=true&status=active&channelPartner=${userid}`,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);

  let _url = `${appConfig.ip}${apiUrl.url}`;

  // let _url = `${appConfig.ip}/product?limit=10&search=${searchVal}&page=${pageno}&isActive=true&status=active`;

  if (filter) {
    _url = `${_url}&licenseeId=${filter}`;
  }
  if (searchVal) {
    _url = `${_url}&search=${searchVal}`;
  }

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_PRODUCT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_PRODUCT_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* getProductById(value) {
  const token = localStorage.getItem("saleStraitToken");

  try {
    const res = yield fetch(`${appConfig.ip}/product/` + value.value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_PRODUCTBYID_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_PRODUCTBYID_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchLicenseeFilter(value) {
  let search = value.value || "";
  const token = localStorage.getItem("saleStraitToken");
  const role = localStorage.getItem("UserRole");
  const userid = localStorage.getItem("saleStraitUserId");

  let getDataUrl = [
    {
      role: "admin",
      url: `/licenseeProf?isActive=true&status=active&limit=50`,
    },
    {
      role: "channelPartner",
      url: `/licenseeProf?isActive=true&status=active&limit=50&channelPartner=${userid}`,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);

  let _url = `${appConfig.ip}${apiUrl.url}`;

  if (search) {
    _url = `${_url}&search=${search}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_LICENSEEFILTER_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_LICENSEEFILTER_FAIL_ACTION,
        error: err,
      });
    }
  }
}

export function* ProductActionWatcher() {
  yield takeEvery(FETCH_PRODUCT_INIT_ACTION, fetchProduct);
  yield takeEvery(FETCH_PRODUCTBYID_INIT_ACTION, getProductById);
  yield takeEvery(FETCH_LICENSEEFILTER_INIT_ACTION, fetchLicenseeFilter);
}
