

export const FETCH_SUB_COUNTRY_INIT_ACTION = "FETCH_SUB_COUNTRY_INIT_ACTION";
export const FETCH_SUB_COUNTRY_SUCCESS_ACTION =
  FETCH_SUB_COUNTRY_INIT_ACTION + "_SUCCESS";
export const FETCH_SUB_COUNTRY_FAIL_ACTION =
  FETCH_SUB_COUNTRY_INIT_ACTION + "_ERROR";

export const FETCH_CURRENCY1_INIT_ACTION = "FETCH_CURRENCY1_INIT_ACTION";
export const FETCH_CURRENCY1_SUCCESS_ACTION =
FETCH_CURRENCY1_INIT_ACTION + "_SUCCESS";
export const FETCH_CURRENCY1_FAIL_ACTION = FETCH_CURRENCY1_INIT_ACTION + "_ERROR";


export const ADD_CNTRY_CRNCY_INIT_ACTION = "ADD_CNTRY_CRNCY_INIT_ACTION";
export const ADD_CNTRY_CRNCY_SUCCESS_ACTION =
  ADD_CNTRY_CRNCY_INIT_ACTION + "_SUCCESS";
export const ADD_CNTRY_CRNCY__FAIL_ACTION =
  ADD_CNTRY_CRNCY_INIT_ACTION + "_ERROR";

export const FETCH_CNTRY_CRNCY_INIT_ACTION = "FETCH_CNTRY_CRNCY_INIT_ACTION";
export const FETCH_CNTRY_CRNCY_SUCCESS_ACTION =
  FETCH_CNTRY_CRNCY_INIT_ACTION + "_SUCCESS";
export const FETCH_CNTRY_CRNCY__FAIL_ACTION =
  FETCH_CNTRY_CRNCY_INIT_ACTION + "_ERROR";

export const DELETE_CNTRY_CRNCY_INIT_ACTION = "DELETE_CNTRY_CRNCY_INIT_ACTION";
export const DELETE_CNTRY_CRNCY_SUCCESS_ACTION =
  DELETE_CNTRY_CRNCY_INIT_ACTION + "_SUCCESS";
export const DELETE_CNTRY_CRNCY_FAIL_CTION =
  DELETE_CNTRY_CRNCY_INIT_ACTION + "_ERROR";

export const EDIT_CNTRY_CRNCY_INIT_ACTION = "EDIT_CNTRY_CRNCY_INIT_ACTION";
export const EDIT_CNTRY_CRNCY_SUCCESS_ACTION =
  EDIT_CNTRY_CRNCY_INIT_ACTION + "_SUCCESS";
export const EDIT_CNTRY_CRNCY_FAIL_ACTION =
  EDIT_CNTRY_CRNCY_INIT_ACTION + "_ERROR";


export function fetchCurrencyList() {
  return {
    type: FETCH_CURRENCY1_INIT_ACTION,
  };
}

export function AddCntryCrncy(value) {
  return {
    type: ADD_CNTRY_CRNCY_INIT_ACTION,
    value: value,
  };
}

export function fetchCntryCrnvy(value) {
  return {
    type: FETCH_CNTRY_CRNCY_INIT_ACTION,
    value:value

  };
}

export function DeleteValue(value) {

  return {
    type: DELETE_CNTRY_CRNCY_INIT_ACTION,
    value:value
  };
}


export function EditCntryCurency(value) {

  return {
    type: EDIT_CNTRY_CRNCY_INIT_ACTION,
    value: value,
  };
}
