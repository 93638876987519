export const FETCH_PAYMENTMODE_INIT_ACTION = "FETCH_PAYMENTMODE_INIT_ACTION";
export const FETCH_PAYMENTMODE_SUCCESS_ACTION =
  FETCH_PAYMENTMODE_INIT_ACTION + "_SUCCESS";
export const FETCH_PAYMENTMODE_FAIL_ACTION = FETCH_PAYMENTMODE_INIT_ACTION + "_ERROR";

export const ADD_PAYMENTMODE_INIT_ACTION = "ADD_PAYMENTMODE_INIT_ACTION";
export const ADD_PAYMENTMODE_SUCCESS_ACTION =
  ADD_PAYMENTMODE_INIT_ACTION + "_SUCCESS";
export const ADD_PAYMENTMODE_FAIL_ACTION = ADD_PAYMENTMODE_INIT_ACTION + "_ERROR";

export const EDIT_PAYMENTMODE_INIT_ACTION = "EDIT_PAYMENTMODE_INIT_ACTION";
export const EDIT_PAYMENTMODE_SUCCESS_ACTION =
  EDIT_PAYMENTMODE_INIT_ACTION + "_SUCCESS";
export const EDIT_PAYMENTMODE_FAIL_ACTION = EDIT_PAYMENTMODE_INIT_ACTION + "_ERROR";

export const DELETE_PAYMENTMODE_INIT_ACTION = "DELETE_PAYMENTMODE_INIT_ACTION";
export const DELETE_PAYMENTMODE_SUCCESS_ACTION =
  DELETE_PAYMENTMODE_INIT_ACTION + "_SUCCESS";
export const DELETE_PAYMENTMODE_FAIL_ACTION =
  DELETE_PAYMENTMODE_INIT_ACTION + "_ERROR";


export function fetchPaymentMode() {
  return {
    type: FETCH_PAYMENTMODE_INIT_ACTION,
  };
}

export function AddPaymentMode(value) {
  return {
    type: ADD_PAYMENTMODE_INIT_ACTION,
    value: value,
  };
}

export function EditPaymentMode(value) {
  return {
    type: EDIT_PAYMENTMODE_INIT_ACTION,
    value: value,
  };
}

export function DeleteValue(value) {
  return {
    type: DELETE_PAYMENTMODE_INIT_ACTION,
    value: value,
  };
}

export function paymentModeSlectedPage(value) {
  return {
    type: FETCH_PAYMENTMODE_INIT_ACTION,
    value: value,
  };
}



