export const FETCH_SUB_CATEGRY_INIT_ACTION = "FETCH_SUB_CATEGRY_INIT_ACTION"
export const FETCH_SUB_CATEGRY_SUCCESS_ACTION = FETCH_SUB_CATEGRY_INIT_ACTION + "-SUCCESS"
export const FETCH_SUB_CATEGRY_FAIL_ACTION = FETCH_SUB_CATEGRY_INIT_ACTION + "_ERROR"

export const FETCH_CATEGORYS_INIT_ACTION = "FETCH_CATEGORYS_INIT_ACTION";
export const FETCH_CATEGORYS_SUCCESS_ACTION = FETCH_CATEGORYS_INIT_ACTION + "-SUCCESS"
export const FETCH_CATEGORYS_FAIL_ACTION = FETCH_CATEGORYS_INIT_ACTION + "_ERROR"

export const ADD_SUBCATGRY_INIT_ACTION = "ADD_SUBCATGRY_INIT_ACTION";
export const ADD_SUBCATGRY_SUCCESS_ACTION = ADD_SUBCATGRY_INIT_ACTION + "_SUCCESS"
export const ADD_SUBCATGRY_FAIL_ACTION = ADD_SUBCATGRY_INIT_ACTION + "_ERROR"

export const DELETE_SUB_CATGRY_INIT_ACTION = "DELETE_SUB_CATGRY_INIT_ACTION";
export const DELETE_SUB_CATGRY_SUCCESS_ACTION = DELETE_SUB_CATGRY_INIT_ACTION + "_SUCCESS";
export const DELETE_SUB_CATGRY_FAIL_ACTION = DELETE_SUB_CATGRY_INIT_ACTION + "_ERROR";

export const EDIT_SUBCATEGORY_INIT_ACTION = "EDIT_SUBCATEGORY_INIT_ACTION";
export const EDIT_SUBCATEGORY_SUCCESS_ACTION = EDIT_SUBCATEGORY_INIT_ACTION + "_SUCCESS"
export const EDIT_SUBCATEGORY_FAIL_ACTION = EDIT_SUBCATEGORY_INIT_ACTION + "_ERROR";





export function fetchSubCatgry(value) {

    return {
        type: FETCH_SUB_CATEGRY_INIT_ACTION,
        value: value

    }
}

export function fetchCategorys(value) {
    return {
        type: FETCH_CATEGORYS_INIT_ACTION,
        value: value

    }
}

export function AddSubCatgry(value) {

    return {
        type: ADD_SUBCATGRY_INIT_ACTION,
        value: value
    }
}
export function DeleteValue(value) {
    return {
        type: DELETE_SUB_CATGRY_INIT_ACTION,
        value: value
    }
}
export function EditSubCatgry(data) {
    return {
        type: EDIT_SUBCATEGORY_INIT_ACTION,
        data: data
    }
}




