export const FETCH_STATE_BY_DISTRICT_INIT_ACTION =
  "FETCH_STATE_BY_DISTRICT_INIT_ACTION";
export const FETCH_STATE_BY_DISTRICT_SUCCESS_ACTION =
  FETCH_STATE_BY_DISTRICT_INIT_ACTION + "_SUCCESS";
export const FETCH_STATE_BY_DISTRICT_FAIL_ACTION =
  FETCH_STATE_BY_DISTRICT_INIT_ACTION + "_ERROR";

export const ADD_DISTRICT_INIT_ACTION = "ADD_DISTRICT_INIT_ACTION";
export const ADD_DISTRICT_SUCCESS_ACTION =
  ADD_DISTRICT_INIT_ACTION + "_SUCCESS";
export const ADD_DISTRICT_FAIL_ACTION = ADD_DISTRICT_INIT_ACTION + "_ERROR";

export const FETCH_DISTRICT_INIT_ACTION = "FETCH_DISTRICT_INIT_ACTION";
export const FETCH_DISTRICT_SUCCESS_ACTION =
  FETCH_DISTRICT_INIT_ACTION + "_SUCCESS";
export const FETCH_DISTRICT_FAIL_ACTION = FETCH_DISTRICT_INIT_ACTION + "_ERROR";

export const DELETE_DISTRICT_INIT_ACTION = "DELETE_DISTRICT_INIT_ACTION";
export const DELETE_DISTRICT_SUCCESS_ACTION =
  DELETE_DISTRICT_INIT_ACTION + "_SUCCESS";
export const DELETE_DISTRICT_FAIL_ACTION =
  DELETE_DISTRICT_INIT_ACTION + "_ERROR";

export const EDIT_DISTRICT_INIT_ACTION = "EDIT_DISTRICT_INIT_ACTION";
export const EDIT_DISTRICT_SUCCESS_ACTION =
  EDIT_DISTRICT_INIT_ACTION + "_SUCCESS";
export const EDIT_DISTRICT_FAIL_ACTION = EDIT_DISTRICT_INIT_ACTION + "_ERROR";

export function fetchState() {
  return {
    type: FETCH_STATE_BY_DISTRICT_INIT_ACTION,
  };
}

export function addDistrict(value) {
  return {
    type: ADD_DISTRICT_INIT_ACTION,
    value: value,
  };
}

export function fetchDistrict(params) {
  return {
    type: FETCH_DISTRICT_INIT_ACTION,
    value: params,
  };
}

export function DeleteValue(value) {
  return {
    type: DELETE_DISTRICT_INIT_ACTION,
    value: value,
  };
}


export function editDistrict(value) {
  return {
    type: EDIT_DISTRICT_INIT_ACTION,
    value: value,
  };
}
