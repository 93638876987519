import {

  FETCH_CURRENCY1_SUCCESS_ACTION,
  FETCH_CNTRY_CRNCY_SUCCESS_ACTION,
  EDIT_CNTRY_CRNCY_SUCCESS_ACTION,
  DELETE_CNTRY_CRNCY_SUCCESS_ACTION
} from "./action";

const initialState = {
  processing: false,
  error: false,
};

export default function (state: any = initialState, action: Function) {
  switch (action.type) {

    case FETCH_CURRENCY1_SUCCESS_ACTION:
      return {
        ...state,
        CurrencyList: action.payload.rows,
      };

    case FETCH_CNTRY_CRNCY_SUCCESS_ACTION:

      return {
        ...state,
        cntrycrncyList: action.payload.rows,
        cntrycrncyListCount: action.payload.count,
      };
    case EDIT_CNTRY_CRNCY_SUCCESS_ACTION:
     
      return {
        ...state,
        cntrycrncyList: state.cntrycrncyList.map((cntryCrncy) =>
        cntryCrncy.id === action.payload.id ? action.payload : cntryCrncy
      ),
      };
    case DELETE_CNTRY_CRNCY_SUCCESS_ACTION:

      return {
        ...state,
        cntrycrncyList: state.cntrycrncyList.filter(
          (item) => item.id !== action.payload
        ),
        cntrycrncyListCount: state.cntrycrncyListCount - 1
      };

    default:
      return {
        ...state,
      };
  }
}
