import { put, takeEvery } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_CATEGORY_INIT_ACTION,
  FETCH_CATEGORY_SUCCESS_ACTION,
  FETCH_CATEGORY_FAIL_ACTION,
  ADD_CATEGORY_INIT_ACTION,
  ADD_CATEGORY_SUCCESS_ACTION,
  ADD_CATEGORY_FAIL_ACTION,
  EDIT_CATEGORY_INIT_ACTION,
  EDIT_CATEGORY_SUCCESS_ACTION,
  EDIT_CATEGORY_FAIL_ACTION,
  DELETE_CATEGORY_INIT_ACTION,
  DELETE_CATEGORY_SUCCESS_ACTION,
  DELETE_CATEGORY_FAIL_ACTION,
} from "./action";
import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var btoa = require("Base64").btoa;
var history = createBrowserHistory();

function* fetchCategory(value) {
  let pageno = (value.value && value.value.page) || 1;
  let searchVal =
    !value || !value.value || !value.value.searchQuery
      ? ""
      : value.value.searchQuery;

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(
      `${appConfig.ip}/category?limit=10&search=${searchVal}&page=${pageno}&isActive=true`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_CATEGORY_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_CATEGORY_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* AddCategory(value) {
  try {
    const token = localStorage.getItem("saleStraitToken");

    const data = {
      code: value.value.code,
      name: value.value.name,
      desc: value.value.desc,
      // isActive: value.value.isActive,
    };

    const res = yield fetch(`${appConfig.ip}/category`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: ADD_CATEGORY_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Category added successfully", {
        autoClose: 3000,
      });
      yield put({ type: FETCH_CATEGORY_INIT_ACTION, value: { page: 1 } });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_CATEGORY_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* EditCategory(value) {
  let id = value.value.id;
  let data = {
    name: value.value.name,
    code: value.value.code,
    desc: value.value.desc,
  };

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/category/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      // yield put({ type: FETCH_Category_INIT_ACTION });
      yield toast.success("Category updated successfully", {
        autoClose: 3000,
      });
      const resJSON = yield res.json();
      yield put({
        type: EDIT_CATEGORY_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EDIT_CATEGORY_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* deletecategory(value) {
  let id = value.value.id;

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/category/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      // yield put({ type: FETCH_CATEGORY_INIT_ACTION });
      // const resJSON = yield res.json();
      yield put({
        type: DELETE_CATEGORY_SUCCESS_ACTION,
        payload: id,
      });
      yield toast.success("Deleted successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: DELETE_CATEGORY_FAIL_ACTION, error: err });
    } else {
    }
  }
}

export function* CategoryActionWatcher() {
  yield takeEvery(FETCH_CATEGORY_INIT_ACTION, fetchCategory);
  yield takeEvery(ADD_CATEGORY_INIT_ACTION, AddCategory);
  yield takeEvery(EDIT_CATEGORY_INIT_ACTION, EditCategory);
  yield takeEvery(DELETE_CATEGORY_INIT_ACTION, deletecategory);
}
