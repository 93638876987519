export const navAdmin = {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "icon-speedometer",
    },
    {
      name: "Channel Partner",
      url: "/channelpartner",
      icon: "icon-user",
    },
    {
      name: "Licensee",
      url: "/Licensee",
      icon: "icon-user",
    },

    {
      name: "Products",
      url: "/products",
      icon: "fa fa-product-hunt",
    },
    {
      name: "Leads",
      url: "/leads",
      icon: "fa fa-circle-o-notch",
    },
    {
      name: "Orders",
      url: "/orders",
      icon: "nav-icon nav-icon nav-icon fa fa-truck",
    },
    {
      name: "Customers",
      url: "/customers",
      icon: "fa fa-users",
    },
    {
      name: "Users",
      url: "/users",
      icon: "fa fa-users",
    },
    {
      name: "Payments",
      url: "/Payments",
      icon: "fa fa-book",
    },
    {
      name: "Platform Earnings",
      url: "/platform_Earnings",
      icon: "nav-icon fa fa-credit-card",
    },
    {
      name: "Support",
      url: "/support",
      icon: "fa fa-user-circle",
    },

    // {
    //   name: "Terms & Conditions",
    //   url: "/termsandcondition",
    //   icon: "fa fa-thumb-tack",
    // },
    // {
    //   name: "App Config",
    //   url: "/appConfig",
    //   icon: "fa fa-share-square-o",
    // },
    // {
    //   name: "Test Report Url",
    //   url: "/testReportUrl",
    //   icon: "fa fa-user-circle",
    // },
    // {
    //   name: "Api Doc Url",
    //   url: "/apiDocUrl",
    //   icon: "fa fa-user-circle",
    // },
    {
      name: "Settings",
      url: "/settings",
      icon: "fa fa-wrench",
      children: [
        {
          name: "Country",
          url: "/settings/country",
          icon: "fa fa-flag",
        },
        {
          name: "State",
          url: "/settings/state",
          icon: "icon-location-pin",
        },
        {
          name: "Districts",
          url: "/settings/district",
          icon: "icon-location-pin",
        },
        {
          name: "Locality",
          url: "/settings/Loc",
          icon: "icon-location-pin",
        },
        {
          name: "Pincode",
          url: "/settings/Pincode",
          icon: "icon-location-pin",
        },
        {
          name: "Currency",
          url: "/settings/currency",
          icon: "fa fa-money",
        },

        {
          name: "Country Currency",
          url: "/settings/CountryCrncy",
          icon: "fa fa-money",
        },
        {
          name: "GST",
          url: "/settings/GST",
          icon: "fa fa-thumb-tack",
        },

        {
          name: "Payment Mode",
          url: "/settings/PaymentMode",
          icon: "fa fa-thumb-tack",
        },
        {
          name: "Unit",
          url: "/settings/unit",
          icon: "fa fa-barcode",
        },
        {
          name: "Bank",
          url: "/settings/bank",
          icon: "fa fa-barcode",
        },

        {
          name: "Product Type",
          url: "/settings/ProductType",
          icon: "cui-chart",
        },
        {
          name: "Categories",
          url: "/settings/categories",
          icon: "cui-chart",
        },
        {
          name: "Subcategories",
          url: "/settings/subcategory",
          icon: "cui-chart",
        },

        {
          name: "Support Type",
          url: "/settings/supportType",
          icon: "fa fa-support",
        },
        {
          name: "Expense Type",
          url: "/settings/expenseType",
          icon: "fa fa-support",
        },
      ],
    },
  ],
};

export const navChannelPrtnr = {
  items: [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: "icon-speedometer",
    },
    {
      name: "Licensee",
      url: "/Licensee",
      icon: "icon-user",
    },
    {
      name: "Products",
      url: "/products",
      icon: "fa fa-product-hunt",
    },
    {
      name: "Leads",
      url: "/leads",
      icon: "fa fa-circle-o-notch",
    },
    {
      name: "Orders",
      url: "/orders",
      icon: "nav-icon nav-icon nav-icon fa fa-truck",
    },
    {
      name: "Customers",
      url: "/customers",
      icon: "fa fa-users",
    },
    {
      name: "Users",
      url: "/users",
      icon: "fa fa-users",
    },
    {
      name: "Payments",
      url: "/Payments",
      icon: "fa fa-book",
    },
    {
      name: "Support",
      url: "/support",
      icon: "fa fa-user-circle",
    },
  ],
};

export const navTechnicalAdmin = {
  items: [
    {
      name: "Test Report Url",
      url: "/testReportUrl",
      icon: "fa fa-user-circle",
    },
    {
      name: "Api Doc Url",
      url: "/apiDocUrl",
      icon: "fa fa-user-circle",
    },
    {
      name: "Support",
      url: "/support",
      icon: "fa fa-user-circle",
    },

    {
      name: "Terms & Conditions",
      url: "/termsandcondition",
      icon: "fa fa-thumb-tack",
    },
    {
      name: "App Config",
      url: "/appConfig",
      icon: "fa fa-share-square-o",
    },
  ],
};

// export default {
//  navLicensee:[
//   {
//     name: 'Dashboard',
//     url: '/dashboard',
//     icon: 'icon-speedometer',
//   },
//   // Don't comment this
//   // {name: "",url:"", }, {name: "",url:"", }, {name: "",url:"", }, {name: "",url:"", },
// ]
// }
