import {
  FETCH_PINCODE_SUCCESS_ACTION,
  EDIT_PINCODE_SUCCESS_ACTION,
  DELETE_PINCODE_SUCCESS_ACTION,
  FETCH_PINCODE_DISTRICT_SUCCESS_ACTION
} from "./action";

const initialState = {
  processing: false,
  error: false,
};

export default function (state: any = initialState, action: Function) {
  switch (action.type) {
    case FETCH_PINCODE_SUCCESS_ACTION:
      return {
        ...state,
        PincodeList: action.payload.rows,
        PincodeDtlsCount: action.payload.count,
      };

    case FETCH_PINCODE_DISTRICT_SUCCESS_ACTION:
      return {
        ...state,
        PincodeDistrictList: action.payload.rows,
        PincodeDistrictDtlsCount: action.payload.count,
      };
    case EDIT_PINCODE_SUCCESS_ACTION:
      return {
        ...state,
        PincodeList: state.CategoryList.map((ctgry) =>
          ctgry.id === action.payload.id ? action.payload : ctgry
        ),
      };
    case DELETE_PINCODE_SUCCESS_ACTION:

      return {
        ...state,
        PincodeList: state.PincodeList.filter(
          (item) => item.id !== action.payload
        ),
        PincodeDtlsCount: state.PincodeDtlsCount - 1
      };

    default:
      return {
        ...state,
      };
  }
}
