import { put, takeEvery } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_PINCODE_INIT_ACTION,
  FETCH_PINCODE_SUCCESS_ACTION,
  FETCH_PINCODE_FAIL_ACTION,
  ADD_PINCODE_INIT_ACTION,
  ADD_PINCODE_SUCCESS_ACTION,
  ADD_PINCODE_FAIL_ACTION,
  EDIT_PINCODE_INIT_ACTION,
  EDIT_PINCODE_SUCCESS_ACTION,
  EDIT_PINCODE_FAIL_ACTION,
  DELETE_PINCODE_INIT_ACTION,
  DELETE_PINCODE_SUCCESS_ACTION,
  DELETE_PINCODE_FAIL_ACTION,
  FETCH_PINCODE_DISTRICT_INIT_ACTION,
  FETCH_PINCODE_DISTRICT_SUCCESS_ACTION,
  FETCH_PINCODE_DISTRICT_FAIL_ACTION
} from "./action";
import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var btoa = require("Base64").btoa;
var history = createBrowserHistory();

function* fetchPincode(value) {
  let pageno = value.value.page === undefined ? 1 : value.value.page;
  let searchVal = !value || !value.value || !value.value.searchVal ? '' : value.value.searchVal

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/pincode?limit=10&page=${pageno}&search=${searchVal}&isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch { }
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_PINCODE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_PINCODE_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* addPincode(value) {

  try {
    const token = localStorage.getItem("saleStraitToken");

    const data = {
      circleName: value.value.circleName,
      regionName: value.value.regionName,
      divisonName: value.value.divisonName,
      officeName: value.value.officeName,
      officeType: value.value.officeType,
      pincode: value.value.pincode,
      delivery: value.value.delivery,
      district: value.value.districtId,
      state: value.value.stateId,
      // isActive: value.value.isActive,
    };

    const res = yield fetch(`${appConfig.ip}/pincode`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: ADD_PINCODE_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Pincode added successfully", {
        autoClose: 3000,
      });
      yield put({
        type: FETCH_PINCODE_INIT_ACTION,value: { page: 1 }
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_PINCODE_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* editPincode(value) {

  let id = value.value.id;
  let data = {
    circleName: value.value.circleName,
    regionName: value.value.regionName,
    divisonName: value.value.divisonName,
    officeName: value.value.officeName,
    officeType: value.value.officeType,
    pincode: value.value.pincode,
    delivery: value.value.delivery,
    district: value.value.districtId,
    state: value.value.stateId,
    // isActive: value.value.isActive,
  };

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/pincode/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch { }
      throw Object.assign(res, errJSON);
    } else {
      yield put({
        type: FETCH_PINCODE_INIT_ACTION,
      });
      yield toast.success("Pincode updated successfully", {
        autoClose: 3000,
      });
      const resJSON = yield res.json();
      // yield put({
      //   type: EDIT_PINCODE_SUCCESS_ACTION,
      //   payload: resJSON
      // });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EDIT_PINCODE_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* deletePincode(value) {

  let id = value.value.id;

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/pincode/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },

    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch { }
      throw Object.assign(res, errJSON);
    } else {
      yield put({
        type: FETCH_PINCODE_INIT_ACTION,
      });
      // yield put({
      //   type: DELETE_PINCODE_SUCCESS_ACTION,
      //   payload: id
      // });
      yield toast.success("Deleted successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: DELETE_PINCODE_FAIL_ACTION, error: err });
    } else {
    }
  }
}




function* fetchPincodeDistrict(value) {
  let id = value.value.stateId;

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/districts?stateId=` + id, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch { }
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_PINCODE_DISTRICT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_PINCODE_DISTRICT_FAIL_ACTION, error: err });
    } else {
    }
  }
}


export function* PincodeActionWatcher() {
  yield takeEvery(FETCH_PINCODE_INIT_ACTION, fetchPincode);
  yield takeEvery(ADD_PINCODE_INIT_ACTION, addPincode);
  yield takeEvery(EDIT_PINCODE_INIT_ACTION, editPincode);
  yield takeEvery(DELETE_PINCODE_INIT_ACTION, deletePincode);
  yield takeEvery(FETCH_PINCODE_DISTRICT_INIT_ACTION, fetchPincodeDistrict);

}
