import {
  FETCH_UNIT_SUCCESS_ACTION,

  EDIT_UNIT_SUCCESS_ACTION,
  DELETE_UNIT_SUCCESS_ACTION
} from "./action";

const initialState = {
  processing: false,
  error: false,
};

export default function (state: any = initialState, action: Function) {
  switch (action.type) {
    case FETCH_UNIT_SUCCESS_ACTION:
      return {
        ...state,
        UnitList: action.payload.rows,
        UnitDtlsCount: action.payload.count,
      };

 
    case EDIT_UNIT_SUCCESS_ACTION:
      return {
        ...state,
         UnitList: state.UnitList.map((unit) =>
          unit.id === action.payload.id ? action.payload : unit
        ),
      };
    case DELETE_UNIT_SUCCESS_ACTION:

      return {
        ...state,
        UnitList: state.UnitList.filter(
          (item) => item.id !== action.payload
        ),
        UnitDtlsCount: state.UnitDtlsCount - 1
      };

    default:
      return {
        ...state,
      };
  }
}
