import {
  FETCH_TERMSANDCONDITION_SUCCESS_ACTION,
  EDIT_TERMSANDCONDITION_SUCCESS_ACTION
} from "./action";

const initialState = {
  processing: false,
  error: false
};

export default function TermsandConditionReducer(state: any = initialState, action: Function) {
  switch (action.type) {
    case FETCH_TERMSANDCONDITION_SUCCESS_ACTION:
      return {
        ...state,
        TrmsadCondtonDtList: action.payload.rows,
        TrmsadCondtonCount: action.payload.count
      };
    case EDIT_TERMSANDCONDITION_SUCCESS_ACTION:
      return {
        ...state,
        TrmsadCondtonDtList: state.TrmsadCondtonDtList.map(terms =>
          terms.id === action.payload.id ? action.payload : terms
        )
      };

    default:
      return {
        ...state
      };
  }
}
