import {
    FETCH_WITHDRAWALLIMIT_SUCCESS_ACTION,
  
    EDIT_WITHDRAWALLIMIT_SUCCESS_ACTION,
    DELETE_WITHDRAWALLIMIT_SUCCESS_ACTION,
    FETCH_USERSS_SUCCESS_ACTION
  } from "./action";
  
  const initialState = {
    processing: false,
    error: false,
  };
  
  export default function (state: any = initialState, action: Function) {
    switch (action.type) {
      case FETCH_WITHDRAWALLIMIT_SUCCESS_ACTION:
        return {
          ...state,
          WithdrawalLimitList: action.payload.rows,
          withdrawalLimitDtlsCount: action.payload.count,
        };
        case FETCH_USERSS_SUCCESS_ACTION:
          return {
            ...state,
            RoleList: action.payload.rows,
          };
  
   
      case EDIT_WITHDRAWALLIMIT_SUCCESS_ACTION:
        return {
          ...state,
          WithdrawalLimitList: state.WithdrawalLimitList.map((withdrawalLimit) =>
          withdrawalLimit.id === action.payload.id ? action.payload : withdrawalLimit
          ),
        };
      case DELETE_WITHDRAWALLIMIT_SUCCESS_ACTION:
  
        return {
          ...state,
          WithdrawalLimitList: state.WithdrawalLimitList.filter(
            (item) => item.id !== action.payload
          ),
          withdrawalLimitDtlsCount: state.withdrawalLimitDtlsCount - 1
        };
  
      default:
        return {
          ...state,
        };
    }
  }
  