import { put, takeEvery } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_BANK_INIT_ACTION,
  FETCH_BANK_SUCCESS_ACTION,
  FETCH_BANK_FAIL_ACTION,
  ADD_BANK_INIT_ACTION,
  ADD_BANK_SUCCESS_ACTION,
  ADD_BANK_FAIL_ACTION,
  EDIT_BANK_INIT_ACTION,
  EDIT_BANK_SUCCESS_ACTION,
  EDIT_BANK_FAIL_ACTION,
  DELETE_BANK_INIT_ACTION,
  DELETE_BANK_SUCCESS_ACTION,
  DELETE_BANK_FAIL_ACTION,

} from "./action";
import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var btoa = require("Base64").btoa;
var history = createBrowserHistory();

function* fetchBank(value) {
  let pageno = value.value === undefined ? 1 : value.value;

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/bankName?limit=10&page=` + pageno+'&isActive=true', {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
   
      yield put({
        type: FETCH_BANK_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_BANK_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* AddBank(value) {
 
  try {
    const token = localStorage.getItem("saleStraitToken");

    const data = {
      code: value.value.code,
      name: value.value.name,
      desc: value.value.desc,
      // isActive: value.value.isActive,
    };

    const res = yield fetch(`${appConfig.ip}/bankName`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: ADD_BANK_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Bank added successfully", {
        autoClose: 3000,
      });
      yield put({
        type: FETCH_BANK_INIT_ACTION,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_BANK_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* EditBank(value) {
  let id = value.value.id;
  let data = {
    name: value.value.name,
    code: value.value.code,
    desc: value.value.desc,
  };

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/bankName/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      // yield put({ type: FETCH_Bank_INIT_ACTION });
      yield toast.success("Bank updated successfully", {
        autoClose: 3000,
      });
      const resJSON = yield res.json();
      yield put({
        type: EDIT_BANK_SUCCESS_ACTION,
        payload:resJSON
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EDIT_BANK_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* deleteBank(value) {

  let id = value.value.id;

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/bankName/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      // yield put({ type: FETCH_BANK_INIT_ACTION });
      // const resJSON = yield res.json();
      yield put({
        type: DELETE_BANK_SUCCESS_ACTION,
        payload:id
      });
      yield toast.success("Deleted successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: DELETE_BANK_FAIL_ACTION, error: err });
    } else {
    }
  }
}



export function* BankActionWatcher() {
  yield takeEvery(FETCH_BANK_INIT_ACTION, fetchBank);
  yield takeEvery(ADD_BANK_INIT_ACTION, AddBank);
  yield takeEvery(EDIT_BANK_INIT_ACTION, EditBank);
  yield takeEvery(DELETE_BANK_INIT_ACTION, deleteBank);

}
