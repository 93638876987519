export const FETCH_LOCALITYY_INIT_ACTION = "FETCH_LOCALITYY_INIT_ACTION";
export const FETCH_LOCALITYY_SUCCESS_ACTION =
  FETCH_LOCALITYY_INIT_ACTION + "_SUCCESS";
export const FETCH_LOCALITYY_FAIL_ACTION = FETCH_LOCALITYY_INIT_ACTION + "_ERROR";

export const ADD_LOCALITYY_INIT_ACTION = "ADD_LOCALITYY_INIT_ACTION";
export const ADD_LOCALITYY_SUCCESS_ACTION =
  ADD_LOCALITYY_INIT_ACTION + "_SUCCESS";
export const ADD_LOCALITYY_FAIL_ACTION = ADD_LOCALITYY_INIT_ACTION + "_ERROR";

export const EDIT_LOCALITYY_INIT_ACTION = "EDIT_LOCALITYY_INIT_ACTION";
export const EDIT_LOCALITYY_SUCCESS_ACTION =
  EDIT_LOCALITYY_INIT_ACTION + "_SUCCESS";
export const EDIT_LOCALITYY_FAIL_ACTION = EDIT_LOCALITYY_INIT_ACTION + "_ERROR";

export const DELETE_LOCALITYY_INIT_ACTION = "DELETE_LOCALITYY_INIT_ACTION";
export const DELETE_LOCALITYY_SUCCESS_ACTION =
  DELETE_LOCALITYY_INIT_ACTION + "_SUCCESS";
export const DELETE_LOCALITYY_FAIL_ACTION =
  DELETE_LOCALITYY_INIT_ACTION + "_ERROR";



export const FETCH_LOCALITYY_DISTRICT_INIT_ACTION = "FETCH_LOCALITYY_DISTRICT_INIT_ACTION";
export const FETCH_LOCALITYY_DISTRICT_SUCCESS_ACTION =
  FETCH_LOCALITYY_DISTRICT_INIT_ACTION + "_SUCCESS";
export const FETCH_LOCALITYY_DISTRICT_FAIL_ACTION = FETCH_LOCALITYY_DISTRICT_INIT_ACTION + "_ERROR";

export const FETCH_STATES_INIT_ACTION =
  "FETCH_STATES_INIT_ACTION";
export const FETCH_STATES_SUCCESS_ACTION =
  FETCH_STATES_INIT_ACTION + "_SUCCESS";
export const FETCH_STATES_FAIL_ACTION =
  FETCH_STATES_INIT_ACTION + "_ERROR";

  export const FETCH_COUNTRY_DRODOWN_INIT_ACTION =
  " FETCH_COUNTRY_DRODOWN_INIT_ACTION";
export const FETCH_COUNTRY_DRODOWN_SUCCESS_ACTION =
  FETCH_COUNTRY_DRODOWN_INIT_ACTION + "_SUCCESS";
export const FETCH_COUNTRY_DRODOWN_FAIL_ACTION =
  FETCH_COUNTRY_DRODOWN_INIT_ACTION + "_ERROR";



export function fetchLocalityy(value) {
  return {
    type: FETCH_LOCALITYY_INIT_ACTION,
    value: value,

  };
}


export function fetchLocalityyDistrict(value) {
  return {
    type: FETCH_LOCALITYY_DISTRICT_INIT_ACTION,
    value: value,
  };
}

export function addLocalityy(value) {
  return {
    type: ADD_LOCALITYY_INIT_ACTION,
    value: value,
  };
}

export function editLocalityy(value) {
  return {
    type: EDIT_LOCALITYY_INIT_ACTION,
    value: value,
  };
}

export function deleteLocalityy(value) {
  return {
    type: DELETE_LOCALITYY_INIT_ACTION,
    value: value,
  };
}

export function fetchStates(value) {
  return {
    type: FETCH_STATES_INIT_ACTION,
    value: value,

  };
}

export function fetchCountry(value) {
  return {
    type: FETCH_COUNTRY_DRODOWN_INIT_ACTION,
    value: value,

  };
}


