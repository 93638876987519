import * as actionTypes from "./constants";

const initialState = {
  processing: false,
  error: false,
  isModel: false,
};

export default function googleFormReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_CHANNELPRTNR_SUCCESS_ACTION:
      return {
        ...state,
        channelPrtnrList: action.payload.rows,
        channelPrtnrCount: action.payload.count,
      };
    case actionTypes.CHANGESTATUS_CHNLPRTNR_SUCCESS_ACTION:
      return {
        ...state,
        channelPrtnrList: state.channelPrtnrList.map((item) =>
          item.id === action.payload.id
            ? { ...item, status: action.payload.status }
            : item
        ),
      };
    case actionTypes.UPDATE_CHNLPRTNR_SUCCESS_ACTION:
      return {
        ...state,
        channelPrtnrList: state.channelPrtnrList.map((item) =>
          item.id === action.payload.id
            ? action.payload : item
        ),
      };

    default:
      return {
        ...state,
      };
  }
}
