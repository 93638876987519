import { put, takeEvery } from "redux-saga/effects";

import {
  FETCH_STATE_BY_DISTRICT_INIT_ACTION,
  FETCH_STATE_BY_DISTRICT_SUCCESS_ACTION,
  FETCH_STATE_BY_DISTRICT_FAIL_ACTION,
  ADD_DISTRICT_INIT_ACTION,
  ADD_DISTRICT_SUCCESS_ACTION,
  ADD_DISTRICT_FAIL_ACTION,
  FETCH_DISTRICT_INIT_ACTION,
  FETCH_DISTRICT_SUCCESS_ACTION,
  FETCH_DISTRICT_FAIL_ACTION,
  DELETE_DISTRICT_INIT_ACTION,
  DELETE_DISTRICT_SUCCESS_ACTION,
  DELETE_DISTRICT_FAIL_ACTION,
  EDIT_DISTRICT_INIT_ACTION,
  EDIT_DISTRICT_SUCCESS_ACTION,
  EDIT_DISTRICT_FAIL_ACTION,
} from "./action";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* fetchState() {
  try {
    const token = localStorage.getItem("saleStraitToken");
    const res = yield fetch(`${appConfig.ip}/state?isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_STATE_BY_DISTRICT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_STATE_BY_DISTRICT_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* addDistrict(value) {
  // let geo = [];
  // geo.push(value.value.latitude, value.value.longitude);
  const token = localStorage.getItem("saleStraitToken");

  try {
    let data = {
      name: value.value.districtName,
      stateId: value.value.states,
      // geo: geo,
    };

    const res = yield fetch(`${appConfig.ip}/districts`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_DISTRICT_INIT_ACTION,
        value: { page: 1 },
      });

      yield toast.success("District added successfully", {
        autoClose: 3000,
      });

      yield put({
        type: ADD_DISTRICT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_DISTRICT_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* fetchDistrict(value) {
  let pageno = (value.value && value.value.page) || 1;
  const token = localStorage.getItem("saleStraitToken");
  let searchVal =
    !value || !value.value || !value.value.searchQuery
      ? ""
      : value.value.searchQuery;
  let filter =
    !value || !value.value || !value.value.filter ? null : value.value.filter;
  let _url = `${appConfig.ip}/districts?limit=10&search=${searchVal}&page=${pageno}&isActive=true`;

  if (filter) {
    _url = `${_url}&stateId=${filter}`;
  }

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_DISTRICT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_DISTRICT_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* deleteDistrict(value) {
  let id = value.value.id;
  try {
    const token = localStorage.getItem("saleStraitToken");
    const res = yield fetch(`${appConfig.ip}/districts/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
        if (errJSON) {
          yield toast.error("Delete not allowed", {
            autoClose: 3000,
          });
        }
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      // yield put({
      //   type: FETCH_DISTRICT_INIT_ACTION,
      // });
      //const resJSON = yield res.json();

      yield toast.success("Deleted successfully", {
        autoClose: 3000,
      });
      yield put({
        type: DELETE_DISTRICT_SUCCESS_ACTION,
        payload: id,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: DELETE_DISTRICT_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* editDistrict(value) {
  try {
    // const geo = [];
    // geo.push(value.value.latitude, value.value.longitude);

    const id = value.value.id;

    const token = localStorage.getItem("saleStraitToken");

    const DistrictValue = {
      name: value.value.districtName,
      stateId: value.value.stateId,
      // geo: geo,
    };
    const res = yield fetch(`${appConfig.ip}/districts/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(DistrictValue),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      // yield put({
      //   type: FETCH_DISTRICT_INIT_ACTION,
      // });

      yield toast.success("District updated successfully", {
        autoClose: 3000,
      });

      yield put({
        type: EDIT_DISTRICT_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EDIT_DISTRICT_FAIL_ACTION, error: err });
    } else {
    }
  }
}

export function* DistrictActionWatcher() {
  yield takeEvery(FETCH_STATE_BY_DISTRICT_INIT_ACTION, fetchState);
  yield takeEvery(ADD_DISTRICT_INIT_ACTION, addDistrict);
  yield takeEvery(FETCH_DISTRICT_INIT_ACTION, fetchDistrict);
  yield takeEvery(DELETE_DISTRICT_INIT_ACTION, deleteDistrict);

  yield takeEvery(EDIT_DISTRICT_INIT_ACTION, editDistrict);
}
