export const FETCH_CURRENCY_INIT_ACTION = "FETCH_CURRENCY_INIT_ACTION";
export const FETCH_CURRENCY_SUCCESS_ACTION =
  FETCH_CURRENCY_INIT_ACTION + "_SUCCESS";
export const FETCH_CURRENCY_FAIL_ACTION = FETCH_CURRENCY_INIT_ACTION + "_ERROR";

export const ADD_CURRENCY_INIT_ACTION = "ADD_CURRENCY_INIT_ACTION";
export const ADD_CURRENCY_SUCCESS_ACTION =
  ADD_CURRENCY_INIT_ACTION + "_SUCCESS";
export const ADD_CURRENCY_FAIL_ACTION = ADD_CURRENCY_INIT_ACTION + "_ERROR";

export const EDIT_CURRENCY_INIT_ACTION = "EDIT_CURRENCY_INIT_ACTION";
export const EDIT_CURRENCY_SUCCESS_ACTION =
  EDIT_CURRENCY_INIT_ACTION + "_SUCCESS";
export const EDIT_CURRENCY_FAIL_ACTION = EDIT_CURRENCY_INIT_ACTION + "_ERROR";

export const DELETE_CURRENCY_INIT_ACTION = "DELETE_CURRENCY_INIT_ACTION";
export const DELETE_CURRENCY_SUCCESS_ACTION =
  DELETE_CURRENCY_INIT_ACTION + "_SUCCESS";
export const DELETE_CURRENCY_FAIL_ACTION =
  DELETE_CURRENCY_INIT_ACTION + "_ERROR";


export function fetchCurrency(value) {
  return {
    type: FETCH_CURRENCY_INIT_ACTION,
    value: value,

  };
}

export function AddCurrency(value) {
  return {
    type: ADD_CURRENCY_INIT_ACTION,
    value: value,
  };
}

export function EditCurrency(value) {
  return {
    type: EDIT_CURRENCY_INIT_ACTION,
    value: value,
  };
}

export function DeleteValue(value) {
  return {
    type: DELETE_CURRENCY_INIT_ACTION,
    value: value,
  };
}




