import {
  FETCH_CATEGORY_SUCCESS_ACTION,

  EDIT_CATEGORY_SUCCESS_ACTION,
  DELETE_CATEGORY_SUCCESS_ACTION
} from "./action";

const initialState = {
  processing: false,
  error: false,
};

export default function (state: any = initialState, action: Function) {
  switch (action.type) {
    case FETCH_CATEGORY_SUCCESS_ACTION:
      return {
        ...state,
        CategoryList: action.payload.rows,
        categoryDtlsCount: action.payload.count,
      };

 
    case EDIT_CATEGORY_SUCCESS_ACTION:
      return {
        ...state,
        CategoryList: state.CategoryList.map((ctgry) =>
        ctgry.id === action.payload.id ? action.payload : ctgry
        ),
      };
    case DELETE_CATEGORY_SUCCESS_ACTION:

      return {
        ...state,
        CategoryList: state.CategoryList.filter(
          (item) => item.id !== action.payload
        ),
        categoryDtlsCount: state.categoryDtlsCount - 1
      };

    default:
      return {
        ...state,
      };
  }
}
