import React from "react";

const Dashboard = React.lazy(() => import("./screens/Dashboard"));
const Login = React.lazy(() => import("./containers/LoginContainer"));
const Licensee = React.lazy(() => import("./containers/LicenseeContainer"));
const Country = React.lazy(() => import("./containers/CountryContainer"));
const State = React.lazy(() => import("./containers/StateContainer"));
const Currency = React.lazy(() => import("./containers/CurrencyContainer"));
const District = React.lazy(() => import("./containers/DistrictContainer"));
const Unit = React.lazy(() => import("./containers/UnitContainer"));
const SupportType = React.lazy(() =>
  import("./containers/SupportTypeContainer")
);
const Subcategory = React.lazy(() =>
  import("./containers/SubcategoryContainer")
);
const CountryCrncy = React.lazy(() =>
  import("./containers/CountryCrncyContainer")
);
const ProductType = React.lazy(() =>
  import("./containers/ProductTypeContainer")
);
const Categories = React.lazy(() => import("./containers/CategoryContainer"));
const TermsAndConditions = React.lazy(() =>
  import("./containers/TermsandConditionContainer")
);
const AppConfig = React.lazy(() => import("./containers/AppConfigContainer"));
const GST = React.lazy(() => import("./containers/GSTContainer"));
const Pincode = React.lazy(() => import("./containers/PincodeContainer"));
const Products = React.lazy(() => import("./containers/ProductsContainer"));
const Customers = React.lazy(() => import("./containers/CustomerContainer"));
const Users = React.lazy(() => import("./containers/UserContainer"));
const Payments = React.lazy(() => import("./containers/PaymentsContainer"));
const Platforms = React.lazy(() => import("./containers/EarningsContainer"));
const Orders = React.lazy(() => import("./containers/OrderContainer"));
const Bank = React.lazy(() => import("./containers/BankContainer"));
const Leads = React.lazy(() => import("./containers/LeadsContainer"));
const WithdrawalLimit = React.lazy(() =>
  import("./containers/WithdrawalLimitContainer")
);
const PaymentMode = React.lazy(() =>
  import("./containers/PaymentModeContainer")
);
const Support = React.lazy(() => import("./containers/SupportContainer"));
const Loc = React.lazy(() => import("./containers/LocContainer"));
const ChannelPartner = React.lazy(() =>
  import("./containers/ChannelPartnerContainer")
);

const ResetPassword = React.lazy(() =>
  import("./containers/ResetPasswordContainer")
);
const TestReportUrl = React.lazy(() =>
  import("./containers/TestReportUrlConatiner")
);

const ApiDocUrl = React.lazy(() => import("./containers/ApiDocUrlContainer"));

const ExpenseType = React.lazy(() =>
  import("./containers/ExpenseTypeContainer")
);

const routes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/login", name: "Login", component: Login },
  { path: "/licensee", name: "Licensee", component: Licensee },
  { path: "/settings/country", name: "Country", component: Country },
  { path: "/settings/state", name: "State", component: State },
  { path: "/settings/currency", name: "Currency", component: Currency },
  { path: "/settings/district", name: "Districts", component: District },
  { path: "/settings/unit", name: "Unit", component: Unit },
  {
    path: "/settings/supportType",
    name: "Support Types",
    component: SupportType,
  },
  {
    path: "/settings/subcategory",
    name: "Subcategory",
    component: Subcategory,
  },
  {
    path: "/settings/CountryCrncy",
    name: "Country currency",
    component: CountryCrncy,
  },
  {
    path: "/settings/ProductType",
    name: "Product Type",
    component: ProductType,
  },
  { path: "/settings/Categories", name: "Categories", component: Categories },
  {
    path: "/termsandcondition",
    name: "Terms & Conditions",
    component: TermsAndConditions,
  },
  { path: "/settings/GST", name: "GST", component: GST },
  { path: "/settings/Pincode", name: "Pincode", component: Pincode },
  { path: "/settings/district", name: "Districts", component: District },
  { path: "/settings/unit", name: "Unit", component: Unit },
  {
    path: "/settings/subcategory",
    name: "Subcategory",
    component: Subcategory,
  },
  {
    path: "/settings/CountryCrncy",
    name: "Country currency",
    component: CountryCrncy,
  },
  {
    path: "/settings/ProductType",
    name: "Product Type",
    component: ProductType,
  },
  { path: "/settings/Categories", name: "Categories", component: Categories },
  { path: "/appConfig", name: "App Config", component: AppConfig },
  { path: "/settings/GST", name: "GST", component: GST },
  { path: "/products", name: "Products", component: Products },
  { path: "/customers", name: "Customers", component: Customers },
  { path: "/users", name: "Users", component: Users },
  { path: "/Payments", name: "Payments", component: Payments },
  {
    path: "/platform_earnings",
    name: "Platform Earnings",
    component: Platforms,
  },
  { path: "/orders", name: "Orders", component: Orders },
  { path: "/settings/Bank", name: "Bank", component: Bank },
  { path: "/leads", name: "Leads", component: Leads },
  {
    path: "/settings/PaymentMode",
    name: "Payment Mode",
    component: PaymentMode,
  },
  {
    path: "/settings/WithdrawalLimit",
    name: "Withdrawal Limit",
    component: WithdrawalLimit,
  },
  { path: "/support", name: "Support", component: Support },
  { path: "/settings/Loc", name: "Locality", component: Loc },
  {
    path: "/channelpartner",
    name: "Channel Partner",
    component: ChannelPartner,
  },
  { path: "/resetpassword", name: "Reset Password", component: ResetPassword },
  {
    path: "/testReportUrl",
    name: "TestReportUrl",
    component: TestReportUrl,
  },
  {
    path: "/apiDocUrl",
    name: "apiDocUrl",
    component: ApiDocUrl,
  },
  {
    path: "/settings/expenseType",
    name: "Expense Type",
    component: ExpenseType,
  },
];

export default routes;
