export const FETCH_SUPPORTTYPE_INIT_ACTION = "FETCH_SUPPORTTYPE_INIT_ACTION";
export const FETCH_SUPPORTTYPE_SUCCESS_ACTION =
  FETCH_SUPPORTTYPE_INIT_ACTION + "_SUCCESS";
export const FETCH_SUPPORTTYPE_FAIL_ACTION =
  FETCH_SUPPORTTYPE_INIT_ACTION + "_ERROR";

export const ADD_SUPPORTTYPE_INIT_ACTION = "ADD_SUPPORTTYPE_INIT_ACTION";
export const ADD_SUPPORTTYPE_SUCCESS_ACTION =
  ADD_SUPPORTTYPE_INIT_ACTION + "_SUCCESS";
export const ADD_SUPPORTTYPE_FAIL_ACTION =
  ADD_SUPPORTTYPE_INIT_ACTION + "_ERROR";

export const EDIT_SUPPORTTYPE_INIT_ACTION = "EDIT_SUPPORTTYPE_INIT_ACTION";
export const EDIT_SUPPORTTYPE_SUCCESS_ACTION =
  EDIT_SUPPORTTYPE_INIT_ACTION + "_SUCCESS";
export const EDIT_SUPPORTTYPE_FAIL_ACTION =
  EDIT_SUPPORTTYPE_INIT_ACTION + "_ERROR";

export const DELETE_SUPPORTTYPE_INIT_ACTION = "DELETE_SUPPORTTYPE_INIT_ACTION";
export const DELETE_SUPPORTTYPE_SUCCESS_ACTION =
  DELETE_SUPPORTTYPE_INIT_ACTION + "_SUCCESS";
export const DELETE_SUPPORTTYPE_FAIL_ACTION =
  DELETE_SUPPORTTYPE_INIT_ACTION + "_ERROR";

export function supportTypelist(value) {
  return {
    type: FETCH_SUPPORTTYPE_INIT_ACTION,
    value: value

  };
}
export function AddSupportType(value) {
  return {
    type: ADD_SUPPORTTYPE_INIT_ACTION,
    value: value
  };
}
export function EditSupportType(value) {
  return {
    type: EDIT_SUPPORTTYPE_INIT_ACTION,
    value: value
  };
}
export function DeleteValue(value) {
 
  return {
    type: DELETE_SUPPORTTYPE_INIT_ACTION,
    value: value
  };
}
export function SupportTypeSlectedPage(value) {
  return {
    type: FETCH_SUPPORTTYPE_INIT_ACTION,
    value: value
  };
}
