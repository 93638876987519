export const FETCH_BANK_INIT_ACTION = "FETCH_BANK_INIT_ACTION";
export const FETCH_BANK_SUCCESS_ACTION =
  FETCH_BANK_INIT_ACTION + "_SUCCESS";
export const FETCH_BANK_FAIL_ACTION = FETCH_BANK_INIT_ACTION + "_ERROR";

export const ADD_BANK_INIT_ACTION = "ADD_BANK_INIT_ACTION";
export const ADD_BANK_SUCCESS_ACTION =
  ADD_BANK_INIT_ACTION + "_SUCCESS";
export const ADD_BANK_FAIL_ACTION = ADD_BANK_INIT_ACTION + "_ERROR";

export const EDIT_BANK_INIT_ACTION = "EDIT_BANK_INIT_ACTION";
export const EDIT_BANK_SUCCESS_ACTION =
  EDIT_BANK_INIT_ACTION + "_SUCCESS";
export const EDIT_BANK_FAIL_ACTION = EDIT_BANK_INIT_ACTION + "_ERROR";

export const DELETE_BANK_INIT_ACTION = "DELETE_BANK_INIT_ACTION";
export const DELETE_BANK_SUCCESS_ACTION =
  DELETE_BANK_INIT_ACTION + "_SUCCESS";
export const DELETE_BANK_FAIL_ACTION =
  DELETE_BANK_INIT_ACTION + "_ERROR";


export function fetchBank() {
  return {
    type: FETCH_BANK_INIT_ACTION,
  };
}

export function AddBank(value) {
  return {
    type: ADD_BANK_INIT_ACTION,
    value: value,
  };
}

export function EditBank(value) {
  return {
    type: EDIT_BANK_INIT_ACTION,
    value: value,
  };
}

export function DeleteValue(value) {
  return {
    type: DELETE_BANK_INIT_ACTION,
    value: value,
  };
}

export function BankSlectedPage(value) {
  return {
    type: FETCH_BANK_INIT_ACTION,
    value: value,
  };
}



