import { call, all } from "redux-saga/effects";
import { LoginActionWatcher } from "../containers/LoginContainer/saga";
import { dashboardActionWatcher } from "../containers/DefaultLayout/saga";

import { LicenseeActionWatcher } from "../containers/LicenseeContainer/saga";
import { countryActionWatcher } from "../containers/CountryContainer/saga";
import { StateActionWatcher } from "../containers/StateContainer/saga";
import { DistrictActionWatcher } from "../containers/DistrictContainer/saga";
import { UnitActionWatcher } from "../containers/UnitContainer/saga";
import { SupportTypeActionWatcher } from "../containers/SupportTypeContainer/saga";
import { currencyActionWatcher } from "../containers/CurrencyContainer/saga";
import { SubCatgryActionWatcher } from "../containers/SubcategoryContainer/saga";
import { CountryCrncyActionWatcher } from "../containers/CountryCrncyContainer/saga";
import { ProductTypeActionWatcher } from "../containers/ProductTypeContainer/saga";
import { CategoryActionWatcher } from "../containers/CategoryContainer/saga";
import { termsandconditionActionWatcher } from "../containers/TermsandConditionContainer/saga";
import { GSTActionWatcher } from "../containers/GSTContainer/saga";
import { PincodeActionWatcher } from "../containers/PincodeContainer/saga";
import { BankActionWatcher } from "../containers/BankContainer/saga";
import { CustomerActionWatcher } from "../containers/CustomerContainer/saga";
import { UserActionWatcher } from "../containers/UserContainer/saga";
import { ProductActionWatcher } from "../containers/ProductsContainer/saga";
import { countryCurrencyActionWatcher } from "../containers/CountryCurrencyContainer/saga";
import { OrderActionWatcher } from "../containers/OrderContainer/saga";
import { LeadsActionWatcher } from "../containers/LeadsContainer/saga";
import { PaymentModeActionWatcher } from "../containers/PaymentModeContainer/saga";
import { PaymentsActionWatcher } from "../containers/PaymentsContainer/saga";
import { EarningsActionWatcher } from "../containers/EarningsContainer/saga";
import { WithdrawalLimitActionWatcher } from "../containers/WithdrawalLimitContainer/saga";
import { SupportActionWatcher } from "../containers/SupportContainer/saga";
import { LocalityyActionWatcher } from "../containers/LocContainer/saga";
import { AppConfigActionWatcher } from "../containers/AppConfigContainer/saga";
import { ChannelPrtnrActionWatcher } from "../containers/ChannelPartnerContainer/saga";
import { resetPasswordActionWatcher } from "../containers/ResetPasswordContainer/saga";
import { testReportUrlActionWatcher } from "../containers/TestReportUrlConatiner/saga";
import { apiDocUrlActionWatcher } from "../containers/ApiDocUrlContainer/saga";
import { expenseTypeActionWatcher } from "../containers/ExpenseTypeContainer/saga";

function* rootSaga() {
  yield all([
    call(LoginActionWatcher),
    call(dashboardActionWatcher),
    call(LicenseeActionWatcher),
    call(countryActionWatcher),
    call(StateActionWatcher),
    call(DistrictActionWatcher),
    call(UnitActionWatcher),
    call(SupportTypeActionWatcher),
    call(currencyActionWatcher),
    call(CountryCrncyActionWatcher),
    call(ProductTypeActionWatcher),
    call(CategoryActionWatcher),
    call(AppConfigActionWatcher),
    call(termsandconditionActionWatcher),
    call(GSTActionWatcher),
    call(PincodeActionWatcher),
    call(BankActionWatcher),
    call(CustomerActionWatcher),
    call(UserActionWatcher),
    call(SubCatgryActionWatcher),
    call(ProductActionWatcher),
    call(countryCurrencyActionWatcher),
    call(OrderActionWatcher),
    call(LeadsActionWatcher),
    call(PaymentModeActionWatcher),
    call(PaymentsActionWatcher),
    call(EarningsActionWatcher),
    call(WithdrawalLimitActionWatcher),
    call(SupportActionWatcher),
    call(LocalityyActionWatcher),
    call(ChannelPrtnrActionWatcher),
    call(resetPasswordActionWatcher),
    call(testReportUrlActionWatcher),
    call(apiDocUrlActionWatcher),
    call(expenseTypeActionWatcher),
  ]);
}

export default rootSaga;
