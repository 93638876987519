import { put, takeEvery, select, call, all } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_PAYMENTS_INIT_ACTION,
  FETCH_PAYMENTS_SUCCESS_ACTION,
  FETCH_PAYMENTS_FAIL_ACTION,
  FETCH_PAYMENTSBYID_INIT_ACTION,
  FETCH_PAYMENTSBYID_SUCCESS_ACTION,
  FETCH_PAYMENTSBYID_FAIL_ACTION,
} from "./action";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { shallow } from "enzyme";

function* fetchPayments(value) {
  let pageno = (value.value && value.value.page) || 1;
  const token = localStorage.getItem("saleStraitToken");
  const role = localStorage.getItem("UserRole");
  const userid = localStorage.getItem("saleStraitUserId");

  let searchVal =
    !value || !value.value || !value.value.searchQuery
      ? ""
      : value.value.searchQuery;
  let filter =
    !value || !value.value || !value.value.filter ? null : value.value.filter;

  let statusFilter = (value.value && value.value.statusFilter) || "";

  let getDataUrl = [
    {
      role: "admin",
      url: `/payment?limit=10&page=${pageno}`,
    },
    {
      role: "channelPartner",
      url: `/payment?limit=10&page=${pageno}&channelPartner=${userid}`,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);

  let _url = `${appConfig.ip}${apiUrl.url}`;

  // let _url = `${appConfig.ip}/payment?limit=10&search=${searchVal}&page=${pageno}`;

  if (filter) {
    _url = `${_url}&licenseeId=${filter}`;
  }
  if (searchVal) {
    _url = `${_url}&search=${searchVal}`;
  }
  if (statusFilter) {
    _url = `${_url}&status=${statusFilter}`;
  }
  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_PAYMENTS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_PAYMENTS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* getPaymentsById(value) {
  const token = localStorage.getItem("saleStraitToken");

  try {
    const res = yield fetch(`${appConfig.ip}/payment/` + value.value, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_PAYMENTSBYID_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_PAYMENTSBYID_FAIL_ACTION, error: err });
    } else {
    }
  }
}

export function* PaymentsActionWatcher() {
  yield takeEvery(FETCH_PAYMENTS_INIT_ACTION, fetchPayments);
  yield takeEvery(FETCH_PAYMENTSBYID_INIT_ACTION, getPaymentsById);
}
