import {
  FETCH_USER_SUCCESS_ACTION,
  FETCH_USERBYID_SUCCESS_ACTION,
  FETCH_REGIONDROPDOWN_SUCCESS_ACTION,
  FETCH_AREADROPDOWN_SUCCESS_ACTION,
  FETCH_ZONEDROPDOWN_SUCCESS_ACTION,
  FETCH_CHANNEL_PARTNER_IN_USER_SUCCESS_ACTION,
} from "./action";

const initialState = {
  processing: false,
  error: false,
};

export default function (state: any = initialState, action: Function) {
  switch (action.type) {
    case FETCH_USER_SUCCESS_ACTION:
      return {
        ...state,
        usersList: action.payload.rows,
        userDtlsCount: action.payload.count,
      };

    case FETCH_USERBYID_SUCCESS_ACTION:
      return {
        ...state,
        userDtls: action.payload,
        // loading: false
      };

    case FETCH_REGIONDROPDOWN_SUCCESS_ACTION:
      return {
        ...state,
        regionDropdownList: action.payload.rows,
      };
    case FETCH_AREADROPDOWN_SUCCESS_ACTION:
      return {
        ...state,
        areaDropdownList: action.payload.rows,
      };
    case FETCH_ZONEDROPDOWN_SUCCESS_ACTION:
      return {
        ...state,
        zoneDropdownList: action.payload.rows,
      };
    case FETCH_CHANNEL_PARTNER_IN_USER_SUCCESS_ACTION:
      return {
        ...state,
        channelPartnerList: action.payload.rows,
      };

    default:
      return {
        ...state,
      };
  }
}
