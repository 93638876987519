export const FETCH_CHANNELPRTNR_INIT_ACTION = "FETCH_CHANNELPRTNR_INIT_ACTION";
export const FETCH_CHANNELPRTNR_SUCCESS_ACTION =
FETCH_CHANNELPRTNR_INIT_ACTION + "_SUCCESS";
export const FETCH_CHANNELPRTNR_FAIL_ACTION = FETCH_CHANNELPRTNR_INIT_ACTION + "_ERROR";

export const ADD_CHANNELPRTNR_INIT_ACTION = "ADD_CHANNELPRTNR_INIT_ACTION";
export const ADD_CHANNELPRTNR_SUCCESS_ACTION =
ADD_CHANNELPRTNR_INIT_ACTION + "_SUCCESS";
export const ADD_CHANNELPRTNR_FAIL_ACTION = ADD_CHANNELPRTNR_INIT_ACTION + "_ERROR";

export const CHANGESTATUS_CHNLPRTNR_INIT_ACTION = "CHANGESTATUS_CHNLPRTNR_INIT_ACTION";
export const CHANGESTATUS_CHNLPRTNR_SUCCESS_ACTION =
CHANGESTATUS_CHNLPRTNR_INIT_ACTION + "_SUCCESS";
export const CHANGESTATUS_CHNLPRTNR_FAIL_ACTION = CHANGESTATUS_CHNLPRTNR_INIT_ACTION + "_ERROR";

export const UPDATE_CHNLPRTNR_INIT_ACTION = "UPDATE_CHNLPRTNR_INIT_ACTION";
export const UPDATE_CHNLPRTNR_SUCCESS_ACTION =
UPDATE_CHNLPRTNR_INIT_ACTION + "_SUCCESS";
export const UPDATE_CHNLPRTNR_FAIL_ACTION = UPDATE_CHNLPRTNR_INIT_ACTION + "_ERROR";