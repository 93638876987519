import * as actionTypes from "./constants";
import _ from "lodash";
import { sortData } from "../../screens/Config/utils";

const initialState = {
  processing: false,
  error: false,
  countryCurrencyList: [],
  countryCurrencyCount: 0,
  countryList: [],
  currencyList: [],
};

export default function countryCurrencyReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_COUNTRY_CURRENCY_SUCCESS_ACTION:
      return {
        ...state,
        countryCurrencyList: action.payload.rows,
        countryCurrencyCount: action.payload.count,
      };

    case actionTypes.FETCH_COUNTRIES_DROPDOWN_SUCCESS_ACTION:
      return {
        ...state,
        countryList: sortData(action.payload.rows),
      };

    case actionTypes.FETCH_CURRENCIES_DROPDOWN_SUCCESS_ACTION:
      return {
        ...state,
        currencyList: sortData(action.payload.rows),
      };

    default:
      return {
        ...state,
      };
  }
}
