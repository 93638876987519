export const FETCH_APPCONFIG_INIT_ACTION = "FETCH_APPCONFIG_INIT_ACTION";
export const FETCH_APPCONFIG_SUCCESS_ACTION =
  FETCH_APPCONFIG_INIT_ACTION + "_SUCCESS";
export const FETCH_APPCONFIG_FAIL_ACTION =
  FETCH_APPCONFIG_INIT_ACTION + "_ERROR";



export const EDIT_APPCONFIG_INIT_ACTION = "EDIT_APPCONFIG_INIT_ACTION";
export const EDIT_APPCONFIG_SUCCESS_ACTION =
  EDIT_APPCONFIG_INIT_ACTION + "_SUCCESS";
export const EDIT_APPCONFIG_FAIL_ACTION =
  EDIT_APPCONFIG_INIT_ACTION + "_ERROR";

export function appConfigList() {
  return {
    type: FETCH_APPCONFIG_INIT_ACTION,
  };
}


export function editAppConfig(value) {
  
  return {
    type: EDIT_APPCONFIG_INIT_ACTION,
    value: value,
  };
}