import { put, takeEvery } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import * as actionTypes from "./constants";
import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var btoa = require("Base64").btoa;
var history = createBrowserHistory();

function* resetPassword(value) {
  const id = JSON.parse(localStorage.getItem("saleStraitUserDtls")).id;
  const mobno = JSON.parse(localStorage.getItem("saleStraitUserDtls")).mobileNo;
  try {
    const data = { password: value.value.newPassword };
    const res = yield fetch(`${appConfig.ip}/users/${id}/password`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa(mobno + ":" + value.value.oldPassword),
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();
      yield put({
        type: actionTypes.RESET_PASSWORD_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success(
        "Password reset succesfully. Please login again to continue",
        {
          autoClose: 5000,
          transition: Flip,
        }
      );
      yield put(push("/dashboard"));
    }
  } catch (err) {
    if (err.status === 401 || err.status === 404 || err.status === 400) {
      yield toast.error("Sorry!! Try again with the registered password", {
        autoClose: 3000,
        transition: Flip,
      });
    } else {
      yield toast.error("Sorry!! Password not reset", {
        autoClose: 3000,
        transition: Flip,
      });
    }
    yield put({
      type: actionTypes.RESET_PASSWORD_FAIL_ACTION,
      payload: err,
    });
  }
}
export function* resetPasswordActionWatcher() {
  yield takeEvery(actionTypes.RESET_PASSWORD_INIT_ACTION, resetPassword);
}
