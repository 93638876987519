import {
  FETCH_COUNTRIES_SUCCESS_ACTION,
  FETCH_COUNTRIES_INIT_ACTION,
  FETCH_COUNTRIES_FAIL_ACTION,
  ADD_COUNTRIES_SUCCESS_ACTION,
  DELETE_COUNTRIES_SUCCESS_ACTION,
  EDIT_COUNTRIES_SUCCESS_ACTION
} from "./action";

const initialState = {
  processing: true,
  error: false,
};

export default function CountryReducer(
  state: any = initialState,
  action: Function
) {
  switch (action.type) {
    // case FETCH_COUNTRIES_INIT_ACTION:
    //   if(action.payload){
    //     return {
    //       ...state,
    //       processing: false,
    //       error: false,
    //     };
    //   }else{
    //   return {
    //     ...state,
    //     processing: true,
    //     error: false,
    //   };
    // }
    case FETCH_COUNTRIES_SUCCESS_ACTION:
      return {
        ...state,
        CountryList: action.payload.rows,
        CountryCount: action.payload.count,
        processing: false,
        error: false,
      };
    case FETCH_COUNTRIES_FAIL_ACTION:
      return {
        ...state,
        processing: false,
        error: action.error,
      };
    // case ADD_COUNTRIES_SUCCESS_ACTION:
    //   return {
    //     ...state,
    //     CountryList: state.CountryList.concat(action.payload),
    //     CountryCount: state.CountryCount + 1,
    //     processing: false,
    //     error: false,
    //   };

    case DELETE_COUNTRIES_SUCCESS_ACTION:
      return {
        ...state,
        CountryList: state.CountryList.filter((x) => x.id !== action.payload),
        CountryCount: state.CountryCount - 1,
        processing: false,
        error: false,
      };
    case EDIT_COUNTRIES_SUCCESS_ACTION:
      return {
        ...state,
        CountryList: state.CountryList.map((x)=>{
          if(x.id===action.payload.id){
            x=action.payload
          }
          return x
        }) ,
        processing: false,
        error: false,
      };
      
    default:
      return {
        ...state,
      };
  }
}
