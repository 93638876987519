export const ADD_STATE_INIT_ACTION = "ADD_STATE_INIT_ACTION";
export const ADD_STATE_SUCCESS_ACTION = ADD_STATE_INIT_ACTION + "_SUCCESS";
export const ADD_STATE_FAIL_ACTION = ADD_STATE_INIT_ACTION + "_ERROR";

export const FETCH_STATELIST_INIT_ACTION = "FETCH_STATELIST_INIT_ACTION";
export const FETCH_STATELIST_SUCCESS_ACTION =
  FETCH_STATELIST_INIT_ACTION + "_SUCCESS";
export const FETCH_STATELIST_FAIL_ACTION =
  FETCH_STATELIST_INIT_ACTION + "_ERROR";

export const DELETE_STATE_INIT_ACTION = "DELETE_STATE_INIT_ACTION";
export const DELETE_STATE_SUCCESS_ACTION =
  DELETE_STATE_INIT_ACTION + "_SUCCESS";
export const DELETE_STATE_FAIL_ACTION = DELETE_STATE_INIT_ACTION + "_ERROR";

export const UPDATE_STATE_INIT_ACTION = "UPDATE_STATE_INI_ACTION";
export const UPDATE_STATE_SUCCESS_ACTION =
  UPDATE_STATE_INIT_ACTION + "_SUCCESS";
export const UPDATE_STATE_FAIL_ACTION = UPDATE_STATE_INIT_ACTION + "_ERROR";


export const FETCH_COUNTRY_DROPDOWN_IN_STATE_INIT_ACTION =
  "FETCH_COUNTRY_DROPDOWN_IN_STATE_INIT_ACTION";
export const FETCH_COUNTRY_DROPDOWN_IN_STATE_SUCCESS_ACTION =
  FETCH_COUNTRY_DROPDOWN_IN_STATE_INIT_ACTION + "_SUCCESS";
export const FFETCH_COUNTRY_DROPDOWN_IN_STATE_FAIL_ACTION =
  FETCH_COUNTRY_DROPDOWN_IN_STATE_INIT_ACTION + "_ERROR";

export function addState(value) {
  return {
    type: ADD_STATE_INIT_ACTION,
    value: value,
  };
}

export function fetchCntryByState() {
  return {
    type: FETCH_COUNTRY_DROPDOWN_IN_STATE_INIT_ACTION,
  };
}

export function stateViewList(value) {
  return {
    type: FETCH_STATELIST_INIT_ACTION,
    value: value,

  };
}

export function DeleteValue(items) {
  return {
    type: DELETE_STATE_INIT_ACTION,
    items: items,
  };
}
export function editState(value) {
  return {
    type: UPDATE_STATE_INIT_ACTION,
    value: value,
  };
}


