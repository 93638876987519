import { put, takeEvery } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import * as actionTypes from "./constants";
import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastrMsg from "../../screens/Common/ToastrMsg";

var btoa = require("Base64").btoa;
var history = createBrowserHistory();

function* fetchCountriesDropDown(params) {
  try {
    const bittybruToken = localStorage.getItem("bittybruToken");
    const res = yield fetch(`${appConfig.ip}/country?isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bittybruToken}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.FETCH_COUNTRIES_DROPDOWN_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_COUNTRIES_DROPDOWN_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* fetchCurrenciesDropDown(params) {
  try {
    const bittybruToken = localStorage.getItem("bittybruToken");
    const res = yield fetch(`${appConfig.ip}/currency?isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bittybruToken}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.FETCH_CURRENCIES_DROPDOWN_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_CURRENCIES_DROPDOWN_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* fetchCountryCurrency(params) {
  let page = (params.params && params.params.page) || 1;
  try {
    const bittybruToken = localStorage.getItem("bittybruToken");
    const res = yield fetch(
      `${appConfig.ip}/countryCurrency?isActive=true&limit=10&page=${page}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${bittybruToken}`,
        },
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.FETCH_COUNTRY_CURRENCY_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.FETCH_COUNTRY_CURRENCY_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* addCountryCurrency(value) {
  let data = value.value;
  try {
    const bittybruToken = localStorage.getItem("bittybruToken");

    const res = yield fetch(`${appConfig.ip}/countryCurrency`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bittybruToken}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();

      yield put({
        type: actionTypes.ADD_COUNTRY_CURRENCY_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success(ToastrMsg.addMsg, {
        autoClose: 3000,
      });
      yield put({
        type: actionTypes.FETCH_COUNTRY_CURRENCY_INIT_ACTION,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.ADD_COUNTRY_CURRENCY_FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* editCountryCurrency(value) {
  let id = value.id;
  let data = value.params;

  const bittybruToken = localStorage.getItem("bittybruToken");
  try {
    const res = yield fetch(`${appConfig.ip}/countryCurrency/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bittybruToken}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield toast.success(ToastrMsg.updateMsg, {
        autoClose: 3000,
      });
      const resJSON = yield res.json();
      yield put({ type: actionTypes.FETCH_COUNTRY_CURRENCY_INIT_ACTION });
      yield put({
        type: actionTypes.EDIT_COUNTRY_CURRENCY_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.EDIT_COUNTRY_CURRENCY_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* deleteCountryCurrency(params) {
  let id = params.data.id;
  let data = {
    countryId: params.data.countryId.id,
    currencyId: params.data.currencyId.id,
  };
  const bittybruToken = localStorage.getItem("bittybruToken");
  try {
    const res = yield fetch(`${appConfig.ip}/countryCurrency/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bittybruToken}`,
      },
      body: JSON.stringify({ isActive: false, ...data }),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      yield put({ type: actionTypes.FETCH_COUNTRY_CURRENCY_INIT_ACTION });
      yield put({
        type: actionTypes.DELETE_COUNTRY_CURRENCY_SUCCESS_ACTION,
        payload: id,
      });
      yield toast.success(ToastrMsg.delMsg, {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: actionTypes.DELETE_COUNTRY_CURRENCY_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

export function* countryCurrencyActionWatcher() {
  yield takeEvery(
    actionTypes.FETCH_COUNTRIES_DROPDOWN_INIT_ACTION,
    fetchCountriesDropDown
  );
  yield takeEvery(
    actionTypes.FETCH_CURRENCIES_DROPDOWN_INIT_ACTION,
    fetchCurrenciesDropDown
  );
  yield takeEvery(
    actionTypes.FETCH_COUNTRY_CURRENCY_INIT_ACTION,
    fetchCountryCurrency
  );
  yield takeEvery(
    actionTypes.ADD_COUNTRY_CURRENCY_INIT_ACTION,
    addCountryCurrency
  );
  yield takeEvery(
    actionTypes.DELETE_COUNTRY_CURRENCY_INIT_ACTION,
    deleteCountryCurrency
  );
  yield takeEvery(
    actionTypes.EDIT_COUNTRY_CURRENCY_INIT_ACTION,
    editCountryCurrency
  );
}
