import { put, takeEvery } from "redux-saga/effects";
// import { createBrowserHistory } from "history";
import {
  FETCH_SUPPORTTYPE_INIT_ACTION,
  FETCH_SUPPORTTYPE_SUCCESS_ACTION,
  FETCH_SUPPORTTYPE_FAIL_ACTION,
  ADD_SUPPORTTYPE_INIT_ACTION,
  ADD_SUPPORTTYPE_SUCCESS_ACTION,
  ADD_SUPPORTTYPE_FAIL_ACTION,
  EDIT_SUPPORTTYPE_INIT_ACTION,
  EDIT_SUPPORTTYPE_SUCCESS_ACTION,
  EDIT_SUPPORTTYPE_FAIL_ACTION,
  DELETE_SUPPORTTYPE_INIT_ACTION,
  DELETE_SUPPORTTYPE_SUCCESS_ACTION,
  DELETE_SUPPORTTYPE_FAIL_ACTION
} from "./action";
import { appConfig } from "../../config";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

function* fetchsupportTypelist(value) {

  let searchVal = !value || !value.value || !value.value.searchVal ? '' : value.value.searchVal

  let pageno = value.value.page === undefined ? 1 : value.value.page;
  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(
      `${appConfig.ip}/supportType?limit=10&isActive=true&search=${searchVal}&page=${pageno}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    );
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_SUPPORTTYPE_SUCCESS_ACTION,
        payload: resJSON
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_SUPPORTTYPE_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* AddSupportType(value) {
  try {
    const token = localStorage.getItem("saleStraitToken");

    const data = {
      supportType: value.value.supportType,
      disc: value.value.disc,
      isActive: value.value.isActive
    };

    const res = yield fetch(`${appConfig.ip}/supportType`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(data)
    });

    if (!res.ok) {
    } else {
      const resJSON = yield res.json();

      yield put({ type: FETCH_SUPPORTTYPE_INIT_ACTION,value: { page: 1 } });
      yield put({
        type: ADD_SUPPORTTYPE_SUCCESS_ACTION,
        payload: resJSON
      });
      yield toast.success("Support Type added successfully", {
        autoClose: 3000
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_SUPPORTTYPE_FAIL_ACTION,
        error: err
      });
    }
  }
}

function* EditSupportType(value) {
  try {
    const token = localStorage.getItem("saleStraitToken");

    const data = {
      supportType: value.value.supportType,
      disc: value.value.disc,
      isActive: value.value.isActive
    };

    const res = yield fetch(`${appConfig.ip}/supportType/` + value.value.id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(data)
    });

    if (!res.ok) {
    } else {
      const resJSON = yield res.json();

      // yield put({ type: FETCH_SUPPORTTYPE_INIT_ACTION });
      yield put({
        type: EDIT_SUPPORTTYPE_SUCCESS_ACTION,
        payload: resJSON
      });
      yield toast.success("Support Type updated successfully", {
        autoClose: 3000
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: EDIT_SUPPORTTYPE_FAIL_ACTION,
        error: err
      });
    }
  }
}

function* DeleteSupportType(value) {
 
  let id = value.value.id;
 
  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/supportType/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify()
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      // yield put({ type: FETCH_SUPPORTTYPE_INIT_ACTION });
      yield toast.success("Deleted successfully", {
        autoClose: 3000
      });
      // const resJSON = yield res.json();

      yield put({
        type: DELETE_SUPPORTTYPE_SUCCESS_ACTION,
        payload: id
        
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: DELETE_SUPPORTTYPE_FAIL_ACTION, error: err });
    } else {
    }
  }
}

export function* SupportTypeActionWatcher() {
  yield takeEvery(FETCH_SUPPORTTYPE_INIT_ACTION, fetchsupportTypelist);
  yield takeEvery(ADD_SUPPORTTYPE_INIT_ACTION, AddSupportType);
  yield takeEvery(EDIT_SUPPORTTYPE_INIT_ACTION, EditSupportType);
  yield takeEvery(DELETE_SUPPORTTYPE_INIT_ACTION, DeleteSupportType);
}
