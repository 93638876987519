export const FETCH_PRODUCT_INIT_ACTION = "FETCH_PRODUCT_INIT_ACTION";
export const FETCH_PRODUCT_SUCCESS_ACTION =
  FETCH_PRODUCT_INIT_ACTION + "_SUCCESS";
export const FETCH_PRODUCT_FAIL_ACTION = FETCH_PRODUCT_INIT_ACTION + "_ERROR";

export const FETCH_PRODUCTBYID_INIT_ACTION = "FETCH_PRODUCTBYID_INIT_ACTION";
export const FETCH_PRODUCTBYID_SUCCESS_ACTION =
FETCH_PRODUCTBYID_INIT_ACTION + "_SUCCESS";
export const FETCH_PRODUCTBYID_FAIL_ACTION = FETCH_PRODUCTBYID_INIT_ACTION + "_ERROR";

export const FETCH_LICENSEEFILTER_INIT_ACTION = "FETCH_LICENSEEFILTER_INIT_ACTION";
export const FETCH_LICENSEEFILTER_SUCCESS_ACTION =
  FETCH_LICENSEEFILTER_INIT_ACTION + "_SUCCESS";
export const FETCH_LICENSEEFILTER_FAIL_ACTION = FETCH_LICENSEEFILTER_INIT_ACTION + "_ERROR";

export function fetchProduct(value) {
  return {
    type: FETCH_PRODUCT_INIT_ACTION,
    value: value,

  };
}


export function getProductById(value) {
  return {
    type: FETCH_PRODUCTBYID_INIT_ACTION,
    value:value
  };
}

export function fetchLicenseeFilter(value) {
  return {
    type: FETCH_LICENSEEFILTER_INIT_ACTION,
    value: value,

  };
}