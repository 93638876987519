import { put, takeEvery } from "redux-saga/effects";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  LOGIN_INIT_ACTION,
  FETCH_LOGIN_FAIL_ACTION,
  FETCH_LOGIN_SUCCESS_ACTION,
  LOGOUT_ACTION,
} from "./actions";
import { appConfig } from "../../config";
import { push } from "connected-react-router";
var btoa = require("Base64").btoa;

function* login(cred) {
  try {
    const loginData = {
      username: cred.cred.username,
      password: cred.cred.password,
    };

    const res = yield fetch(appConfig.ip + "/auth", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Basic " + btoa(loginData.username + ":" + loginData.password),
      },
    });

    if (!res.ok) {
      throw res;
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_LOGIN_SUCCESS_ACTION,
        payload: resJSON,
      });
      localStorage.setItem("saleStraitToken", resJSON.token);
      localStorage.setItem("UserRole", resJSON.user.role);
      let userID = resJSON.user.id;
      localStorage.setItem("saleStraitUserId", userID);
      localStorage.setItem("saleStraitUserDtls", JSON.stringify(resJSON.user));
      if (
        resJSON.user.role === "admin" ||
        resJSON.user.role === "channelPartner"
      ) {
        yield put(push("/dashboard"));
        yield toast.success("Login successfully", {
          autoClose: 3000,
        });
      } else if (resJSON.user.role === "techAdmin") {
        yield put(push("/testReportUrl"));
        yield toast.success("Login successfully", {
          autoClose: 3000,
        });
      } else {
        yield toast.error("Only admin can access", {
          autoClose: 3000,
        });
      }
    }
  } catch (err) {
    // if (err.status === 400) {
    //   yield toast.error("Invalid credential", {
    //     autoClose: 3000,
    //   });
    // }
    if (err.status === 401) {
      yield toast.error("Invalid credential", {
        autoClose: 3000,
      });
    }
    yield put({
      type: FETCH_LOGIN_FAIL_ACTION,
      payload: err,
    });
  }
}

function* logOut() {
  localStorage.removeItem("saleStraitToken");
  localStorage.removeItem("UserRole");
  localStorage.removeItem("saleStraitUserId");
  localStorage.removeItem("saleStraitUserDtls");
}
export function* LoginActionWatcher() {
  yield takeEvery(LOGIN_INIT_ACTION, login);
  yield takeEvery(LOGOUT_ACTION, logOut);
}
