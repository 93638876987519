export const FETCH_TERMSANDCONDITION_INIT_ACTION =
  "FETCH_TERMSANDCONDITION_INIT_ACTION";
export const FETCH_TERMSANDCONDITION_SUCCESS_ACTION =
  FETCH_TERMSANDCONDITION_INIT_ACTION + "_SUCCESS";
export const FETCH_TERMSANDCONDITION_FAIL_ACTION =
  FETCH_TERMSANDCONDITION_INIT_ACTION + "_ERROR";

export const ADD_TERMSANDCONDITION_INIT_ACTION =
  "ADD_TERMSANDCONDITION_INIT_ACTION";
export const ADD_TERMSANDCONDITION_SUCCESS_ACTION =
  ADD_TERMSANDCONDITION_INIT_ACTION + "_SUCCESS";
export const ADD_TERMSANDCONDITION_FAIL_ACTION =
  ADD_TERMSANDCONDITION_INIT_ACTION + "_ERROR";

export const EDIT_TERMSANDCONDITION_INIT_ACTION =
  "EDIT_TERMSANDCONDITION_INIT_ACTION";
export const EDIT_TERMSANDCONDITION_SUCCESS_ACTION =
  EDIT_TERMSANDCONDITION_INIT_ACTION + "_SUCCESS";
export const EDIT_TERMSANDCONDITION_FAIL_ACTION =
  EDIT_TERMSANDCONDITION_INIT_ACTION + "_ERROR";

export function fetchTermsandCondition() {
  return {
    type: FETCH_TERMSANDCONDITION_INIT_ACTION
  };
}

export function AddTrmsadCndtn(value) {
  return {
    type: ADD_TERMSANDCONDITION_INIT_ACTION,
    value: value
  };
}
export function EditTrmsadCndtn(value) {
  return {
    type: EDIT_TERMSANDCONDITION_INIT_ACTION,
    value: value
  };
}
