import React, { Component, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import * as router from "react-router-dom";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import { logOut } from "../../containers/LoginContainer/actions";
import {
  AppAside,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppBreadcrumb2 as AppBreadcrumb,
  AppSidebarNav2 as AppSidebarNav,
} from "@coreui/react";
// sidebar nav config
import { navAdmin, navChannelPrtnr, navTechnicalAdmin } from "../../_nav";
// routes config
import routes from "../../routes";

const DefaultAside = React.lazy(() => import("./DefaultAside"));
const DefaultFooter = React.lazy(() => import("./DefaultFooter"));
const DefaultHeader = React.lazy(() => import("./DefaultHeader"));

class DefaultLayout extends Component {
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  signOut(e) {
    e.preventDefault();
    this.props.logoutaction();
    this.props.history.push("/login");
  }



  render() {
    const role = localStorage.getItem("UserRole");
    const sideMenuList = [
      { role: "admin", menu: navAdmin },
      { role: "channelPartner", menu: navChannelPrtnr },
      { role: "techAdmin", menu: navTechnicalAdmin },
    ];
    const sideMenu = sideMenuList.find((e) => e.role === role);

    return (
      <div className="app">
        <AppHeader fixed>
          <Suspense fallback={this.loading()}>
            <DefaultHeader onLogout={e => this.signOut(e)} />
          </Suspense>
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense>
              <AppSidebarNav
                // navConfig={sideMenu.menu}
                navConfig={sideMenu && sideMenu.menu}
                {...this.props}
                router={router} />
            </Suspense>
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes} router={router} />
            <Container fluid>
              <Suspense fallback={this.loading()}>
                <Switch>
                  {routes.map((route, idx) => {
                    return route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        // render={props => (
                        //   <route.component {...props} />
                        // )}
                        render={(props) =>
                          localStorage.getItem("saleStraitToken") !== null ? (
                            <route.component {...props} />
                          ) : (
                            <Redirect from="/" to="/login" />
                          )
                        }
                      />
                    ) : (null);
                  })}
                  <Redirect from="/" to="/dashboard" />
                </Switch>
              </Suspense>
            </Container>
          </main>
          <AppAside fixed>
            <Suspense fallback={this.loading()}>
              <DefaultAside />
            </Suspense>
          </AppAside>
        </div>
        <AppFooter>
          <Suspense fallback={this.loading()}>
            <DefaultFooter />
          </Suspense>
        </AppFooter>
      </div>
    );
  }
}

function bindAction(dispatch) {
  return {
    logoutaction: () => {
      dispatch(logOut());
    },
  };
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, bindAction)(DefaultLayout);
