import { put, takeEvery } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {
  FETCH_LEADS_INIT_ACTION,
  FETCH_LEADS_SUCCESS_ACTION,
  FETCH_LEADS_FAIL_ACTION,
  FETCH_CUSTOMERS_BY_SALESEXECUTIVE_INIT_ACTION,
  FETCH_CUSTOMERS_BY_SALESEXECUTIVE_SUCCESS_ACTION,
  FETCH_CUSTOMERS_BY_SALESEXECUTIVE_FAIL_ACTION,
  FETCH_PRODUCTS_BY_SALESEXECUTIVE_INIT_ACTION,
  FETCH_PRODUCTS_BY_SALESEXECUTIVE_SUCCESS_ACTION,
  FETCH_PRODUCTS_BY_SALESEXECUTIVE_FAIL_ACTION,
  FETCH_LEADS_DETAIL_INIT_ACTION,
  FETCH_LEADS_DETAIL_SUCCESS_ACTION,
  FETCH_LEADS_DETAIL_FAIL_ACTION,
  FETCH_USER_DETAIL_INIT_ACTION,
  FETCH_USER_DETAIL_SUCCESS_ACTION,
  FETCH_USER_DETAIL_FAIL_ACTION,
  // FETCH_LICENSEEFILTER_INIT_ACTION,
  // FETCH_LICENSEEFILTER_SUCCESS_ACTION,
  // FETCH_LICENSEEFILTER_FAIL_ACTION,
} from "./action";

import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function* fetchLeads(value) {
  let pageno = (value.value && value.value.page) || 1;
  const role = localStorage.getItem("UserRole");
  const userid = localStorage.getItem("saleStraitUserId");

  let customerId =
    !value || !value.value || !value.value.customerId
      ? ""
      : value.value.customerId;
  let searchVal =
    !value || !value.value || !value.value.searchQuery
      ? ""
      : value.value.searchQuery;
  let filter =
    !value || !value.value || !value.value.filter ? null : value.value.filter;

  let getDataUrl = [
    {
      role: "admin",
      url: `/lead?limit=10&page=${pageno}&isActive=true&status=active`,
    },
    {
      role: "channelPartner",
      url: `/lead?limit=10&page=${pageno}&isActive=true&status=active&channelPartner=${userid}`,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);

  let _url = `${appConfig.ip}${apiUrl.url}`;

  // let _url = `${appConfig.ip}/lead?limit=10&search=${searchVal}&customer=${customerId}&page=${pageno}&isActive=true&status=active`;

  if (filter) {
    _url = `${_url}&licenseeId=${filter}`;
  }
  if (searchVal) {
    _url = `${_url}&search=${searchVal}`;
  }
  if (customerId) {
    _url = `${_url}&customer=${customerId}`;
  }

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_LEADS_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_LEADS_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchCusBySExec(value) {
  const token = localStorage.getItem("saleStraitToken");

  try {
    const res = yield fetch(`${appConfig.ip}/customer?&isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_CUSTOMERS_BY_SALESEXECUTIVE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_CUSTOMERS_BY_SALESEXECUTIVE_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* fetchPrdtBySExec(value) {
  const token = localStorage.getItem("saleStraitToken");

  try {
    const res = yield fetch(`${appConfig.ip}/product?&isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_PRODUCTS_BY_SALESEXECUTIVE_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_PRODUCTS_BY_SALESEXECUTIVE_FAIL_ACTION,
        error: err,
      });
    } else {
    }
  }
}

function* fetchLeadsDetail(value) {
  const token = localStorage.getItem("saleStraitToken");
  let id = !value || !value.value || !value.value.id ? "" : value.value.id;
  try {
    const res = yield fetch(`${appConfig.ip}/lead/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_LEADS_DETAIL_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_LEADS_DETAIL_FAIL_ACTION, error: err });
    } else {
    }
  }
}

function* fetchUserDetail(value) {
  const token = localStorage.getItem("saleStraitToken");
  let id = !value || !value.value || !value.value.id ? "" : value.value.id;
  try {
    const res = yield fetch(`${appConfig.ip}/users/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_USER_DETAIL_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_USER_DETAIL_FAIL_ACTION, error: err });
    } else {
    }
  }
}

// function* fetchLicenseeFilter(value) {
//   let search = value.value || "";
//   try {
//     const token = localStorage.getItem("saleStraitToken");
//     const res = yield fetch(
//       `${appConfig.ip}/licenseeProf?isActive=true&status=active&limit=50&search=${search}`,
//       {
//         method: "GET",
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );

//     if (!res.ok) {
//       let errJSON = {};
//       try {
//         errJSON = yield res.json();
//       } catch {
//         throw Object.assign(res, errJSON);
//       }
//     } else {
//       const resJSON = yield res.json();

//       yield put({
//         type: FETCH_LICENSEEFILTER_SUCCESS_ACTION,
//         payload: resJSON,
//       });
//     }
//   } catch (err) {
//     if (err.ok === false) {
//       yield put({
//         type: FETCH_LICENSEEFILTER_FAIL_ACTION,
//         error: err,
//       });
//     }
//   }
// }

export function* LeadsActionWatcher() {
  yield takeEvery(FETCH_LEADS_INIT_ACTION, fetchLeads);
  yield takeEvery(
    FETCH_CUSTOMERS_BY_SALESEXECUTIVE_INIT_ACTION,
    fetchCusBySExec
  );
  yield takeEvery(
    FETCH_PRODUCTS_BY_SALESEXECUTIVE_INIT_ACTION,
    fetchPrdtBySExec
  );
  yield takeEvery(FETCH_LEADS_DETAIL_INIT_ACTION, fetchLeadsDetail);
  yield takeEvery(FETCH_USER_DETAIL_INIT_ACTION, fetchUserDetail);
  // yield takeEvery(FETCH_LICENSEEFILTER_INIT_ACTION, fetchLicenseeFilter);
}
