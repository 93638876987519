import { put, takeEvery } from "redux-saga/effects";
import { createBrowserHistory } from "history";
import {

  FETCH_CURRENCY1_INIT_ACTION,
  FETCH_CURRENCY1_SUCCESS_ACTION,
  FETCH_CURRENCY1_FAIL_ACTION,
  ADD_CNTRY_CRNCY_INIT_ACTION,
  ADD_CNTRY_CRNCY_SUCCESS_ACTION,
  ADD_CNTRY_CRNCY__FAIL_ACTION,
  FETCH_CNTRY_CRNCY_INIT_ACTION,
  FETCH_CNTRY_CRNCY_SUCCESS_ACTION,
  FETCH_CNTRY_CRNCY__FAIL_ACTION,
  DELETE_CNTRY_CRNCY_INIT_ACTION,
  DELETE_CNTRY_CRNCY_SUCCESS_ACTION,
  DELETE_CNTRY_CRNCY_FAIL_CTION,
  EDIT_CNTRY_CRNCY_INIT_ACTION,
  EDIT_CNTRY_CRNCY_SUCCESS_ACTION,
  EDIT_CNTRY_CRNCY_FAIL_ACTION,
} from "./action";
import { push } from "connected-react-router";
import { appConfig } from "../../config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var btoa = require("Base64").btoa;
var history = createBrowserHistory();



function* fetchCurrency() {
  try {
    const token = localStorage.getItem("saleStraitToken");
    const res = yield fetch(`${appConfig.ip}/currency?limit=100&isActive=true`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {
        throw Object.assign(res, errJSON);
      }
    } else {
      const resJSON = yield res.json();
    
      yield put({
        type: FETCH_CURRENCY1_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_CURRENCY1_FAIL_ACTION,
        error: err,
      });
    }
  }
}



function* addCntryCrncy(value) {
  try {
    const token = localStorage.getItem("saleStraitToken");

    const data = {
      countryId: value.value.countryId,
      currencyId: value.value.currencyId,
    };

    const res = yield fetch(`${appConfig.ip}/countryCrncy?isActive=true`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!res.ok) {
    } else {
      const resJSON = yield res.json();
    

      yield put({ type: FETCH_CNTRY_CRNCY_INIT_ACTION });
      yield put({
        type: ADD_CNTRY_CRNCY_SUCCESS_ACTION,
        payload: resJSON,
      });
      yield toast.success("Country Currency added successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: ADD_CNTRY_CRNCY__FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* fetchCountryCrncy(value) {
  let pageno = value.value.page === undefined ? 1 : value.value.page;
  let searchVal = !value || !value.value || !value.value.searchVal ? '' : value.value.searchVal
  try {
    const token = localStorage.getItem("saleStraitToken");

    const res = yield fetch(
      `${appConfig.ip}/countryCrncy?imit=10&search=${searchVal}&page=${pageno}&isActive=true`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!res.ok) {
    } else {
      const resJSON = yield res.json();
    
      yield put({
        type: FETCH_CNTRY_CRNCY_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({
        type: FETCH_CNTRY_CRNCY__FAIL_ACTION,
        error: err,
      });
    }
  }
}

function* deleteCrntyCrncy(value) {

  let id = value.value.id;
  
  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/countryCrncy/` + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
     
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      // yield put({ type: FETCH_CNTRY_CRNCY_INIT_ACTION });
      // const resJSON = yield res.json();
    
      yield put({
        type: DELETE_CNTRY_CRNCY_SUCCESS_ACTION,
        payload:id
      });
      yield toast.success("Deleted successfully", {
        autoClose: 3000,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: DELETE_CNTRY_CRNCY_FAIL_CTION, error: err });
    } else {
    }
  }
}

function* EditCntryCurency(value) {
  let id = value.value.id;
 
  let data = {
    countryId: value.value.countryId,
    currencyId: value.value.currencyId,
    // isActive:value.value.isActive
  };

  const token = localStorage.getItem("saleStraitToken");
  try {
    const res = yield fetch(`${appConfig.ip}/countryCrncy/` + id, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();
    
      // yield put({ type: FETCH_CNTRY_CRNCY_INIT_ACTION });
      yield put({
        type: EDIT_CNTRY_CRNCY_SUCCESS_ACTION,
        payload:resJSON
      });
      
      yield toast.success("Country Currency updated successfully", {
        autoClose: 3000,
      });
   
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: EDIT_CNTRY_CRNCY_FAIL_ACTION, error: err });
    } else {
    }
  }
}

export function* CountryCrncyActionWatcher() {
 
  yield takeEvery(FETCH_CURRENCY1_INIT_ACTION, fetchCurrency);
  
  yield takeEvery(ADD_CNTRY_CRNCY_INIT_ACTION, addCntryCrncy);
  yield takeEvery(FETCH_CNTRY_CRNCY_INIT_ACTION, fetchCountryCrncy);
  yield takeEvery(DELETE_CNTRY_CRNCY_INIT_ACTION, deleteCrntyCrncy);
  yield takeEvery(EDIT_CNTRY_CRNCY_INIT_ACTION, EditCntryCurency);
}
