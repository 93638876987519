import {
    FETCH_CUSTOMER_SUCCESS_ACTION,
    FETCH_CUSTOMERBYID_SUCCESS_ACTION,
    FETCH_REGIONDROPDOWN_SUCCESS_ACTION,
    FETCH_AREADROPDOWN_SUCCESS_ACTION,
    FETCH_ZONEDROPDOWN_SUCCESS_ACTION
    
  } from "./action";
  
  const initialState = {
    processing: false,
    error: false,
  };
  
  export default function (state: any = initialState, action: Function) {
    switch (action.type) {
      case FETCH_CUSTOMER_SUCCESS_ACTION:
        return {
          ...state,
          customersList: action.payload.rows,
          customerDtlsCount: action.payload.count,
        };

              case FETCH_CUSTOMERBYID_SUCCESS_ACTION:
            
                return {
                    ...state,
                    customerDtls: action.payload,
                    // loading: false
                };

                case FETCH_REGIONDROPDOWN_SUCCESS_ACTION:

                  return {
                      ...state,
                      regionDropdownList: action.payload.rows,
      
                  };
              case FETCH_AREADROPDOWN_SUCCESS_ACTION:
                  return {
                      ...state,
                      areaDropdownList: action.payload.rows,
                  };
                  case FETCH_ZONEDROPDOWN_SUCCESS_ACTION:
                    return {
                        ...state,
                        zoneDropdownList: action.payload.rows,
                    };
      
           
            
  
      default:
        return {
          ...state,
        };
    }
  }
  