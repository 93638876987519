import { put, takeEvery } from "redux-saga/effects";
//import { createBrowserHistory } from "history";

//import { push } from "connected-react-router";
import { appConfig } from "../../config";
//import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  FETCH_DASHBOARD_INIT_ACTION,
  FETCH_DASHBOARD_SUCCESS_ACTION,
  FETCH_DASHBOARD_FAIL_ACTION,
} from "./action";

//var btoa = require("Base64").btoa;
//var history = createBrowserHistory();

function* fetchDashboardList() {
  const token = localStorage.getItem("saleStraitToken");
  const role = localStorage.getItem("UserRole");

  let getDataUrl = [
    {
      role: "admin",
      url: `/count`,
    },
    {
      role: "channelPartner",
      url: `/count/forChannelPartner`,
    },
  ];

  let apiUrl = getDataUrl.find((e) => e.role === role);

  let _url = `${appConfig.ip}${apiUrl.url}`;

  try {
    const res = yield fetch(_url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      let errJSON = {};
      try {
        errJSON = yield res.json();
      } catch {}
      throw Object.assign(res, errJSON);
    } else {
      const resJSON = yield res.json();

      yield put({
        type: FETCH_DASHBOARD_SUCCESS_ACTION,
        payload: resJSON,
      });
    }
  } catch (err) {
    if (err.ok === false) {
      yield put({ type: FETCH_DASHBOARD_FAIL_ACTION, error: err });
    } else {
    }
  }
}

export function* dashboardActionWatcher() {
  yield takeEvery(FETCH_DASHBOARD_INIT_ACTION, fetchDashboardList);
}
